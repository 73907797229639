.holidayscroller::-webkit-scrollbar {
  width: 10px;
}

.holidayscroller::-webkit-scrollbar-track {
  height: 10px;
}

.holidayscroller::-webkit-scrollbar-thumb {
  height: 10px !important;
  outline: 1px solid #314d7e;
  border-radius: 25px;
}

.Caraouseldatemain {
  width: 100%;
  height: 83%;
  max-height: 545px;
  padding: 15px 0px;
  overflow-y: auto;
  background-color: #3a5686;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.dateHeader {
  width: 100%;
  background: #506994;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0px;
}

.datecontent {
  width: 100%;
  padding: 15px 20px;
  height: 80px;
}

.datediv {
  width: 20%;
  background: #506994;
  padding: 10px 10px;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  float: left;
  font-weight: 600;
  margin-bottom: 10px;
}

.datedisc {
  width: 80%;
  padding: 5px 20px;
  float: left;
  font-size: 12px;
  font-weight: 600;
}
