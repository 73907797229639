.ownerCard {
  display: flex;
  flex-wrap: wrap;
  width: 93% !important;
  margin: 0 auto;
  padding-bottom: 210px;
}

.card_onhover {
  display: none;
  padding-top: 6px;
  border-top: 1px solid #1d3a6d;
}

.ownerSelectownerCard {
  width: 238px;
  height: 105px;
  border-radius: 10px !important;
  box-shadow: -5px -5px 11px #71717133 !important;
  background-color: #3a5686;
  box-sizing: border-box;
  margin: 10px;
  padding-right: 1px !important;
  cursor: pointer;
  transition: height 0.1s;
}

.ownerSelectownerCard:hover {
  position: absolute;
  border: 1px solid #ff5860;
  height: 250px !important;
  width: 238px;
  z-index: 999;
}

.ownerSelectownerCard:hover .card_onhover {
  transition: all 0.2s ease 0.2s;
  display: block;
}

.owner_viewall-details {
  padding: 10px 0px 0px 15px;
}

.card_refresh {
  width: 22px;
  height: 18px;
}
.card_refresh-img {
  width: 22px;
  height: 20px;
  object-fit: cover;
  border-radius: 5px;
}

.balance-container {
  display: flex;
  gap: 10px;
  position: relative;
}

.borders-div {
  margin: 3px 10px;
  height: 13px;
}

.viewall-date-div {
  color: #dadada;
  font: normal normal normal 10px/14px Open Sans;
  margin-bottom: 20px;
}

.viewall-balance-div {
  font: normal normal normal 12px/16px Open Sans;
  color: #fafafa;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0px 0px 10px 15px !important;
}

.views-all-user {
  white-space: nowrap;
  height: 40px;
  width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}

.viewall-bank-acc-div {
  max-width: 60px;
  width: 50px;
  font: normal normal 600 16px/22px Open Sans;
  padding-bottom: 2px;
}

.not-available {
  color: #f9f9f9 !important;
  font: normal normal 400 16px/19px Open Sans;
  margin-bottom: 4px;
}

.boxs-status {
  width: 5px;
  height: 15px;
  background-color: #4ddd37;
  border-radius: 0px 5px 0px 0px;
}

.boxheader:hover .assigned {
  display: block;
}

.assigned {
  display: none;
  width: auto;
  padding: 0px 5px;
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  letter-spacing: 0.5px;
}

.label-divs {
  display: flex;
}

.css-wdslgk {
  padding-right: 0 !important;
}

@keyframes slideOutRightToLeft {
  0% {
    visibility: visible;
    transform: translateX(0);
  }

  100% {
    visibility: hidden;
    transform: translateX(-10%);
  }
}

.viewall-amount-div {
  visibility: visible;
}

.view-all-card-container {
  margin-right: 0px !important;
  width: 90px !important;
  height: 26px !important;
}

.assigned-pending-box,
.assigned-approved-box,
.assigned-blocked-box,
.assigned-archived-box,
.assigned-denied-box ::first-letter {
  text-transform: capitalize;
}

.assigned-pending-box {
  background-color: rgba(49, 229, 229, 0.2);
  color: rgba(49, 229, 229, 1);
  font-size: 9px;
  border-radius: 0px 3px 0px 0px;
}

.assigned-approved-box {
  background-color: rgba(77, 221, 55, 0.2);
  color: rgba(77, 221, 55, 1);
  font-size: 9px;
  border-radius: 0px 3px 0px 0px;
}

.assigned-denied-box,
.assigned-blocked-box {
  background-color: rgba(255, 88, 96, 0.2);
  color: rgba(255, 88, 96, 1);
  border-radius: 0px 3px 0px 0px;
  font-size: 9px;
  text-transform: capitalize;
}

.assigned-archived-box {
  background-color: rgba(207, 207, 207, 0.2);
  font-size: 9px;
  border-radius: 0px 3px 0px 0px;
  color: rgba(207, 207, 207, 1);
}

.card_info-div {
  width: 22px;
  height: 18px;
}

.card_info-div > img {
  border-radius: 5px;
  width: 22px;
  height: 20px;
  object-fit: cover;
}

.card_balance_last_div {
  padding-right: 5px;
  display: flex;
  gap: 5px;
}

.account_text {
  font: normal normal normal 10px/14px Open Sans;
  color: #dadada;
  padding-top: 5px;
}

.split_view_header {
  z-index: 1;
  height: 25px;
  background-color: #1d3a6d;
  top: 0;
  position: sticky;
  color: #dadada;
  font: normal normal normal 14px/19px Open Sans;
  display: flex;
  width: 100%;
  /* padding-top: 10px; */
  padding-left: 15px;
}

.noaccountfound {
  padding: 0 0 15px 15px;
  color: #dadada;
  font: normal normal normal 14px/19px Open Sans;
}

.owner_split_div {
  border: 1px solid #031f4f;
  height: auto;
  min-height: 320px;
}

.animation_test {
  animation: test 0.2s forwards ease-in;
}

@keyframes test {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

.animation_test2 {
  animation: test2 0.2s forwards ease-in;
}

@keyframes test2 {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}

@media screen and (max-width: 960px) {
  .ownerCard {
    width: 100% !important;
  }
}

@media screen and (max-width: 700px) {
  .ownerCard {
    width: 93% !important;
  }
}

@media screen and (max-width: 540px) {
  .ownerSelectownerCard {
    width: 100% !important;
    margin: 0 !important;
  }

  .views-all-user {
    width: 22.5rem !important;
  }
}