.main-div::-webkit-scrollbar {
  width: 7px;
}

.main-div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.main-div::-webkit-scrollbar-thumb {
  background-color: #fafafa;
  outline: 1px solid #314d7e;
  border-radius: 25px;
  height: 10px;
}

.main-div::-webkit-scrollbar-thumb:hover {
  background-color: #fafafa;
}

.detail-category-button {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
}

.detail-button {
  width: 80px;
}

.category-button {
  width: 100px;
}

.category-button,
.detail-button {
  height: 30px;
  background-color: #373f4b;
  border-radius: 18px;
  color: #f9f9f9;
}

.detail-button.active,
.category-button.active {
  background-color: #f9f9f9;
  color: #373f4b;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.account-details-page-categories-card {
  background: #314d7e;
  box-shadow: 5px 5px 11px #47474733;
  border-radius: 20px;
  opacity: 1;
  padding: 20px 20px;
  margin-bottom: 60px;
  height: auto;
}

.assigned-beneficiary-categories-data-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.category-card {
  width: 220px;
  height: 59px;
  background: #506994;
  border-radius: 10px;
}

.bene-category-img {
  width: 55px;
  height: 58px;
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-left: -3px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-detail-card,
.contact-detail-card,
.support-information-card,
.origin-timeline-card {
  width: 241px;
  background-color: #314d7e;
  border-radius: 20px;
  box-shadow: -5px -5px 11px #71717133;
  opacity: 1;
}

.gst-details {
  border-radius: 10px;
}

.approved-name {
  color: #f9f9f9;
  font-size: 0.9rem;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
}

.bene-account-primary-details {
  width: 716px;
  /* margin-top: 31px; */
}

.minorAcc-div {
  width: 308px;
  /* margin-top: 30px; */
}

.associated-account-transaction-history-block,
.pay-bene,
.settings,
.bene-account-holder-details,
.pay-bene-box2 {
  height: 90px;
}

.associated-account-transaction-history-block {
  height: 90px;
  border-bottom: 2px solid #1d3a6d;
  display: flex;
}

.bene-account-holder-name,
.bene-account-name {
  text-transform: lowercase !important;
}

.bene-account-holder-name::first-line {
  text-transform: capitalize !important;
}

.bene-account-name::first-line {
  text-transform: capitalize;
}

.edit-img {
  /* transform: rotate(270deg); */
}

.bene-account-pending-status {
  color: #30e5e4;
}

.bene-account-approved-status {
  color: #4ddd37;
}

.bene-account-rejected-status,
.bene-account-blocked-status {
  color: #ff5860;
}

.bene-account-archived-status {
  color: #a3a3a3;
}

.approved-pay-bene {
  background-color: #27b882;
}

.creator-name {
  text-transform: capitalize;
}

/* .ifsc-div-one {
  flex: 60%;
} */

.button-div-element {
  flex: 33%;
}

.denied-ifsc-div {
  flex: 45%;
}

.denied-btn-div {
  flex: 55%;
}

.make-text-lower::first-letter {
  text-transform: none !important;
}

.bene_detail_email::first-letter {
  text-transform: none !important;
}

.bene_detail_heder_containerm {
  background-color: #314c7f;
  background-color: #314c7f;
  margin-top: 0px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.bene_detail_containerm {
  background-color: #314c7f;
  background-color: #314c7f;
  width: 100%;
  margin-top: 100px;
}

.bene_quickaction_containerm {
  background-color: #3a5686;
  background-color: #3a5686;
  width: 100%;
  margin-top: 20px;
}

.bene_detailcard_containerm {
  width: 100%;
  padding: 20px;
}

.quickactionheder {
  background-color: #506994;
  padding: 20px;
  color: #dadada;
  font: normal normal 600 19px Open Sans;
}

.allBenedetailheadertext {
  width: 100%;
  padding: 20px 20px;
  border-bottom: 4px solid #1d3a6d;
  display: table;
}

.allBenedetailheaderarrow {
  display: table-cell;
  width: 8%;
  vertical-align: middle;
  cursor: pointer;
}

.allBenedetailheadertextm {
  display: table-cell;
  width: 90%;
  vertical-align: middle;
  padding: 0px 10px;
}

.allBenedetailtext {
  width: 100%;
  padding: 0px 20px;
  border-bottom: 2px solid #1d3a6d;
}

.cardm {
  width: 90%;
  height: 59px;
  background: #506994;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px auto;
}

.bank-img-blockm {
  margin-right: -19px;
  float: right;
}

.service-card-rowm {
  justify-content: space-around;
  flex-direction: row;
  margin: 10px 0px 100px 0px;
  gap: 20px;
}

.settingsm {
  height: 90px;
  border-bottom: 2px solid #1d3a6d;
  display: flex;
}

.bene-account-name-blockm {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 1px;
}

.bene-account-holder-contentm {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bene-account-status-blockm {
  padding: 20px 0px;
  width: 100%;
}

.bene-account-status-contentm {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.headingAccountDetail {
  font: normal normal 600 20px/39px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}

.headertextbottomv {
  font: normal normal 600 14px/23px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
}

.bene-account-name-label {
  color: #dadada;
  opacity: 1;
  font-size: 0.75rem;
}

.bene-account-name {
  color: #f9f9f9;
  font-size: 1rem;
  opacity: 1;
  /* font-weight: 600; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: lowercase !important;
}

.bene-account-status-label,
.bene-account-holder-label {
  color: #dadada;
  opacity: 1;
  font-size: 0.75rem;
}

.bene-account-status-div {
  font-size: 0.875rem;
  opacity: 1;
  font-weight: 600;
  text-transform: capitalize;
}

.bene-account-number-label {
  color: #dadada;
  opacity: 1;
}

.bene-account-number-blockm {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 1px;
}

.bene-account-bank-holder-blockm {
  padding: 20px 0px;
  width: 100%;
}

.bene-account-holder-name {
  color: #f9f9f9;
  font-size: 1rem;
  opacity: 1;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: lowercase !important;
}

.acc-content-elementm {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-detail-cardm {
  width: 100%;
  background-color: #314d7e;
  border-radius: 1.5rem;
  box-shadow: -5px -5px 11px #71717133;
  padding: 20px;
}
.support-detail-cardm {
  width: 100%;
  background-color: #314d7e;
  border-radius: 1.5rem;
  box-shadow: -5px -5px 11px #71717133;
}

.origin-timeline-cardm {
  width: 100%;
  background-color: #314d7e;
  border-radius: 1.5rem;
  box-shadow: -5px -5px 11px #71717133;
  padding: 20px;
}

.title {
  font-size: 0.8rem;
  font-weight: 600;
  color: #dadada;
  padding-bottom: 10px;
}

.pan,
.phone {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: #dadada;
  padding-bottom: 10px;
  border-bottom: 2px solid #1d3a6d;
  width: 100%;
}

.email {
  font-size: 0.8rem;
  color: #dadada;
}

.support {
  font-size: 0.8rem;
  color: #dadada;
}

.denied-section {
  margin-top: 10px;
}

.email-part {
  color: #ffffff;
  font-size: 0.9rem;
  text-transform: capitalize;
}

.denial-reason-part {
  color: #ffffff;
  font-size: 0.9rem;
  text-transform: none;
}

.email-id-div {
  color: #f9f9f9;
  font-size: 0.9rem;
}

.gst-section {
  margin-top: 10px;
  padding-left: 4px;
  padding-right: 5px;
  padding-top: 3px;
}

.gst-label {
  font-size: 0.8rem;
  color: #c9c9c9;
  padding-left: 1px;
}

.gst-details {
  height: 50px;
  border-radius: 10px;
}
.approval-name-div {
  display: flex;
  justify-content: space-between;
}

.approval-name {
  color: #ffffff;
  font-size: 0.9rem;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.approval-date,
.archive-date,
.block-date {
  font-size: 0.7rem;
  color: #c9c9c9;
  margin-top: 4px;
  text-transform: none;
}

.approved-by {
  color: #c9c9c9;
  font-size: 0.8rem;
}

.denied-border {
  border-bottom: 2px solid #1d3a6d;
  padding-bottom: 10px;
}

.acc-status-name-last-div {
  display: flex;
  justify-content: space-between;
}

.email-section {
  margin-top: 10px;
}

.account-details-page-categories-cardm {
  margin-top: 20px;
  background: #314d7e;
  box-shadow: 5px 5px 11px #47474733;
  border-radius: 20px;
  opacity: 1;
  padding: 20px 0px;
  margin-bottom: 30px;
  height: 80vh;
  width: 95%;
}

.categories-card-assigned-text {
  display: block;
  color: #dadada;
  opacity: 1;
  font-weight: 600;
  font-size: 0.875rem;
}

.assigned-beneficiary-categories-data-divm {
  height: 90%;
  overflow: auto;
}

.bene-category-name {
  font-weight: 600;
  font-size: 0.875rem;
  color: #fafafa;
  padding-left: 6px;
}

.bene_truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ffffff;
}
.account-ifsc-div-main {
  border-left: 2px solid #1d3a6d;
  display: flex;
  flex-direction: column;
  height: 50px;
}
.account-ifsc-div-content {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  height: 55px;
  width: 172px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 6px;
}

.account-ifsc-code-element {
  color: #dadada;
  opacity: 1;
  font-size: 0.75rem;
  margin-top: 2px;
  font-size: 0.8rem;
  color: #dadada;
  /* padding-bottom: 10px; */
  width: 100%;
}
.account-ifsc-response {
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  width: 125px;
}
.internal-image-div {
  border-top-left-radius: 20px;
  position: relative;
  bottom: 36px;
  right: -172px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.internal-image-divm {
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.approved-internal {
  background-color: #69b76f;
}
