.reject-txn-card {
  display: flex;
  flex-wrap: wrap;
  width: 90% !important;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 100px;
}

.wrap-rej-acc-view {
  width: 230px;
  height: 101px;
  position: relative;
  margin: 4px 2px;
}

@media screen and (min-width: 2560px) {
  .wrap-rej-acc-view {
    width: 230px;
  }

  .multi-select-txn-Card {
    width: 223px !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1800px) {
  .wrap-rej-acc-view {
    width: 230px;
  }

  .multi-select-txn-Card {
    width: 223px !important;
  }
}

@media screen and (min-width: 1920px) {
  .multi-select-txn-Card {
    width: 225px !important;
  }
}

.multi-select-txn-Card {
  width: 238px;
  height: 101px;
  border-radius: 20px !important;
  box-shadow: -5px -5px 11px #71717133 !important;
  grid-column: span 1;
  box-sizing: border-box;
  cursor: pointer !important;
  padding-top: 10px;
}

.multi-select-txn-Card:hover {
  display: block;
}

.multi-select-txn-Card:hover {
  position: absolute;
  border: 1px solid #ff5860;
  height: 200px;
  transition: height 0.2s;
  z-index: 1;
  background-color: #506994 !important;
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {
  .multi-select-txn-Card {
    width: 220px;
  }
}

.view-all-txn-card-container_acc {
  width: 65px !important;
  height: 22px !important;
  top: 65px;
}

.bene_viewall-txn_label-div {
  display: flex;
  height: 20px;
  right: 0px;
  position: absolute;
  bottom: 65px;
}

.bene_viewall-txn_assigned {
  display: none;
  /* width: 60px; */
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  padding-left: 10px;
  padding-right: 10px;
}

.bene_txn-dlts_viewall_hover_content_acc {
  display: none;
  border-top: 1px solid #1d3a6d;
  height: 54px;
  transition: all 0.2s ease 0.2s;
}

.multi-select-txn-Card:hover .bene_txn-dlts_viewall_hover_content_acc {
  display: block;
}

.bene_viewall-txn-bank-acc-div {
  color: #fafafa;
  font-size: 14px;
  margin-bottom: 4px;
  overflow: hidden;
  width: 200px;
  text-transform: capitalize !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bene-pending-viewall-date-div {
  color: #dadada;
  font: normal normal normal 10px/14px Open Sans;
  margin-bottom: 20px;
}

.bene_pending-viewall_name_div {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: lowercase !important;
}

.bene_pending-viewall_name_div::first-line {
  text-transform: capitalize !important;
}