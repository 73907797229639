.MuiPaginationItem-icon,
.MuiPaginationItem-root {
  font-weight: 600 !important;
  color: #f9f9f9 !important;
  margin: 0 5px !important;
}

.MuiPaginationItem-root.Mui-selected {
  background-color: #667ca3 !important;
}

.MuiPaginationItem-root:hover {
  background-color: #102c5d !important;
}
