.internalTxn-dsbMainParent {
  height: 100vh;
  width: 100%;
}

.internalTxn-dashboard {
  --tw-bg-opacity: 1;
  /* position: relative;
  left: 0; */
  width: 100%;
  height: 200px;
}

.internalTxn-navMenu {
  background: #314c7f 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  padding-left: 30px;
  /* height: 265px !important; */
  /* padding: 0px 10px 10px 10px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000; /* to ensure it stays on top of other elements */
}

.internal_GridLayout {
  display: flex;
  align-items: center;
  max-width: 1024px;
  width: 95%;
  overflow: hidden;
  position: relative;
  /* flex-wrap: wrap; */
  position: relative;
  height: auto; /* Allows the height to grow dynamically */
}
.internalTxn-allSection {
  width: 100%;
  /* height: calc(100vh - 275px); */
  background: linear-gradient(to right, #1d3a6d 60%, #244277 40%) !important;
}

.internalTxn-section {
  display: flex;
  flex-direction: row;
  max-width: 1024px;
  margin: auto;
  height: 100%;
  background: linear-gradient(to right, #1d3a6d 60%, #244277 40%);
}

.internalTxn-rightSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  --tw-bg-opacity: 1;
  background-color: rgba(36, 66, 119, var(--tw-bg-opacity));
  /* width: 32%; */
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.internalTxn-content {
  --tw-bg-opacity: 1;
  display: flex;
  flex-direction: column;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  width: 68%;
  height: 100%;
  padding-bottom: 20px;
}

.internalTxn-quickActionSec {
  background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #3a5686;
  border-radius: 20px;
}

.internalTxn_LeftBenfContent {
  width: 308px;
  background-color: #314c7f;
  height: 37px;
  padding: 15px 0px;
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.internalTxn_LeftBenfContent p {
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 10px;
}
.internalTxn_RightBenfContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  /* opacity: 0.4; */
  width: 308px;
}

.internalTxn_RightSideContent {
  display: flex;
  justify-content: flex-end;
  justify-content: center;
  align-items: center;
  background-color: rgba(2, 2, 19, 0.171);
  border-radius: 6px;
  width: 20px;
  height: 20px;
}
.internalTxn_leftSidecontent {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: #f9f9f9;
  display: flex;
  align-items: center;
  gap: 5px;
}

.internalTxn_RightSideContent > img {
  width: 6px;
  height: 11px;
  color: white;
}

.internalTxn_leftSidecontent img {
  width: 25px;
  height: 25px;
  opacity: 1;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.internalTxn_leftSidecontent p {
  width: 95px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.internalTxn-right-middleSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 20px;
  gap: 15px;

  width: 308px;
  height: 170px;

  background: #3a5686;
  border-radius: 20px;
}

.internalTxn-right-middleSection h5 {
  /* Auto Transfer Matrix */

  width: 268px;
  height: 19px;

  /* Web Font/14 Semibold */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #dadada;
}
.internalTxn-right-middleSection p {
  /* Auto transfer allows you to schedule internal transactions for a single time or set it up to repeat at your chosen frequency. */

  width: 254px;
  height: 48px;

  /* Web Font/12 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #dadada;
}

.internal-createAc {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  gap: 3%;
  /* padding-top: 7%; */

  img {
    width: 25px;
    height: 25px;
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
}

.internalTxn_CreateTxn {
  color: #f9f9f9 !important;
  width: 141px;
  height: 22px;
  margin-top: 21px;
  margin-bottom: 22px;
  text-align: center;
  font: normal normal normal 16px / 22px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
}

.internalTxn-right-middleSection button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 10px;

  width: 98px;
  height: 33px;

  background: #506994;
  border: 1px solid #bb5353;
  border-radius: 20px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #f9f9f9;
}

.internalTxn_RecentPage {
  display: flex;
  max-width: 680px;
  justify-content: space-between;
  height: 42px;
  background: #234176 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000033;
  border-radius: 21px;
  opacity: 1;
  margin-right: -20px;
  margin-top: 20px;
}

.internal_RecentText {
  width: 51px;
  height: 22px;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.internal_GridLaySection {
  display: flex;
  flex-direction: row;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  margin-top: 20px;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 10px;
}

.internal-GridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  width: 680px;
  row-gap: 20px;
  column-gap: 20px;
}

.pendingColor {
  color: #31e5e5;
}

.rejectedColor {
  color: #ff5860;
}

.approvedColor {
  color: #b196fa;
}

.queuedColor {
  color: #fbc02d;
}

.settledColor {
  color: #4ddd37;
}

.abandonedColor {
  color: #b4b4b4;
}

.cancelledColor {
  color: #ff5860;
}

.deniedColor {
  color: #ff5860;
}

.internalTxn_Dashboard_mobile_header {
  display: none;
}

.internalText-dashboard-mob {
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  color: #f9f9f9;
}

.internalTransText-dashboard-mob {
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: left;
  color: #dadada;
}

.internal_CaraouselBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 5px;
  width: 1024px;
  /* margin: auto; */
  margin-top: -10px;
  height: 20px;
}

.internal_CarouselDotbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12px;
  gap: 15px;
  width: 73px;
}

.internal_FirstCaraoDot {
  width: 46px;
  height: 12px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
}

.internal_SecondCaraoDot {
  width: 12px;
  height: 12px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 0.65;
}
.internal_ThirdCaraoDot {
  width: 12px;
  height: 12px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 0.65;
}

.internal_NextAndPrevIcon {
  display: flex;
  align-items: center;
  height: 20px;
  gap: 15px;
  width: 77px;
}

.internal_CarouPrevIcon {
  img {
    width: 20px;
    height: 20px;
    opacity: 0.5;
    border-radius: 32px;
  }
}

.internal_CarouNextIcon {
  img {
    width: 20px;
    height: 20px;
    border-radius: 32px;
  }
}

.internal_ViewallPage {
  width: 103px;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000033;
  border: 1.6px solid #ff5860;
  border-radius: 21px;
  opacity: 1;
  cursor: pointer;
}

.internal_ViewallPage p {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.internal_ViewallPage img {
  width: 12px;
  height: 14px;
  color: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.mob-view-matrix {
  /* Frame 11486 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 10px;
  gap: 10px;

  width: 100%;
  height: 45px;

  background: #506994;
  border: 1px solid #ff5860;
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: center;
  color: #f9f9f9;
}
.showFirstContents {
  transform: translateX(0%);
  position: absolute;
}

.showSecondContents {
  transform: translateX(0%);
  position: absolute;
}

.showThirdContents {
  transform: translateX(0%);
  position: absolute;
}

.hideFirstContents {
  transform: translateX(-100%);
}

.hideSecondContents {
  transform: translateX(850%);
}

.hideThirdContents {
  transform: translateX(750%);
}

.transition-effects {
  display: flex;
  transition: transform 0.2s linear;
  flex-wrap: wrap !important;
  align-items: flex-start;
  gap: 5px;
  column-gap: 0px;
  height: 100%;
  overflow: hidden;
}

@media (min-width: 800px) and (max-width: 1024px) {
  .internalTxn_RecentPage {
    width: 610px;
  }
}

@media (max-width: 800px) {
  .internalTxn_Dashboard_mobile_header {
    display: block;
    padding: 10px 0;
    width: 100%;
    height: 60px;
    background: #3a5686 0% 0% no-repeat padding-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .internalTxnMobHeaderCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .internalTxn-navMenu {
    background: #1d3a6d 0 0 no-repeat padding-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
    /* padding: 20px 0px 20px 0px !important; */
    /* padding: 15px !important; */
    /* position: relative; */
    width: 100%;
  }
.showFirstContents {
  transform: translateX(0%);
  position: absolute;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2px;
}

.showSecondContents {
  transform: translateX(0%);
  position: absolute;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2px;
}

.showThirdContents {
  transform: translateX(0%);
  position: absolute;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2px;
}
  .internalTxn-dsbMainParent {
    height: auto;
    padding-bottom: 105px;
    width: 100%;
  }

  .internal-GridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, max-content));
    justify-content: center; /* Centers the grid items horizontally */
    width: 100%;
    /* grid-column: 1 / 4; */
    height: auto;
  }

  .internal_GridLayout {
    /* height: 250px; */
    height: auto;
    align-items: initial;
    margin-top: 0;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    flex-grow: 1;
  }

  .internal_CaraouselBar {
    width: 90%;
    margin-top: 10px;
  }

  .internalTxn-section {
    flex-direction: column; /* Stack items vertically */
    gap: 20px;
    height: 100%;
  }

  .internalTxn-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Full width */
    padding-bottom: 20px;
    height: 100%;
  }

  .internalTxn_RecentPage {
    width: 90%;
    margin-right: 0px;
  }

  .internalTxn-rightSection {
    width: 100%; /* Full width */
    order: 2; /* Move below internalTxn-content */
    margin-top: -20px;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  }

  .internalTxn-rightSection .has-data {
    width: 35%;
  }

  .internalTxn-rightSection .no-data {
    width: 32%;
  }

  .internal-createAc {
    display: flex;
    justify-content: center;
    width: 100%; /* Full width */
    margin-bottom: 20px; /* Add spacing below */
  }

  .internalTxn-quickActionSec {
    width: 90%;
  }

  .internalTxn_LeftBenfContent {
    width: 100%;
  }

  .internal-createAc {
    display: flex;
    flex-direction: column;
  }

  .internal-createAc img {
    width: 40px;
    height: 40px;
  }

  .internalTxn_CreateTxn {
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: center;
    color: #f9f9f9;
  }

  .internalTxn-right-middleSection {
    /* Frame 11485 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    gap: 10px;

    height: 87px;
    left: 0px;
    top: 174px;

    background: #3a5686;
    width: 100%;
    border-radius: 0;
  }

  .internalTxn-right-middleSection h5 {
    /* Auto Transfer Matrix */

    /* Auto Transfer Matrix */

    width: 330px;
    height: 19px;

    /* Web Font/14 Semibold */
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    color: #dadada;

    opacity: 0.5;
  }
  .internalTxn-right-middleSection p {
    /* Auto transfer allows you to schedule internal transactions for a single time or set it up to repeat at your chosen frequency. */

    /* Auto transfer allows you to schedule internal transactions for a single time or set it up to repeat at your chosen frequency. */

    width: 326px;
    height: 28px;

    /* Web Font/10 Regular */
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;

    color: #dadada;
  }

  .internalTxn_RightBenfContent {
    width: 100%;
  }
}
