.hand-pointer {
  cursor: pointer;
}

.block-pointer {
  cursor: not-allowed;
  opacity: 0.5;
}

.ifsc-first-div {
  height: 200px;
  background: #314c7f 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  opacity: 1;
  display: flex;
  align-items: flex-start;
  padding-top: 18px;
  justify-content: center;
  padding-bottom: 22px;
}

.ifsc-bank-dropdown {
  width: 393px;
  height: 49px;
  background: #667ca3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.ifsc-bank-dropdown-expand {
  transition: height 0.2s ease;

  width: 393px;
  height: 212px;
  background: #667ca3 0% 0% no-repeat padding-box;
  border: 1px solid #e25869;
  border-radius: 10px;
  opacity: 1;
  z-index: 1;
}

.ifsc-bar-container {
  padding-top: 110px;
  padding-left: 260px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ifsc-bar-container-others {
  padding-top: 110px;
  padding-right: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.ifsc-code {
  width: 393px;
  height: 49px;
  background: #667ca3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.ifsc-option {
  width: 23px;
  height: 22px;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.ifsc-partition {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ifsc-partition-css {
  margin-top: 15px;
  margin-left: 10px;
}

.ifsc-partition > div {
  margin: 0 10px;
}

.ifsc-partition-one {
  flex: 80%;
}

.ifsc-partition-two {
  flex: 20;
}

.ifsc-partition-one-css {
  width: 182px;
  height: 22px;
  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.ifsc-down-img {
  width: 20px;
  height: 18px;
}

.ifsc-up-img {
  transform: matrix(-1, 0, 0, -1, 0, 0);
}

.ifsc-down-search {
  width: 20px;
  height: 20px;
}

.ifsc-fetch-css {
  width: 128px !important;
  height: 49px !important;
  background: #f9f9f9 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  text-align: center !important;
  font: normal normal 600 14px/19px Open Sans !important;
  letter-spacing: 0px !important;
  color: #1d3a6d !important;
  text-transform: none !important;
}

.ifsc-fetch-css-dis {
  width: 128px !important;
  height: 49px !important;
  background: #707070 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  text-align: center !important;
  font: normal normal 600 14px/19px Open Sans !important;
  letter-spacing: 0px !important;
  color: #f9f9f9 !important;
  text-transform: none !important;
}

.splitline-dd {
  margin-top: 8px;
  margin-bottom: 10px;
  margin-left: 16px;
  width: 90%;
  height: 1px;
  border: 1px solid #314d7e;
  opacity: 1;
}

.bank-name-search-dd {
  width: 373px;
  height: 40px;
  background: #314d7e 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-left: 9px;
  flex-wrap: wrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label-left {
  text-align: left !important;
}

.bank-name-dd-options {
  height: 110px;

  overflow-y: auto;
  overflow-x: hidden;
}

.bank-name-dd-options .bank-name-search-dd {
  opacity: 0;
  animation: fadeIn 0.2s ease forwards;
  animation-delay: 0.2s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.bank-name-dd-options::-webkit-scrollbar {
  width: 5px;
}

.bank-name-dd-options::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.bank-name-dd-options::-webkit-scrollbar-thumb {
  background-color: #fafafa;
  outline: 1px solid #314d7e;
  border-radius: 25px;
  height: 40px;
}

.bank-name-dd-options::-webkit-scrollbar-thumb:hover {
  background-color: #fafafa;
}

.bank-name-search-dd-color {
  background: #667ca3 0% 0% no-repeat padding-box !important;
}

.bank-name-search-dd-text {
  text-align: left;
  align-items: center;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-top: 10px;
  padding-left: 10px;
}

.bank-name-search-dd-text:hover {
  background: #506994 0% 0% no-repeat padding-box !important;
}

.input-custom {
  background: #314d7e 0% 0% no-repeat padding-box;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  color: #ffffff;
  font: normal normal normal 14px/19px "Open Sans", sans-serif;
  outline: none;
}

.input-custom-ifsc {
  background: #667ca3 0% 0% no-repeat padding-box;
  width: 100%;
  height: 22px;
  box-sizing: border-box;
  color: #fafafa;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
  outline: none;
}

.input-custom-ifsc::placeholder {
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.input-custom::placeholder {
  font: normal normal normal 14px/19px "Open Sans", sans-serif;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.ifsc-enter-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8.75rem;
}

.ifsc-no-enter-image {
  width: 400px;
  height: 284px;
  opacity: 1;
}

.ifsc-enter-label {
  width: 522px;
  height: 54px;
  text-align: center;
  font: normal normal normal 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  padding-top: 20px;
}

.ifsc-card-viewer {
  max-height: 528px;
  margin-left: 100px;
}

.ifsc-details-card {
  width: 250px;
  height: 127px !important;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 20px !important;
  box-shadow: -5px -5px 11px #71717133 !important;
  grid-column: span 1;
  box-sizing: border-box;
  padding: 5px !important;
  margin: 10px;
  padding-right: 1px !important;
  cursor: pointer;
}

.ifsc-details-card:hover {
  background-color: #506994 !important;
}

.ifsc-code-card {
  width: 220px;
  height: 23px;
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  padding-top: 4px;
  padding-left: 8px;
}

.ifsc-code-card-label {
  width: 200px;
  height: 14px;
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  padding-left: 8px;
  margin-top: 4px;
}

.ifsc-logo-card-container {
  margin-right: 0px !important;
  width: 98px !important;
  height: 40px !important;
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 20px 0px;
  margin-top: 0px;
  margin-left: 147px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ifsc-bank-image {
  width: 70px;
  height: 18px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
}

.ext-padding {
  padding-top: 4px;
}

.loader-height {
  margin-top: 220px;
}

#infinityScrollContainerIfscView {
  display: flex !important;
  flex-direction: column !important;
  overflow: auto;
  position: relative;
  height: 100%;
}

.ifscCard {
  left: 5rem;
  padding-top: 8px;
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .ifscCard {
    left: 6rem;
  }
}

@media screen and (min-width: 2560px) and (min-height: 1440px) {
  .ifscCard {
    left: 8rem;
  }
}

@media screen and (min-width: 3840px) and (min-height: 1920px) {
  .ifscCard {
    left: 12rem;
  }
}

.ifscCard {
  display: flex;
  flex-wrap: wrap;
  width: 90% !important;
  margin: 0 auto;
  padding-bottom: 30px;
}

.ifsc-cancle-img {
  width: 20px;
  height: 20px;
}
