.MyProfile-main {
  /* background-color: #3a5686; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.MyProfile-main-fixed {
  background-color: #3a5686;
  box-shadow: 5px 5px 11px #00000026;
  height: auto;
  margin-top: 80px;
}

.MyProfile-Container {
  background-color: #3a5686;
}

.My-Profile-details1 {
  max-width: 1024px;
}

.Myprofile-detail-button {
  width: 100px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #373f4b;
  border-radius: 30px;
  cursor: pointer;
  color: white;
}

.Myprofile-detail-button.active1 {
  width: 100px;
  height: 30px;
  color: #373f4b;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f9f9f9;
  border-radius: 30px;
}

.profile_roles_main {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: flex-start;
  height: fit-content;
  overflow-y: auto;
}

.profile_roles_child {
  width: 241px;
  height: 144px;
  padding: 15px;
  gap: 4px;
  border-radius: 20px;
  background-color: #314d7e;
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  margin-right: 14px;
}

.profile_roles_child > h4 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 211px;
  height: 19px;
  color: #f9f9f9;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.profile_roles_des {
  margin-top: 5px;
}

.profile_roles_des > p {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
  width: 213px;
}

.profile_roles_des > h6 {
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 13.62px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 213px;
  color: #dadada;
  margin-top: 5px;
}

.roles_not_available {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 211px;
  height: 19px;
  color: #f9f9f9;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.MyProfile_cin_address_slide_right {
  height: 325px;
  width: 288px;
  margin-top: 20px;
}
