.internalTxnviewallsidebar {
  background-color: #3a5686;
  margin-top: 221px;
  height: 0px;
  border-radius: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: height 0.2s ease-in;
  visibility: hidden;
}

.internalTxnviewallsidebar.open {
  visibility: visible;
  height: 298px;
  transition: height 0.2s ease-in;
}

.selected-category {
  background-color: #3a5686;
}

.selected-category:hover {
  background-color: transparent;
}

.internalTxnviewallsidebar.closed {
  transition: height 0.2s ease-in, visibility 0s linear 0.2s;
  height: 0;
  visibility: hidden;
}

.internalTxnviewallFilterDiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px !important;
  margin: 0 auto;
  padding-top: 20px;
  z-index: 1;
  flex-direction: row;
}

.internalTxnFilterSection1 {
  display: flex;
  flex-direction: column;
  height: 165px;
  padding-right: 30px;
}

.internalTxn_viewall_select_account {
  transform: matrix(0, 1, 1, 0, 0, 0);
}

.internalTxnFilterSection2 {
  display: flex;
  flex-direction: column;
  height: 180px;
  padding-right: 45px;
  border-left: 1px solid #1d3a6d;
  border-right: 1px solid #1d3a6d;
}

.internalTxnFilterSection3 {
  display: flex;
  flex-direction: column;
  height: 225px;
  padding-right: 30px;
}

.internalTxnFilterbuttonContainer {
  display: flex;
  padding-top: 210px;
}

.internalTxn-viewall_upperdiv_opt {
  position: relative;
  top: -18px;
  display: inline-flex;
}

.internalTxn_select_scroll_line {
  width: 171px;
  height: 1px;
  position: absolute;
  background-color: #3a5686;
  top: 39px;
  left: 10px;
}

.bene-txn-close-btn-div {
  display: grid;
  justify-items: center;
  border-radius: 0.375rem;
  height: 2rem;
  width: 2rem;
  background-color: #1d3a6d;
  top: 140px;
}

.bene-txn-close-btn-div:hover {
  background-color: #506994;
}

.clear-filter:hover {
  background-color: #506994;
}

.internalTxn-filter_text {
  font-size: 16px;
  display: inline-flex;
}

.dropdown-menu {
  overflow-y: scroll !important;
  height: 100px !important;
  background-color: #667ca3 !important;
}

.internalTxn_Calendar_main_div {
  border-radius: 0.75rem;
  /* margin-left: 15px; */
}

.internalTxn_date_heading_div {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: space-around;
  height: 35px;
  width: 305px;
}

.internalTxn_date_heading_left {
  display: flex;
  text-align: left;
  font: normal normal normal 12px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 20px !important;
}

.internalTxn_filter_search-bar {
  background-color: #667ca3 !important;
  width: 150px !important;
  color: #ffffff !important;
}

.internalTxn_date_heading_right {
  display: flex;
  font: normal normal normal 12px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 35px !important;
  margin-right: 20px !important;
  justify-content: end;
}

.internalTxn_date_select_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 35px;
  width: 305px;
}

.internalTxnMoredetails {
  box-shadow: 0px 3px 6px #00000029;
}

.internalTxn_viewall_calendar_icon {
  margin-top: -2px;
  color: #dadada !important;
}

.react-datepicker__month--march .date-picker-container,
.react-datepicker__month--june .date-picker-container {
  width: 305px;
  /* height: 390px; */
}

.react-datepicker__month--march .react-datepicker__month-container,
.react-datepicker__month--june .react-datepicker__month-container {
  overflow: hidden;
}

.internalTxn_category_select {
  padding: 0 !important;
  width: 200px !important;

  /* height: 35px !important; */
  text-transform: none !important;
  position: absolute !important;
  z-index: 99 !important;
  background-color: #667ca3 !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
  /* transition: all 0.2s ease !important; */
  margin-top: 5px !important;
}

.internalTxn_payment_select {
  padding: 0 !important;
  width: 216px;
  position: relative;
  /* height: 35px !important; */
  text-transform: none !important;
  z-index: 9999 !important;
  background-color: #667ca3 !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
  /* transition: all 0.2s ease !important; */
  margin-top: 5px !important;
}

.paymentTxn_category_select_scroll_line {
  width: 196px;
  height: 1px;
  position: absolute;
  background-color: #3a5686;
  top: 39px;
  left: 10px;
}

.categoryTxnname {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.internalTxn_category_select_scroll_com {
  position: relative;
  width: 178px !important;
  height: 30px !important;

  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px/17px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  padding-left: 5px !important;
  margin-bottom: 3px !important;
}

.internalTxn_category_select_scroll_com:hover {
  background-color: #506994 !important;
  border-radius: 5px !important;
}

.internalTxn_filter_category_select_scroll_com {
  width: 178px !important;
  height: 30px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px/17px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-bottom: 7px !important;
  padding-left: 3px !important;
}

.internalTxn_filter_category_select_scroll_com:hover {
  background-color: #506994 !important;
  border-radius: 5px !important;
}

.bene_input_div_element {
  width: 179px;
  height: 30px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  padding: 8px 10px 10px 10.5px;
  justify-content: space-around;
  margin-left: 3px;
}

.internalTxn_filter_by_category_scroll {
  max-height: 114px !important;
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 2px;
  transition: max-height 0.2s ease;
}

.internalTxn_filter_by_category_scroll::-webkit-scrollbar {
  width: 5px;
}

.internalTxn_filter_by_category_scroll::-webkit-scrollbar-thumb {
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 5px;
  height: 4px;
}

.internalTxn_filter_by_category_scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.internalTxn_number_text {
  text-align: left;
  font: normal normal normal 12px / 17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  padding-bottom: 5px;
  margin-top: 20px;
}

.internalTxn_text_input {
  background: #667ca3;
  border-radius: 10px;
  opacity: 1;
  width: 216px;
  height: 35px;
  padding: 15px;
  font: normal normal normal 12px / 17px Open Sans;
  color: #dadada;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .internalTxnFilterbuttonContainer {
    position: absolute;
    right: 10px;
  }

  .internalTxnFilterSection1 {
    padding-right: 15px;
  }

  .internalTxnFilterSection2,
  .internalTxnFilterSection3 {
    padding: 0 15px !important;
  }
}

@media screen and (max-width: 800px) {
  .internalTxn_category_select_scroll_com {
    width: 100% !important;
  }

  .categoryTxnname {
    width: 90% !important;
  }

  .internalTxn_category_select_select > span {
    width: 90% !important;
  }
}
