.bene_Stepper_Main {
  width: 1024px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom {
  color: #ffffff !important;
  height: 59.85px !important;
  background-color: #506994 !important;
  width: 63px !important;
  padding-top: 10px !important;
}

.stepperline_Div {
  width: 898px;
  height: 59.85px;
  padding: 0px 80px;
}

.step_Icon {
  height: 18px;
  width: 18px;
}

.stepperline_Div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.stepinnerline {
  height: fit-content;
  width: fit-content;
}

.stepDetails {
  font-size: 12px;
  color: #ffffff;
  width: fit-content;
  display: inline-flex;
  overflow: hidden;
  white-space: nowrap;
}

.create_Button_1 {
  color: #ffffff !important;
  height: 59.85px !important;
  width: 126px !important;
  padding-top: 10px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.main_Line {
  height: 7px;
  border-radius: 4px;
  background-color: #ffffff;
}

@keyframes growHeight {
  from {
    width: 100px; /* Starting height */
  }
  to {
    width: 220px; /* Final height */
  }
}

.growing-element {
  animation: growHeight 0.7s backwards; /* Adjust the duration and timing function as needed */
}
