.beneTxnViewAllNavbar {
  width: 100%;
}

.beneTxnViewAllSec1 {
  width: 100%;
  background: #3a5686;
  position: fixed;
  z-index: 1001;
  opacity: 1;
  /* height: 208px; */
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.secondDivElement {
  display: flex;
}

.acc_blackTxn_div {
  position: absolute;
  opacity: 0.8;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
}

.beneTxnViewAllSec2 {
  /* width: 1024px; */
  /* display: flex; */
  /* background: linear-gradient(to right, #506994 32.5%, #3a5686 20%); */
  height: 59px;
  /* justify-content: flex-end; */
  /* align-items: end; */
  margin: auto;
  position: relative;
  top: 100px;
  /* bottom: -94px; */
}

.beneTxnViewAll_middleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 59px; */
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.beneTxnViewAllWholeSection {
  width: 463px;
  display: flex;
  background-color: #506994;
  height: 91px;

  position: relative;
}

.beneTxnViewallAmountSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin-right: 90px; */
  height: 49px;
  /* border-left: 1px solid #1d3a6d; */
  margin-top: 20px;
}

.beneTxnViewAllParentdiv {
  display: flex;
  flex-direction: column;
  gap: 04px;
}

.beneTxnTotalCount {
  display: inline-block;
  transform: rotate(270deg);
  font-style: italic;
  color: #ffffff;
  margin-right: 5px;
  font-size: 15px;
}

.benetxnViewallCount {
  font: normal normal 600 20px/27px Open Sans;
}

.beneTxnViewAllchild1 {
  /* width: 120px;
  height: 27px; */
  text-align: left;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  white-space: nowrap;
  /* margin-left: 10px; */
}

.ver__divider {
  border: 1px solid #1d3a6d;
  height: 86px;
  position: absolute;
  /* top: 5px; */
  /* left: 10px; */
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.beneTxnViewallTotalAmount {
  width: 180px;
  height: 27px;
  text-align: left;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  white-space: nowrap;
}

.beneTxnViewAllchild2 {
  width: 100px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: -3px;
  margin-top: 8px;
}

.bene_txn_filterdropdown {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1024px !important;
  padding-top: 10px;
}

.bene_txn_viewall_calendar_icon {
  margin-top: -2px;
  height: 14px;
  align-items: center;
  justify-content: center;
  width: 14px !important;
}

.totalBalance {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.totalBalanceAmountDiv {
  font: normal normal normal 600 14px/19px Open Sans;

  color: #4ddd37;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bene_txn_filterdropdown-container-div {
  background-color: #3a5686;
  margin-top: 150px;
  height: 90px;
  border-radius: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.MultiApprovalclear {
  width: 87px;
  height: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #f9f9f9;
  border-radius: 8px;
  padding: 3px 27px;
  opacity: 1;
}

.transactionAmountTitle {
  width: 114px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 20px;
  position: relative;
  right: 30px;
  margin-top: 4px;
}

/* .beneTxn_viewall_filter_div {
  margin-left: auto;
} */

.beneTxn_viewall_filter_div.filtered .beneTxnViewAll_filter_container {
  background: #f9f9f9;
  /* margin-left: auto; */
}

.beneTxnViewAll_filter_container {
  display: flex;
  justify-content: space-between;
  width: 50px;
  height: 30px;
  padding: 9px;
  margin-top: 20px;
  background: #244277;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  cursor: pointer;
  margin-left: 20px;
}

.beneTxnViewAll_filter_container:hover {
  background-color: #506994;
}

.bene-txn-filter-image {
  width: 12px !important;
  height: 12px;
}

.filter-images-ele {
  width: 12px !important;
  height: 12px;
}
.beneTxn_viewAll_search_div {
  margin-left: 30px;
  width: 50px;
  background-color: #244277;
  margin-top: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* gap: 6px; */
  height: 30px;
  cursor: pointer;
}

/* @media screen and (max-width: 2400px) and (min-width: 1500px) {
  .beneTxn_viewAll_search_div {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 3840px) and (min-width: 2560px) {
  .beneTxn_viewAll_search_div {
    margin-right: 1rem;
  }
} */

.bene-txn-no-acc-image {
  /* width: 288px; */
  height: 250px;
  opacity: 0.9;
}

.bene-txn-no-beneaccount-text,
.no-beneaccount-text2 {
  color: #ffffff;
  opacity: 1;
  font-size: 1rem;
  text-align: center;
  /* margin-top: 12px; */
}

.no-beneaccount-text2 {
  display: inline-flex;
  padding-bottom: 18px;
}

/* .bene-txn-viewall-loader-container {
  img {
    width: 49px;
    height: 50px;
  }
} */

.beneTxnViewAll_middle_section_container {
  /* margin-top: 232px; */
  display: flex !important;
  justify-content: space-between !important;
  width: 89%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 13px;
}

.bene_txn_viewall_filter_div_left {
  display: flex;
  align-items: center;
  margin-top: 23px;
  margin-left: 12px;
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

/* .bene_txn_viewall_filter_div_left p {
  top: 149px;
  left: 330px;
  height: 18px;
} */

.bene_txn_viewall_filter_div_left .bene_txn_viewall_filter_body {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
  box-shadow: 0px 3px 6px #00000024;
  border: 2px solid #667ca3;
  border-radius: 48px;
  opacity: 1;
}

.bene_txn_viewall_filter_div_left .bene_txn_viewall_filter_img_div {
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 26px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bene_txn_viewall_filter_div_left .bene_txn_viewall_filter_img_div > img {
  width: 10px;
  height: 10px;
}

.bene_txn_viewall_filter_div_left .bene_txn_viewall_close_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  background-color: #667ca3;
  border-radius: 26px;
  opacity: 1;
}

.bene_txn_viewall_filter_div_left .bene_txn_viewall_close_btn > img {
  height: 8px;
  width: 8px;
}

#txnViewallScrollContainer {
  display: flex !important;
  flex-direction: column !important;
  overflow: auto;
  position: relative;
  height: 100%;
}

.beneTxnViewallCard {
  left: 5rem;
  padding-top: 0px;
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .beneTxnViewallCard {
    left: 6rem;
  }
}

@media screen and (min-width: 3840px) and (min-height: 1920px) {
  .beneTxnViewallCard {
    left: 12rem;
  }
}

.bene-txn-viewall-page-select-div {
  margin-top: 20px;
  margin-left: 20px;
  height: 34px !important;
  /* width: 130px !important; */
  cursor: pointer;
  display: flex;
  background: #244277;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}

.bene-txn-viewall-pagination-containers {
  margin-top: 20px;
  margin-left: 20px;
  height: 34px !important;
  width: 132px !important;
  /* cursor: pointer; */
  display: flex;
  background: #244277;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}

.bene_viewall_data_dropdown {
  border-radius: 0px 5px 5px 0px;
  padding: 8px 0px 0px 0px;
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.bene_txn_dropdown_options {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffff;
  width: 60px;
  height: 33px;
  font-size: 12px;
  padding-left: 12px;
}

.bene_txn_dropdown_options:hover {
  background-color: rgb(86, 110, 151);
}

.bene_txn_viewall_toggle_containers {
  margin-top: 20px;
  margin-left: 20px;
  height: 30px !important;
  width: 68px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  background: #244277;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}

.toggle-box-txn {
  border-radius: 5px;
  padding: 8px;
  height: 30px !important;
  width: 34px !important;
}

.beneTxn_viewAll_search_div.searched {
  background: #f9f9f9;
}

.bene_txn_viewall_pagination_input {
  font-family: "Open Sans", sans-serif;
  /* font-weight: 600; */
  font-size: 12px;
  line-height: 20px;
  padding: 4px 7px;
  width: 38px;
  height: 33px;
  text-align: center;
  border: 1px solid #fff;
  /* border-radius: 4px; */
  background-color: #f9f9f9;
  color: #314d7e;
  box-shadow: none;
}

.bene_txn_viewall_pagination_input:focus {
  outline: none;
  border-color: #fff;
}

.txn-main-btn {
  margin: 0 !important;
  width: 57px !important;
  font: normal normal normal 12px / 17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
  position: absolute !important;
  z-index: 1 !important;
  background-color: #667ca3 !important;
  display: flex !important;
  justify-content: space-evenly;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

.txn_high_to_low_select_select {
  display: flex;
  justify-content: space-between;
  /* width: 100%;
    height: 39px; */
  padding-right: 10px;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
}

.txn_high_to_low_select_scroll {
  position: absolute;
}

.txn_select_scroll_com {
  height: 24px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px / 15px Open Sans !important;
  letter-spacing: 0px !important;
  margin: 4px 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
  padding: 2px 4px;
}

@media screen and (max-width: 1024px) {
  .beneTxnViewAllSec2 {
    width: 900px !important;
  }
  .totalBalance p {
    width: 170px !important;
  }
  .beneTxnViewAllDistriPopover {
    width: 200px !important;
  }
  .beneTxnViewAllWholeSection {
    width: 430px;
  }
  .beneTxnViewAllPopover {
    width: 210px !important;
  }
  .bene-txn-viewall-popover {
    width: 215px !important;
  }
  .bene-txn-distri-viewall-popover {
    width: 215px !important;
    margin-left: 5px !important;
  }
  .beneTxn_viewAll_search_div {
    margin-left: 15px !important;
  }
}
@media screen and (max-width: 900px) and (min-width: 800px) {
  .beneTxnViewAll_middleBar {
    justify-content: start !important;
  }

  .totalBalance {
    padding-left: 10px;
  }

  .beneTxnViewAllSec2 {
    width: 800px !important;
  }

  .beneTxnViewAllPopover {
    width: 200px !important;
  }

  .bene_viewall_popover_icon {
    left: 155px !important;
  }

  .bene_distri_viewall_popover_icon {
    left: 152px !important;
  }

  .bene-txn-viewall-popover {
    width: 198px !important;
  }

  .bene-txn-distri-viewall-popover {
    width: 197px !important;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 800px) {
  .beneTxnViewAll_middleBar {
    justify-content: space-between !important;
  }
  .totalBalance p {
    width: 250px !important;
  }
  .beneTxnViewAllPopover,
  .beneTxnViewAllDistriPopover {
    width: 100% !important;
  }
  .bene-txn-distri-viewall-popover {
    margin-left: 0 !important;
  }
  .beneTxnViewAllSec2 {
    max-width: 100% !important;
  }

  /* .bene-txn-distri-viewall-popover.open {
    padding: 7px;
  } */
  .beneTxnViewAllDistriPopover {
    -webkit-tap-highlight-color: transparent;
  }

  .beneTxnViewAllPopover {
    -webkit-tap-highlight-color: transparent;
  }

  .bene_txn_viewall_filter_div_left {
    margin-top: 15px !important;
  }

  .bene_txn_filterdropdown-container-div {
    margin-top: 140px !important;
    height: 80px !important;
  }
}

@media screen and (max-width: 500px) {
  .bene-txn-distri-viewall-popover,
  .bene-txn-viewall-popover {
    width: 100% !important;
  }
  .bene_distri_viewall_popover_icon,
  .bene_viewall_popover_icon {
    left: 130px !important;
  }
  .totalBalance p {
    width: 200px !important;
  }
  .beneTxnViewAll_middleBar {
    padding: 0 !important;
  }
  .benTxnViewall_distri_inner_div {
    padding-left: 10px !important;
  }
}

.transactionHistoryPop_topDiv {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1d3a6d;
  width: 100%;
  z-index: 9999;
}
