.BeneTxn_DashBoardMainParent {
  height: 100vh;
  width: 100%;
}

.BeneTxn_Dashboard {
  --tw-bg-opacity: 1;
  /* position: relative;
  left: 0; */
  width: 100%;
  height: 280px;
}

.BeneTxn_NavMenu {
  background: #314c7f 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  opacity: 1;
  height: 275px;
  padding-left: 30px;
  padding: 0 10px;
  padding-top: 65px;
}

.BeneTxn_CaraouselBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 5px;
  width: 1024px;
  margin: auto;
  margin-top: -20px;
  height: 20px;
}

.BeneTxn_CarouselDotbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12px;
  gap: 15px;
  width: 73px;
}

.BeneTxn_FirstCaraoDot {
  width: 46px;
  height: 12px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
}

.BeneTxn_SecondCaraoDot {
  width: 12px;
  height: 12px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 0.65;
}
.BeneTxn_ThirdCaraoDot {
  width: 12px;
  height: 12px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 0.65;
}

.BeneTxn_NextAndPrevIcon {
  display: flex;
  align-items: center;
  height: 20px;
  gap: 15px;
  /* width: 77px; */
}

.BeneTxn_CarouPrevIcon {
  img {
    width: 20px;
    height: 20px;
    opacity: 0.5;
    border-radius: 32px;
  }
}

.BeneTxn_CarouNextIcon {
  img {
    width: 20px;
    height: 20px;
    border-radius: 32px;
  }
}

.beneTxngridlayout {
  margin: auto;
  max-width: 1024px;
  height: 50%;
  margin-top: 48px;
  margin-bottom: 30px;
  display: grid;
  gap: 0px;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.BeneTxn_GridLayout {
  margin: auto;
  max-width: 1024px;
  /* height: 50%; */
  margin-top: 52px;
  display: flex;
  justify-content: start;
  gap: 10px;
  overflow-x: hidden;
  /* height: 9rem; */
  position: relative;
}

.transition-effect {
  display: flex;
  transition: transform 0.2s linear;
}

.datacard {
  background-color: red;
  margin-top: 6px;
  width: 129px;
  height: 111px;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
}

.showSecondContent {
  transform: translateX(0%);
  position: absolute;
}

.hideSecondContent {
  transform: translateX(850%);
}

.showFirstContent {
  transform: translateX(0%);
  position: absolute;
}

.hideFirstContent {
  transform: translateX(-105%);
}
.showThirdContent {
  transform: translateX(0%);
  position: absolute;
}
.hideThirdContent {
  transform: translateX(750%);
}

.BeneTxn_AllSection {
  width: 100%;
  height: calc(100vh - 275px);
  background: linear-gradient(to right, #1d3a6d 60%, #244277 40%);
}

.BeneTxn_TxnSection {
  display: flex;
  flex-direction: row;
  max-width: 1024px;
  margin: auto;
  height: 100%;
  background: linear-gradient(to right, #1d3a6d 60%, #244277 40%);
}

.createAc {
  cursor: pointer;
}

.createAc:hover {
  border-radius: 20px;
}

.BeneTxn_ContentPage {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  width: 66%;
  padding-top: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 66%;
}

.expentraloaderwithDashBoard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;

  img {
    width: 180px;
    height: 180px;
    margin-top: -81px;
    margin-left: 30px;
  }
}

.BeneTxn_AcountRightSec {
  padding-top: 15px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  gap: 1%;
  --tw-bg-opacity: 1;
  background-color: rgba(36, 66, 119, var(--tw-bg-opacity));
  width: 34%;
  height: auto;
}

.beneTxnDashNotFound {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  width: 60%;
  gap: 38px;
}

.beneTxnDashNotFound img {
  margin-top: 2rem;
  margin-bottom: -1rem;
  left: 289px;
  width: 285px;
  height: 210px;
}

.BeneTxn_BenGridLaySection {
  display: flex;
  flex-direction: row;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  margin-top: 20px;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 10px;
}

.box1 {
  width: 50%;
  padding: 10px 5px;
  gap: 65px;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  padding: 10px 0px;
  height: 100vh;
}

.box2 {
  width: 50%;
  padding: 10px 5px;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  height: 100vh;
}

.container1 {
  display: flex;
  margin-top: 30px;
  height: 100%;
  justify-content: space-between;
  border-radius: 40px;
  align-items: center;
  font-size: 18px;
  color: white;
  width: 100%;

  .mspan {
    font: normal normal normal 16px/20px Open Sans;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 11px;
  }
}

.container2 {
  padding-left: 1.5rem;
  display: flex;
  margin-top: 3%;
  height: 100%;
  justify-content: space-between;
  border-radius: 40px;
  align-items: center;
  font-size: 12px;
  color: white;
  width: 100%;

  span {
    font-size: 15px;
  }
}

.btn {
  width: 100%;
  height: 100%;
  border-radius: 40px;

  .btnp {
    display: flex;
    align-items: center;

    .btn1 {
      position: relative;
      left: -2%;
      width: 100%;
      height: 17px;
      margin-left: 20px;
      font: normal normal normal 13px/17px Open Sans;

      color: #f9f9f9;
    }

    img {
      width: 12px;
      height: 12px;
      margin-right: 17px;
    }
  }
}

.BeneAcountRightSec {
  display: flex;
  flex-direction: column;
  gap: 1%;

  --tw-bg-opacity: 1;
  background-color: rgba(36, 66, 119, var(--tw-bg-opacity));
  width: 34%;
  height: 81vh;
}

.createAc {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 3%;

  img {
    width: 25px;
    height: 25px;
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  .BeneTxn_CreateTxn {
    width: 141px;
    height: 22px;
    margin-top: 21px;
    margin-bottom: 22px;
    text-align: center;
    font: normal normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #ffffff !important;
    opacity: 1;
  }
}

.BeneTxn_QuickActionSec {
  width: 100%;
  height: 90%;
}

.BeneTxn_LeftBenfContent {
  width: 308px;
  background-color: #314c7f;
  height: 37px;
  padding: 15px 0px;
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  align-items: center;
  display: flex;
  justify-content: center;

  p {
    width: 74pxs;
    height: 17px;
    text-align: center;
    font: normal normal normal 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #c9c9c9;
    opacity: 1;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.BeneTxn_RightBenfContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  opacity: 0.4;
  margin-bottom: 20px;
}

.BeneTxn_leftSidecontent {
  display: flex;
  width: 268px;
  height: 25px;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  img {
    width: 25px;
    height: 25px;
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  p {
    width: 95px;
    height: 17px;
    text-align: left;
    font: normal normal normal 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

.BeneTxn_leftSidecPaymentAdvice {
  display: flex;
  width: 268px;
  height: 25px;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  img {
    width: 25px;
    height: 25px;
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  p {
    width: 125px;
    height: 17px;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal normal 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

.BeneTxn_LeftSideSendTxn {
  display: flex;
  width: 268px;
  height: 25px;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  img {
    width: 25px;
    height: 25px;
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  p {
    width: 195px;
    height: 17px;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal normal 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

.BeneTxn_leftSideMisReport {
  display: flex;
  width: 268px;
  height: 25px;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  img {
    width: 25px;
    height: 25px;
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  p {
    width: 91px;
    height: 17px;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal normal 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

.BeneTxn_RightSideContent {
  display: flex;
  justify-content: flex-end;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 4px 0px;
  background-color: rgba(2, 2, 19, 0.171);
  border-radius: 6px;
  width: 20px;
  height: 20px;

  img {
    width: 6px;
    height: 11px;
    color: white;
  }
}

.BeneTxnRightDivider {
  margin-left: 20px;
  margin-top: 10px;
  width: 268px;
  height: 0px;
  border: 1px solid #1d3a6d;
  opacity: 1;
}

.arrowfunc {
  display: flex;
  justify-content: center;
  background-color: #244277;
  width: 20%;
  height: 30%;
}

.BeneTxn-GridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  width: 655px;
  row-gap: 20px;
  column-gap: 20px;
}

.BeneTxn_GridItemContent {
  margin-right: -20px;
  max-width: 320px;
  max-height: 65px !important;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000033;
  border-radius: 20px;
  opacity: 1;
}

.BeneTxn_ItemContent {
  display: flex;
  justify-content: space-between;
  max-height: 65px !important;
  max-width: 320px;
  border-radius: 42px;
}

.BeneTxn_TxtLeftSection {
  display: flex;
  flex-direction: column;
  gap: 1px;
  justify-content: space-between;
  margin-right: 12px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  overflow: hidden;
}

.BeneTxn_PriceSection {
  margin-top: 10px;
  margin-left: 20px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
}

.leftPrice {
  height: 22px;
  text-align: left;
  letter-spacing: 0px;
  color: #fbc02d;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: lowercase;
}

.pendingColor {
  color: #31e5e5;
}

.rejectedColor {
  color: #ff5860;
}

.approvedColor {
  color: #b196fa;
}

.queuedColor {
  color: #fbc02d;
}

.settledColor {
  color: #4ddd37;
}

.abandonedColor {
  color: #b4b4b4;
}

.cancelledColor {
  color: #ff5860;
}

.deniedColor {
  color: #ff5860;
}

.lelfP {
  margin-left: 20px;
  margin-bottom: 10px;
  width: 187px;
  height: 22px;

  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: lowercase;
}

.pendingColor {
  color: #31e5e5;
}

.deniedColor {
  color: #ff5860;
}

.approvedColor {
  color: #4ddd37;
}

.archivedColor {
  color: #b4b4b4;
}

.blockColor {
  color: #ff5860;
}

.lelfP::first-line {
  text-transform: capitalize !important;
}

.BeneTxn_ImageRightSection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 82px;
  height: 65px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000033;
  opacity: 1;
  border-radius: 20px;
  right: 1px;

  .BeneTxn_CategoriesSection {
    width: 25px;
    height: 19px;

    img {
      width: 45px;
      height: 25px;
      opacity: 1;
      margin-top: 3px;
    }
  }

  .BeneTxn_DividerBtwnCatnBank {
    width: 64px;
    height: 0px;
    border: 1px solid #1e3a6d;
    opacity: 1;
  }

  .BeneTxn_BankImagesSection {
    img {
      width: 50px;
      height: 15px;
      box-shadow: 0px 3px 6px #00000029;
      opacity: 1;
      margin-left: 18px;
      margin-bottom: 9px;
      margin-right: 14px;
    }
  }
}

.BeneTxn_RecentPage {
  display: flex;
  max-width: 654px;
  justify-content: space-between;
  height: 42px;
  background: #234176 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000033;
  border-radius: 21px;
  opacity: 1;
  margin-right: -20px;
}

.BeneTxn_RecentText {
  width: 51px;
  height: 22px;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.BeneTxn_ViewallPage {
  width: 103px;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000033;
  border: 1.6px solid #ff5860;
  border-radius: 21px;
  opacity: 1;
  cursor: pointer;
}

.BeneTxn_ViewallPage p {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.BeneTxn_ViewallPage img {
  width: 12px;
  height: 14px;
  color: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.BeneTxn_Dashboard_mobile_header {
  display: none;
}

.BeneTxn-mobile-create-tran {
  display: none;
}

.BeneTxn_NavMenuMob {
  display: none;
}

@media screen and (max-width: 1024px) {
  .BeneTxn_DashBoardMainParent {
    padding-bottom: 105px;
    height: 100%;
  }
  .BeneTxn_Dashboard_mobile_header {
    display: block;
    padding: 10px 0;
    width: 100%;
    height: 60px;
    background: #3a5686 0% 0% no-repeat padding-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .TxnMobHeaderCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bt-header-left-mobile {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .bt-header-left-mobile img {
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }

  .bt-header-left-mContent {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .bt-header-left-mContent .beneText-dashboard-mob {
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: left;
    color: #f9f9f9;
  }

  .bt-header-left-mContent .beneTransText-dashboard-mob {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    text-align: left;
    color: #dadada;
  }

  .bt-header-right-mobile {
    padding-right: 20px;
  }

  .bt-header-right-mobile img {
    width: 40px;
    height: 40px;
  }

  .BeneTxn-mobile-create-tran {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    padding: 9px 0;
    background: #506994 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 11px #00000033;
    width: 100%;
    margin-top: 60px;
  }

  .BeneTxn-mobile-create-tran img {
    height: 40px;
    width: 40px;
  }

  .BeneTxn-mobile-create-tran .BeneTxn_mob_tranText {
    /* font-size: 12px; */
    font-weight: 400;
    line-height: 16.34px;
    text-align: center;
    color: #f9f9f9;
  }

  .BeneTxn_NavMenu {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: auto;
    padding: 20px;
    background: #1d3a6d 0% 0% no-repeat padding-box;
    /* height: 287px; */
    /* padding-bottom: 0; */
  }

  .BeneTxn_NavMenu .BeneTxn_GridLayout {
    margin-top: 0;
    width: 100%;
    overflow-y: hidden;
    padding-bottom: 5px;
    margin-bottom: 0;
    /* justify-content: center; */
    /* height: 100%; */
    /* height: auto; */
    /* height: 238px; */
  }

  .BeneTxn_GridLayout .transition-effect {
    flex-wrap: wrap;
    gap: 10px;
  }

  .BeneTxn_NavMenu .BeneTxn_CaraouselBar {
    width: 100%;
    height: 10px;
    margin: 0 7px;
    position: static;
  }

  .BeneTxn_AllSection {
    width: 100%;
    background: #1d3a6d 0% 0% no-repeat padding-box;
    padding: 0 20px;
    height: 100%;
  }

  .BeneTxn_TxnSection {
    width: 100%;
    background: #1d3a6d 0% 0% no-repeat padding-box;
    flex-direction: column;
    gap: 20px;
  }

  .BeneTxn_ContentPage {
    width: 100%;
    height: auto;
    padding: 0;
    position: relative;
  }

  .BeneTxn_RecentPage {
    max-width: 100%;
    height: 36px;
    border-radius: 20px;
    margin-right: 0;
  }

  .BeneTxn_RecentText {
    margin-top: 8.5px;
    margin-bottom: 8.5px;
    width: 47px;
    height: 19px;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left;
  }

  .BeneTxn_ViewallPage {
    width: 94px;
    height: 36px;
  }

  .BeneTxn_ViewallPage p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
    color: #f9f9f9;
  }

  .BeneTxn_ViewallPage img {
    height: 10px;
    width: 10px;
  }

  .BeneTxn_BenGridLaySection {
    margin-top: 20px;
  }

  .BeneTxn-GridContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }

  .BeneTxn_GridItemContent {
    max-width: 50%;
    width: 49%;
    margin-right: 0;
    max-height: 70px !important;
  }

  .BeneTxn_ItemContent {
    max-width: 100%;
    border-radius: 10px;
    max-height: 70px !important;
  }

  .BeneTxn_TxtLeftSection {
    margin-right: 10px;
    gap: 4px;
  }

  .BeneTxn_PriceSection p {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left;
  }

  .lelfP {
    width: 222px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: left;
  }

  .BeneTxn_AcountRightSec {
    width: 100%;
    display: block;
    /* padding-top: 20px; */
    background: none;
    padding-top: 0;
  }

  .bene-box-mobile-hide {
    display: none;
  }

  .BeneTxn_AcountRightSec .bene-box-mobile {
    margin: 0;
    width: 100%;
    /* margin-bottom: 111px; */
    /* margin-bottom: 535px; */
  }

  .BeneTxn_QuickActionSec {
    width: 100%;
  }

  .BeneTxn_LeftBenfContent {
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 10px 0;
  }

  .BeneTxn_LeftBenfContent p {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    width: 79px;
    color: #dadada;
  }

  .BeneTxn_leftSidecontent p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: #f9f9f9;
  }

  .BeneTxnRightDivider {
    width: 95%;
  }
  .beneTxngridlayout {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin: 0;
  }

  .beneTxnDashNotFound {
    width: 100%;
    padding-top: 20px;
    gap: 24px;
  }

  .beneTxnDashNotFound img {
    margin-top: 0;
    margin-bottom: 0;
  }

  .beneTxnDashNotFound p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: center;
  }

  .expentraloaderwithDashBoard {
    width: 100% !important;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .expentraloaderwithDashBoard img {
    position: absolute;
    top: 220%;
    left: 43%;
  }
}

@media screen and (max-width: 900px) {
  .BeneTxn_NavMenu .BeneTxn_GridLayout {
    height: 245px;
  }
}

@media screen and (max-width: 600px) {
  .BeneTxn_NavMenu .BeneTxn_GridLayout {
    margin-top: 0;
    width: 100%;
    overflow-y: hidden;
    padding-bottom: 5px;
    margin-bottom: 0;
    height: auto;
  }

  .BeneTxn_GridLayout .transition-effect .datacard {
    background: #3a5686 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 11px #00000033;
    width: 117px;
    height: 114px;
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-bottom: 10px;
    padding-top: 20px;
    margin: 0;
  }

  .BeneTxn_GridLayout .transition-effect .datacard .colorpage {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 24px;
    margin-left: 0;
    border-radius: 0 20px;
  }

  .BeneTxn_GridLayout .transition-effect .datacard .cardpage {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-top: 0;
    margin-left: 0;
    position: static;
    /* height: 76px; */
  }

  .BeneTxn_GridLayout .transition-effect .datacard .cardpage .word1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    width: 42px;
    height: 40px;
  }

  .BeneTxn_GridLayout .transition-effect .datacard .cardpage div:nth-child(2) {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16.34px !important;
    text-align: left !important;
    width: 80px !important;
    height: 32px !important;
  }

  .BeneTxn_NavMenu .BeneTxn_CaraouselBar {
    margin: 0;
    /* display: block; */
  }

  .BeneTxn_CarouselDotbar {
    margin: 0 auto;
  }

  /* .BeneTxn_NextAndPrevIcon {
    display: none;
  } */

  .BeneTxn_GridItemContent {
    width: 100%;
    max-width: 100%;
  }

  .BeneTxnRightDivider {
    width: 90%;
  }

  .expentraloaderwithDashBoard {
    width: 100% !important;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .expentraloaderwithDashBoard img {
    position: absolute;
    top: 220%;
    left: 35%;
  }

  .BeneTxn_NavMenu {
    display: none;
  }
  .BeneTxn_NavMenuMob {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: auto;
    padding: 18px 0;
    background: #1d3a6d 0% 0% no-repeat padding-box;
    /* /* height: 287px; */
  }

  .BeneTxn_NavMenuMob .BeneTxn_GridLayoutMob {
    margin-top: 0;
    max-width: 100%;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    height: 255px;
    /* height: auto; */
  }

  .BeneTxn_NavMenuMob .BeneTxn_GridLayoutMob .transition-effect {
    flex-wrap: wrap;
    overflow: hidden;
    height: 100%;
    margin-left: 8px;
    gap: 15px 0;
  }

  .BeneTxn_CaraouselBar {
    width: 100%;
    top: 3px;
    margin-top: 0;
    padding: 0 20px;
  }

  .BeneTxn_NavMenuMob .owner-datacard {
    width: 110px;
    height: 119px;
    border-radius: 20px;
    display: flex;
    gap: 4px;
    flex-direction: column;
  }

  .BeneTxn_NavMenuMob .colorpage {
    height: 24px;
    width: 36px;
    margin-left: 74px;
    border-radius: 0px 20px;
  }

  .BeneTxn_NavMenuMob .cardpage {
    padding-top: 0 !important;
    width: 110px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-left: 0;
    position: static;
    transform: translateY(-12px);
  }

  .BeneTxn_NavMenuMob .cardpage .word1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    width: 42px;
    height: 40px;
  }

    .BeneTxn_AllSection{
      padding: 0 15px;
    }
}

@media screen and (max-width: 400px) {
  .BeneTxn_NavMenuMob .owner-datacard {
    width: 100px;
  }

  .BeneTxn_NavMenuMob .colorpage {
    margin-left: 64px;
  }

  .BeneTxn_NavMenuMob .cardpage {
    width: 100px;
  }
}
