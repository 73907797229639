.device-card-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.deviceCard {
  display: flex;
  flex-wrap: wrap;
  width: 90% !important;
  margin: 0 auto;
  padding-bottom: 20px;
  margin-top: 45px;
  background-color: #1d3a6d;
}

.devices-middle_section_container {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start;
  width: 89%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 10px;
  background-color: #1d3a6d;
  position: fixed;
  z-index: 4;
  overflow: hidden;
  height: 40px;
  /* background-color: red; */
}

.devices-middle_section_container .devices-search-div {
  width: 30px;
  height: 30px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.devices-middle_section_container .devices-search-div img {
  width: 14px;
  height: 14px;
}

.multiSelectDeviceCard {
  width: 252px;
  height: 115px !important;
  border-radius: 20px !important;
  grid-column: span 1;
  background-color: #3a5686;
  box-sizing: border-box;
  padding: 10px !important;
  margin: 10px;
  padding-right: 1px !important;
  cursor: pointer;
  position: relative;
}

.multiSelectDeviceCard:hover {
  background-color: #63799f;
}

.browser-logo-div {
  background: #fafafa !important;
  box-shadow: -5px -5px 11px #71717133 !important;
  opacity: 1;
  width: 70px;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px !important;
  cursor: pointer;
  border-radius: 20px 0px;
}

.browser-logo-div img {
  width: 22px;
  height: 30px;
  opacity: 1;
}

@media screen and (min-width: 1900px) {
  .multiSelectDeviceCard {
    width: 266px;
  }
}
@media screen and (min-width: 1500px) and (max-width: 1800px) {
  .multiSelectDeviceCard {
    width: 254px;
  }
}

.device-viewall-date-div {
  color: #b0bbce;
  font-size: 10px;
  margin-bottom: 20px;
}

.device_viewall_name_div {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #b196fa;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* overflow: hidden; */
  text-transform: lowercase !important;
}

.device_viewall_name_div::first-line {
  text-transform: capitalize !important;
}

.device-view-all-user {
  white-space: nowrap;
  width: 13rem;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.device_viewall-bank-acc-div {
  color: #fafafa;
  font-size: 12px;
  margin-bottom: 4px;
}

.device_viewall_box-status {
  width: 5px;
  height: 15px;
  background-color: #4ddd37;
  border-radius: 0px 5px 0px 0px;
}

.multiSelectDeviceCard:hover .device_viewall_assigned {
  display: block;
}

.device_viewall_assigned {
  display: none;
  width: 60px;
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
}

.device_viewall_label-div {
  display: flex;
}

.device-view-all-card-container {
  margin-right: 0px !important;
  width: 98px !important;
  height: 40px !important;
}

.device-card-top-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 5px;
}

.device-card-top-div > div > h6 {
  text-align: left;
  font: normal normal 600 14px/30px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.device-card-top-div > div > p {
  text-align: left;
  font: normal normal 600 9px/19px Open Sans;
  letter-spacing: 0px;
  color: #b0bbce;
  opacity: 1;
  margin-top: -5px;
}

.device-card-bottom-div {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 0 5px;
}

.device-card-bottom-div > div > h6 {
  text-align: left;
  font: normal normal 600 14px/30px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.device-card-bottom-div > div > p {
  font: normal normal 600 9px/19px Open Sans;
  letter-spacing: 0px;
  color: #b0bbce;
  opacity: 1;
  margin-top: -5px;
}
