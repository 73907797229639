.mainBack {
  background-color: black;
  opacity: 0.8;
}

.UpdateButton {
  margin-left: 22px;
  width: 75px;
  height: 35px;
  padding-top: 7px;
  border-radius: 75px;
  text-align: center;
  color: #f9f9f9;
  cursor: pointer;
  font-size: 12px;
  background-color: #69b76f;
}

.UpdateButton_responsive {
  /* margin-left: 43px; */
  width: 94px;
  height: 42px;
  padding-top: 9px;
  border-radius: 20px;
  text-align: center;
  color: #f9f9f9;
  cursor: pointer;
  background-color: #69b76f;
}

.successImg img {
  background-color: #102c5d;
  height: 30px;
  padding-left: 45px;
}

.successImg_responsive img {
  background-color: #1d3a6d;
  height: 40px;
  padding-left: 14px;
}

.failImg img {
  background-color: #102c5d;
  height: 30px;
  padding-left: 45px;
}

.failImg_responsive {
  background-color: #1d3a6d;
  height: 40px;
  padding-left: 10px;
}

.failImg_responsive img {
  width: 40px;
  height: 40px;
}
.loadingImg img {
  background-color: #102c5d;
  height: 30px;
  padding-left: 60px;
}

.loadingImg_responsive img {
  background-color: #1d3a6d;
  height: 40px;
  padding-left: 10px;
}

.defaultButton {
  margin-left: 22px;
  width: 75px;
  height: 35px;
  border: 1px solid #f9f9f9;
  padding-top: 7px;
  border-radius: 75px;
  text-align: center;
  color: #f9f9f9;
  font-size: 12px;
}

.defaultButton_responsive {
  width: 94px;
  height: 42px;
  border: 1px solid #f9f9f9;
  padding-top: 9px;
  border-radius: 20px;
  text-align: center;
  color: #f9f9f9;
}
