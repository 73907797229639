.InternalTxn_Stepper_Main {
    width: 1024px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stepperline_InternalTxn {
    width: 772px;
    height: 59.85px;
    padding: 0px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.InternalTxn_stepinnerline {
    height: fit-content;
    width: fit-content;
}