.beneCardEdit {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  width: 90%;
  margin-top: 10px;
}

.multiSelectBeneCard {
  width: 100%;
  height: 101px;
  border-radius: 10px !important;
  box-shadow: -5px -5px 11px #71717133 !important;
  grid-column: span 1;
  box-sizing: border-box;
  cursor: pointer;
  padding-top: 10px;
}

@media (min-width: 801px) {
  .multiSelectBeneCard {
    width: 255px !important;
  }
  .wrap_top_acc_view {
    width: 255px !important;
  }
}

.bene_txn_viewall_hover_content_acc {
  display: none;
  border-top: 1px solid #1d3a6d;
  height: 54px;
  transition: all 0.2s ease 0.2s;
}

.multiSelectBeneCard:hover .bene_txn_viewall_hover_content_acc {
  display: block;
  /* margin-top: 10px;*/
  /*Removed Attesham code*/
}

.multiSelectBeneCard:hover {
  position: absolute;
  border: 1px solid #ff5860;
  height: 155px;
  transition: height 0.2s;
  z-index: 2;
  background-color: #506994 !important;
}

.viewall-date-div {
  color: #b0bbce;
  font-size: 10px;
  margin-bottom: 20px;
}

.bene_viewall_name_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #b196fa;
  padding: 0px 10px 10px 10px;
}

.bene_viewall_payee {
  width: 20px;
  height: 20px;
}

.bene_viewall_name_div::first-line {
  text-transform: capitalize !important;
}

.bene-view-all-user {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bene_viewall-bank-acc-div {
  color: #fafafa;
  font-size: 12px;
  margin-bottom: 5px;
  overflow: hidden;
  /* width: 139px; */
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bene_viewall_box-status {
  width: 5px;
  height: 15px;
  background-color: #4ddd37;
  border-radius: 0px 5px 0px 0px;
  top: 0px;
  position: absolute;
  right: 0px;
}

/* .viewall-details:hover .bene_viewall_assigned {
  display: block;
} */
.viewall-details:hover .bene_viewall_box-status {
  transform: translateX(calc(100% -100%));
  right: auto;
  left: 0px;
}

.beneCard.sidebar-open .multiSelectBeneCard:hover .bene_viewall_box-status {
  transform: none !important;
  right: 0px !important;
  left: auto !important;
}

.bene_viewall_assigned {
  display: none;
  /* width: 60px; */
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  padding-left: 10px;
  padding-right: 10px;
}

.beneCard.sidebar-open .multiSelectBeneCard:hover .bene_viewall_assigned {
  display: none;
}

.bene_viewall_label-div {
  display: flex;
  height: 20px;
  right: 0px;
  position: absolute;
  bottom: 65px;
}

.css-wdslgk {
  padding-right: 0 !important;
}

@keyframes slideOutRightToLeft {
  0% {
    visibility: visible;
    transform: translateX(0);
  }

  100% {
    visibility: hidden;
    transform: translateX(-10%);
  }
}

/* .viewall-details:hover .bene_viewall_name_div {
  visibility: hidden;
  animation: slideOutRightToLeft 0.2s ease-in-out forwards;
  transition: transform 0.3s ease-in-out;
} */

/* .viewall-amount-div {
  visibility: visible;
} */

.view-all-card-container_acc {
  width: 65px !important;
  height: 26px !important;
  top: 60px;
}

.bene_viewall_assigned-pending-box,
.bene_viewall_assigned-approved-box,
.bene_viewall_assigned-blocked-box,
.bene_viewall_assigned-archived-box,
.bene_viewall_assigned-denied-box ::first-letter {
  text-transform: capitalize;
}

.bene_viewall_assigned-pending-box {
  background-color: rgba(49, 229, 229, 0.2);
  color: rgba(49, 229, 229, 1);
  font-size: 9px;
  border-radius: 0px 3px 0px 0px;
}

.bene_viewall_assigned-approved-box {
  background-color: rgba(77, 221, 55, 0.2);
  color: rgba(77, 221, 55, 1);
  font-size: 9px;
  border-radius: 0px 3px 0px 0px;
}

.bene_viewall_assigned-denied-box,
.bene_viewall_assigned-blocked-box {
  background-color: rgba(255, 88, 96, 0.2);
  color: rgba(255, 88, 96, 1);
  border-radius: 0px 3px 0px 0px;
  font-size: 9px;
  text-transform: capitalize;
}

.bene_viewall_assigned-archived-box {
  background-color: rgba(207, 207, 207, 0.2);
  font-size: 9px;
  border-radius: 0px 3px 0px 0px;
  color: rgba(207, 207, 207, 1);
}

.beneCard.sidebar-open .multiSelectBeneCard:hover .bene_viewall_name_div {
  visibility: visible;
  animation: none;
}

.wrap_top_acc_view {
  width: 100%;
  height: 101px;
  position: relative;

  /* margin: 10px 9.5px; */
}

/* @media (min-width: 801px) {
  .wrap_top_acc_view {
    width: 255px;
  }
} */
@media screen and (min-width: 300px) and (max-width: 480px) {
  /* .beneCardEdit {
    padding-bottom: 0px !important;
  } */
  /* .bene-view-all-user {
    width: 14rem !important;
  } */
}
@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .beneCardEdit {
    width: 96%;
  }

  .multiSelectBeneCard:hover,
  .multiSelectBeneCard,
  .wrap_top_acc_view {
    width: 240px !important;
  }
}
@media screen and (min-width: 1400px) and (min-height: 960px) {
  .multiSelectBeneCard {
    width: 250px !important;
  }
  .wrap_top_acc_view {
    width: 250px !important;
  }
  .beneCardEdit {
    width: 95% !important;
  }
}
@media screen and (min-width: 2560px) and (min-height: 1440px) {
  .beneCardEdit {
    width: 95% !important;
  }
  .multiSelectBeneCard,
  .wrap_top_acc_view {
    width: 260px !important;
  }
}
@media screen and (min-width: 3800px) and (min-height: 1920px) {
  .beneCardEdit {
    width: 85% !important;
  }
  .multiSelectBeneCard,
  .wrap_top_acc_view {
    width: 260px !important;
  }
}

@media screen and (max-width: 800px) {
  .beneCardEdit {
    margin: 10px auto 0;
  }
}
