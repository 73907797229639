html,
body,
#root {
  height: 100%;
  margin: 0;
  height: 100%;
  margin: 0;
}

.user-main-div {
  display: flex;
  flex-direction: column;
  background-color: #3a5686;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: #3a5686;
  height: 100vh;
  overflow: auto;
}

.user-main-div::-webkit-scrollbar {
  width: 7px;
  width: 7px;
}

.user-main-div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.user-main-div::-webkit-scrollbar-thumb {
  background-color: #fafafa;
  outline: 1px solid #314d7e;
  border-radius: 25px;
  height: 10px;
  background-color: #fafafa;
  outline: 1px solid #314d7e;
  border-radius: 25px;
  height: 10px;
}

.user-main-div::-webkit-scrollbar-thumb:hover {
  background-color: #fafafa;
  background-color: #fafafa;
}

.user-div-fixed {
  height: auto;
  background-color: #3a5686;
  margin-top: 76px;
  box-shadow: 5px 5px 11px #00000026;
  height: auto;
  background-color: #3a5686;
  margin-top: 76px;
  box-shadow: 5px 5px 11px #00000026;
}

.user-div-auto {
  flex-grow: 1;
  background-color: #1d3a6d;
  width: 100%;
}

.user-container {
  width: 100%;
  background-color: #3a5686;
  height: 30%;
  min-height: 15%;
  margin: auto;
}

.user-acc-section {
  height: 30%;
  background-color: #3a5686;
  max-width: 1024px;
  margin: auto;
  display: flex;
}

.user-detail-section {
  height: auto;
  background-color: #1d3a6d;
  max-width: 1024px;
  margin-top: 92px;
  margin: auto;
}

.user-multi-button {
  display: flex;
  padding-top: 30px;
  margin-bottom: 25px;
}

.user-detail-button {
  width: 80px;
  height: 30px;
  background-color: #373f4b;
  border-radius: 18px;
  margin-right: 20px;
  color: #f9f9f9;
  font-size: 0.75rem;
  text-align: center;
  font-style: Open, Sans;
  font-weight: 600;
  text-align: center;
}

.user-detail-button.active {
  background-color: #f9f9f9;
  color: #373f4b;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.user-bank-detail {
  height: 90px;
  display: flex;
}

.user-account-holder-details {
  border-bottom: 2px solid #1d3a6d;
  height: 90px;
  display: flex;
}

.user-contact-detail-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  height: 180px;
  background-color: #314d7e;
  border-radius: 1.5rem;
  box-shadow: -5px -5px 11px #71717133;
  padding: 20px;
}

.user-account-name {
  text-transform: lowercase !important;
}

.user-account-name::first-line {
  text-transform: capitalize !important;
}

.user-service-card-row {
  display: flex;
  justify-content: left;
  flex-direction: row;
  margin: 35px 0px;
  gap: 20px;
}

.user-major-div {
  width: 800px;
  margin-top: 29px;
}

.user-minor-div {
  width: 224px;
  margin-top: 29px;
}

.user-individual-person-detail {
  margin-top: 6.5px;
  display: flex;
  border-bottom: 2px solid #1d3a6d;
}

.user-acc-content {
  justify-content: center;
  display: flex;
  align-items: center;
}

.user-recipient {
  height: 90px;
  border-bottom: 2px solid #1d3a6d;
  display: flex;
}

.user-recipient-noborder {
  height: 90px;
  border-bottom: 2px solid #506994;
  display: flex;
}

.user-individual-name {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 1px;

  color: #ffffff;
  font-size: 1rem;
  opacity: 1;
  font-weight: 600;
}

.user-account-name {
  color: #ffffff;
  font-size: 1rem;
  opacity: 1;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: lowercase !important;
}

.user-account-name::first-line {
  text-transform: capitalize;
}

.user-bank-code {
  padding: 20px;
  width: 50%;
}

.user-acc-holder-name {
  padding: 20px;
  width: 50%;
}

.user-acc-name-label {
  color: #dadada;
  opacity: 1;
  font-size: 0.75rem;
}

.user-acc-detail {
  padding: 20px;
  width: 50%;
}

.user-acc-content {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  border-left: 2px solid #1d3a6d;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}

.user-acc-number-label {
  color: #dadada;
  opacity: 1;
  font-size: 0.75rem;
}

.user-recipient-img {
  width: 35px;
  height: 35px;
}

.user-account-status-div {
  font-size: 0.875rem;
  opacity: 1;
  font-weight: 600;
  text-transform: capitalize;
}

.user-inactive-status {
  color: #30e5e4;
}

.user-active-status {
  color: #4ddd37;
}

@media (max-width: 768px) {
  .user-acc-section {
    flex-direction: column;
  }

  .user-individual-person-detail,
  .user-bank-detail {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .user-detail-section {
    display: flex;
    flex-direction: column;
  }

  .user-service-card-row {
    margin-top: 20px;
  }

  .user-container {
    margin: auto;
  }
}

.crt-by-text {
  text-transform: capitalize !important;
}

.splitline-div {
  width: 0px;
  height: 28px;
  border: 1px solid #b4b4b4;
  opacity: 1;
}

.features-div,
.services-div,
.roles-div {
  margin-bottom: 20px;
}

.roles-div {
  margin: 35px 0px;
}

.roles-tab-div,
.features-tab-div,
.services-tab-div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.services-tab-header,
.features-tab-header {
  top: 479px;
  left: 170px;
  width: 1024px;
  height: 42px;
  /* UI Properties */
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
  margin: 30px 0px 20px 0px;
}

.features-tab-header:nth-child(1),
.services-tab-header:nth-child(1) {
  margin-top: 35px;
}

.features-tab-header h5,
.services-tab-header h5 {
  top: 489px;
  left: 190px;
  width: 71px;
  height: 22px;
  color: var(---f9f9f9);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  width: 100%;
  white-space: initial;
}
.services-tab-card,
.features-tab-card,
.roles-tab-card {
  /* top: 541px;
  left: 170px; */
  width: 241px;
  height: 68px;
  /* UI Properties */
  background: #314d7e 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  border-radius: 15px;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 20px;
}

.email-id-div {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 5px;
}
.user-title {
  font-size: 0.8rem;
  font-weight: 600;
  color: #dadada;
  padding-bottom: 10px;
}

.user-phone {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: #c9c9c9;
  padding-bottom: 10px;
  border-bottom: 2px solid #1d3a6d;
  width: 100%;
}

.user-email-section {
  margin-top: 10px;
}

.user-email-id-div {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #f9f9f9;
}

.user-email {
  font-size: 0.8rem;
  color: #c9c9c9;
}

.not-found-container {
  width: 300px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  margin: auto;
}

.not-found-container p {
  padding-left: 33px;
}
