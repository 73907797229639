.internalTxnDetailsPage {
  width: 100%;
  background-color: #3a5686;
  height: auto;
}

.internaltxn-account-primary-details {
  height: 30%;
  background-color: #3a5686;
  width: 100%;
  display: flex;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;
}

.url-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.progress-require-approval::-webkit-progress-bar {
  background-color: #102c5d;
  border-radius: 15px;
}

.progress-require-approval::-webkit-progress-value {
  background-color: #69b76f;
  border-radius: 15px;
}

.progress-rejected-approval::-webkit-progress-bar {
  background-color: #102c5d;
  border-radius: 15px;
}

.progress-rejected-approval::-webkit-progress-value {
  background-color: #ff5860;
  border-radius: 15px;
}

.progress-abandoned-transaction::-webkit-progress-bar {
  background-color: #102c5d;
  border-radius: 15px;
}

.progress-abandoned-transaction::-webkit-progress-value {
  background-color: #b4b4b4;
  border-radius: 15px;
}
