.beneContact_Main {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.dividecontact {
  width: 472px;
  max-height: 400vh;
  margin: 0px 20px;
}
.cateTxnParent {
  height: 58px;
  margin-left: -16px;
  border-radius: 15px 0px 0px 15px;
}

.cateTxnImage {
  margin: 10px auto;
}

.custom-scroll-container::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

.custom-scroll-container::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 8px;
}

.custom-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #dadada;
  border-radius: 8px;
}

.label-container {
  color: #dadada;
}

.categoryName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
@media screen and (max-width: 800px) {
  .beneContact_Main {
    flex-direction: column !important;
    justify-content: flex-start !important;
    gap: 25px;
    padding-top: 20px !important;
  }

  .dividecontact {
    padding: 0 20px;
    margin: 0 !important;
  }
}

@media screen and (max-width: 400px) {
  .cateTxnImage {
    width: 1.25rem !important;
  }

  .categoryName {
    max-width: 170px !important;
  }
}
