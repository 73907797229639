.tag-search {
  width: 500px;
  height: 34px;
  background: #314d7e 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 2px !important;
  justify-content: center;
  margin: 4px !important;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tag-partition-one {
  flex: 65%;
}

.tag-partition-two {
  display: flex;
  flex-direction: row;
  padding-inline: 10px;
}

.tag-partition-one-css {
  width: 182px;
  height: 22px;
  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.input-custom-search {
  background: #314d7e 0% 0% no-repeat padding-box;
  width: 100%;
  box-sizing: border-box;
  color: #ffffff;
  font: normal normal normal 14px / 19px "Open Sans", sans-serif;
  outline: none;
}

.tag-down-search {
  width: 15px;
  height: 15px;
}

.btn-custom-primary {
  width: 89px !important;
  height: 24px !important;
  background: #707070 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  text-align: center !important;
  font: normal normal 400 10px/19px Open Sans !important;
  letter-spacing: 0px !important;
  color: #f9f9f9 !important;
  text-transform: none !important;
  margin: 3px 5px 5px 5px !important;
  padding: 9px !important;
}

.btn-custom-secondary {
  width: 89px !important;
  height: 24px !important;
  background: #667ca3 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  text-align: center !important;
  font: normal normal 400 10px/19px Open Sans !important;
  letter-spacing: 0px !important;
  color: #f9f9f9 !important;
  text-transform: none !important;
  margin: 3px 5px 5px 5px !important;
  padding: 9px !important;
}

.tag-list {
  display: flex;
  flex-direction: column;
}

.existing-tag {
  display: flex;
  flex-direction: row;
}

.existing-tag > p {
  font: normal normal normal 400 12px/19px Open Sans;

  color: #dadada;
}

.tag-list > p {
  font: normal normal normal 400 14px/19px Open Sans;

  color: #dadada;
}

.tag-box-item {
  display: flex;
  flex-direction: row;
  border-radius: 20px !important;
  background: #3a5686;
  box-sizing: border-box;
  grid-column: span 1;

  margin: 10px 10px 10px 0;
  padding: 10px !important;
  width: fit-content;
  cursor: pointer;
}

.Assign-box-item {
  display: flex;
  flex-direction: row;
  border-radius: 20px !important;
  justify-content: center;
  background: #3a5686;
  box-sizing: border-box;
  grid-column: span 1;
  margin: 10px 10px 13px 0px;
  height: 30px !important;
  width: fit-content;
  cursor: default;
  color: #f9f9f9;
}

.tag-box-item > div {
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row;
  font: normal normal normal 400 12px/17px Open Sans;

  color: #ffffff;
}

.tag-bottom {
  position: absolute;
  height: 80px;

  z-index: 6;

  width: 562px;

  bottom: 0;
  background: #244277;
}

.tag-bottom-expand {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  max-height: 470px !important;
  padding: 10px 20px;
  z-index: 6;

  margin-bottom: 80px;
  width: 562px;
  border-bottom: 2px solid #102c5d !important;
  bottom: 0;
  background: #244277;
  border-radius: 16px 16px 0px 0px;
}

.tag-bottom-nodata {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  height: auto !important;

  z-index: 6;
  padding: 10px;
  margin-bottom: 80px;
  width: 560px;
  border-bottom: 2px solid #102c5d !important;
  bottom: 0;
  background: #244277;
  border-radius: 16px 16px 0px 0px;
}

.show-btn {
  margin: 5px 0 0 110px;
}

.main-btn {
  display: flex;
  margin: 0px 0 0 0px;
  justify-content: center;
  align-items: center;
}

.show_overbtn {
  width: 70px;
  height: 30px;
  text-align: center !important;
  letter-spacing: 0px !important;
  color: #f9f9f9 !important;
  background-color: #102c5d !important;
  font: normal normal normal 400 12px/19px Open Sans !important;
  border-radius: 2rem !important;
  text-transform: none !important;
  margin-right: 1rem !important;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px !important;
  padding: 12px !important;
}

.cancel_overbtn {
  width: 66px;
  height: 26px;
  text-align: center !important;
  letter-spacing: 0px !important;
  color: #f9f9f9 !important;
  text-decoration: underline !important;
  margin-top: 3px;
  font: normal normal normal 400 12px/19px Open Sans !important;
  border-radius: 2rem !important;
  text-transform: none !important;
  margin-right: 1rem !important;
  cursor: pointer;
}

.confirm_overbtn {
  width: 66px;
  height: 26px;
  padding: 3px;

  text-align: center !important;
  letter-spacing: 0px !important;
  color: #f9f9f9 !important;

  font: normal normal normal 400 12px/19px Open Sans !important;
  border-radius: 2rem !important;
  text-transform: none !important;
  margin-right: 1rem !important;
}

.tagup_icon {
  width: 12px !important;
  height: 12px !important;
  margin-left: 2px !important;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 10px !important;
}

@media screen and (max-width: 800px) {
  .main-btn {
    background-color: #294d8d;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 8px;
    gap: 20px;
  }
  .cancel_overbtn {
    border: 1px solid #f9f9f9;
    text-decoration: unset !important;
  }
}
