.beneBank_Main {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dividebank {
  width: 472px;
  margin: 0px 20px;
}

.bankright {
  width: 100%;
}

.bankLeft {
  width: 100%;
}

.bank_Details {
  width: 472px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;
  padding-bottom: 12px;
}

.dividepan {
  width: 472px;
  margin: 0px 20px;
}

.bank_ass_top {
  width: 100% !important;
  height: 345px;
  color: white;
}

.bank-no-assacc-found {
  width: 472px;
  height: 105px;
  color: white;
}

.bank_achived_main-new {
  width: 100%;
}
.bank_hidden_accounts {
  margin-left: 30px;
  margin-top: 20px;
}
.bank_hidden_accounts > p {
  width: 375px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
}

.bank_hidden_accounts p > span {
  width: 259px;
  height: 19px;
  text-align: left;
  font: normal normal 600 14px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
}

.bank_scroll_All_approval {
  height: 250px;
  overflow-y: scroll;
}

.bene_achived_scroll {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 28px;
}

.bene_child_scroll-new {
  width: 412px !important;
  height: 68px !important;
  background: #1d3a6d 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  display: flex !important;
  justify-content: flex-start !important;
  padding: 0px !important;
  margin: 0px !important;
  position: relative !important;
  align-items: flex-start !important;
  margin-bottom: 10px !important;
  text-transform: none !important;
}

.bene_child_scroll-new-responsive {
  width: auto !important;
  height: 78px !important;
  background: #1d3a6d 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  display: flex !important;
  justify-content: flex-start !important;
  padding: 0px !important;
  margin: 0px !important;
  position: relative !important;
  align-items: flex-start !important;
  margin-bottom: 10px !important;
  text-transform: none !important;
}

.bank_client_name {
  padding-left: 19px;
  padding-right: 65px;
  height: fit-content;
}

.bank_client_name-responsive {
  padding-left: 19px;
  padding-right: 10px;
  height: fit-content;
}

.bank_name_client2-new {
  width: 253px;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  color: #f9f9f9;
  text-align: left;
  margin-top: 14px;
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: normal normal 600 16px/15px Open Sans;
}

.bene_bank_client2-new {
  width: 112px;
  height: 17px;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: #f9f9f9;
  opacity: 1;
  width: 100%;
  font: normal normal normal 12px/12px Open Sans;
}

.bene_bank_no-new {
  width: 131px;
  height: 17px;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: #f9f9f9;
  opacity: 1;
  margin-left: 4px;
  font: normal normal normal 12px/12px Open Sans;
}

.bank_achived_main-new > p {
  margin-left: 30px;
  width: 102px;
  height: 17px;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: #f9f9f9;
  opacity: 1;
  margin-bottom: 4px;
}

.bene_new_message_text_crt {
  /* width: 472px; */
  /* height: 116px; */
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
}
.bene_block_message_text {
  width: 472px;
  height: 85px;
  background: #c97b29 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
}

.bene_block_message_text_responsive {
  width: auto;
  height: 85px;
  background: #c97b29 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
}

.bene_block_message_text_responsive > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.bene_block_message_text_responsive > p {
  width: 368px;
  height: 44px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  color: #f9f9f9;
  opacity: 1;
}

.bene_block_message_text > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.bene_block_message_text > p {
  width: 368px;
  height: 44px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  color: #f9f9f9;
  opacity: 1;
}

.bene_new_message_text_crt p > span {
  width: 232px;
  height: 22px;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  color: #f9f9f9;
}

.bene_new_message_text_crt_block_bank {
  width: 472px;
  height: 112px;
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px 30px;
}

.bene_new_message_text_crt_block_bank > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.bene_new_message_text_crt_block_bank > p {
  width: 368px;
  height: 83px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.bene_new_message_text_crt > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.bene_new_message_text_crt > p {
  width: 388px;
  /* height: 76px; */
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  /* padding-top: 1em; */
}

.bene_new_message_text_crt ul {
  /* width: 388px; */
  height: 76px;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: #f9f9f9;
  opacity: 1;
  padding: 10px 0 0 10px;
}

.bene_top_account-new {
  width: 158px;
  height: 22px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.79px;
  color: #dadada;
  margin-left: 20px;
  margin-bottom: 4px;
  position: relative;
}

.bene_top_account-new > img {
  position: absolute;
}

.bene_top_account > img,
.bene_top_account-new > img {
  width: 11px;
  height: 11px;
  top: -3px;
  right: -9px;
  cursor: pointer;
  margin-left: 5px;
}

.bene_swap_butt {
  margin-left: 20px;
  margin-bottom: 10px;
}

.bene_swap_butt_1-new {
  width: 58px !important;
  height: 24px !important;
  border-radius: 30px !important;
  text-align: center !important;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 16.34px;
  color: #f9f9f9;
  text-transform: capitalize !important;
}

.bene_swap_butt_2-new {
  width: 58px !important;
  height: 24px !important;
  margin-left: 10px !important;
  border-radius: 30px !important;
  text-align: center !important;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 16.34px;
  color: #f9f9f9;
  text-transform: capitalize !important;
}
.bank_viewProfile-new {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: inherit;
  position: relative;
}

.bank_viewProfile-new-responsive {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

@media (max-width: 385px) {
  .bank_name_client2-new {
    width: 180px;
  }
}

.bank_viewProfile-new::after {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 1px;
  height: 48px;
  background-color: #dadada;
}

.bank_viewProfile-new-responsive::after {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 1px;
  height: 48px;
  background-color: #dadada;
}

.bank_viewProfile-new > p {
  width: 39px;
  height: 9px;
  text-align: left;
  font: normal normal normal 7px/9px Open Sans;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
  margin-top: 4px;
}

.bank_viewProfile-new-responsive > p {
  width: 39px;
  height: 9px;
  text-align: left;
  font: normal normal normal 7px/9px Open Sans;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
  margin-top: 4px;
}

.bank_viewProfile-new > img {
  width: 25px;
  height: 25px;
  opacity: 1;
}

.bank_viewProfile-new-responsive > img {
  width: 25px;
  height: 25px;
  opacity: 1;
}

.bene_top_con-new {
  /* width: 412px; */
  padding-bottom: 2em;
  height: 17px;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: #f9f9f9;
  opacity: 1;
  margin-left: 20px;
  margin-bottom: 15px;
}

::-webkit-scrollbar {
  width: 9px;
  height: 10px !important;
}

::-webkit-scrollbar-thumb {
  width: 9px;
  height: 104px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

.bank_ass_bottom_crt-new {
  width: 100%;
  height: 140px;
  border-radius: 20px;
  padding: 1.5em 2em 0 2em;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 6;
}

.bank_new_crt_acc_bottom {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 472px;
  height: 110px;
  border-radius: 20px;
  /* padding: 9px; */
  padding: 10px 10px 10px 20px;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.bene_plus_Add {
  margin-right: 2px;
  font-size: 28px;
  font-weight: 300;
  width: 31px;
  height: 46px;
}

.bank_ass_create {
  width: 412px !important;
  height: 48px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}
.bank_ass_create:hover {
  background: #fefefe 0% 0% no-repeat padding-box !important;
  color: #1d3a6d !important;
}

.crt-Confirm-info-new {
  width: 335px;
  height: 19px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.79px;
  color: #dadada;
  opacity: 1;
  /* padding: 10px 0px 0px 9px; */
}

.crt-Confirm-sub-info-new {
  width: 335px;
  height: 51px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  color: #f9f9f9;
  opacity: 1;
  /* padding: 16px 0px 0px 9px; */
}

.bank_new_create_btn {
  width: 86px;
  height: 77px;
  /* box-shadow: 0px 3px 6px #00000029 !important; */
  background: var(---506994) 0% 0% no-repeat padding-box;
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  text-transform: none !important;
}
.bank_new_create_btn:hover {
  background: #fefefe6a 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.bank_archive_btn {
  margin-right: 8px !important;
  width: 24px;
  height: 24px;
}

.bank_ass_bottom_crt-new > p {
  font-size: 12px;
  color: #ffffff;
}

.bank_ass_next-new > p {
  font-size: 16px !important;
  color: #ffffff;
}

.bank_ass_next-new {
  width: 100% !important;
  height: 48px !important;
  background: #63ba74 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.bank_bene_view_acc {
  width: 412px !important;
  height: 48px !important;
  background: #8696b5 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}
.bank_pan_in_bottom {
  margin-top: 30px;
  width: 471px;
  height: 84px;
  background: #bea542 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.bank_pan_in_bottom > p {
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
}

.bank_pan_in_bottom img {
  width: 24px;
  height: 24px;
  background: #bea542 0% 0% no-repeat padding-box;
  margin: 0px 20px;
}

.bank_pan_in_bottom2 {
  margin-top: 30px;
  width: 471px;
  height: 139px;
  background: #bea542 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
}

.bank_new_message_text_block_nonarchive {
  /* width: 472px; */
  /* height: 85px; */
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px 30px;
}

.bank_new_message_text_block_nonarchive > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.bank_new_message_text_block_nonarchive > p {
  width: 368px;
  /* height: 66px; */
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  padding: 5px 0 0 0;
  color: #f9f9f9;
  opacity: 1;
}

.associate-acc-info {
  width: 286px;
  height: 85px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  color: #f9f9f9;
  opacity: 1;
}

.associate-acc-info_res {
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  color: #f9f9f9;
  opacity: 1;
}

.associate-acc-info-container {
  width: 301px;
  height: 110px;
  padding: 10px;
  background: var(---506994) 0% 0% no-repeat padding-box;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  position: absolute;
  left: 160px;
  top: 10px;
  z-index: 1;
}

.associate-acc-info-container_res {
  width: 280px;
  /* height: 110px; */
  padding: 10px;
  background: var(---506994) 0% 0% no-repeat padding-box;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  position: absolute;
  /* left: 160px; */
  top: 1.5em;
  z-index: 1;
}

.bank_new_create_btn:hover {
  color: #f9f9f9 !important;
  background: #f9f9f912 0% 0% no-repeat padding-box !important;
}

.bank_ass_next-new:hover {
  background: #7bca8b 0% 0% no-repeat padding-box !important;
}

.bank-confirm-btn-style {
  font-weight: 400;
  font-size: 13px;
  line-height: 16.34px;
  color: #f9f9f9;
}

.bank_new_create_btn_disabled {
  width: 86px;
  height: 77px;
  box-shadow: 0px 3px 6px #00000029 !important;
  background: var(---506994) 0% 0% no-repeat padding-box;
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  text-transform: none !important;
}

.beneBank_Main_res {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
}

.dividebank_res {
  margin: 0px 20px;
  height: inherit;
}

/* .user-crt-bank-name_res {
  z-index: 1;
  position: relative;
  background: var(---4cb537-verified-green) 0% 0% no-repeat;
  background: #4cb537 0% 0% no-repeat;
  padding-top: 13px;
  bottom: 26px;
  height: auto;
} */

.bank_new_crt_acc_bottom_res {
  display: flex;
  border-radius: 20px;
  padding: 9px;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0px 20px;
}

.crt-Confirm-info-new_res {
  font-weight: 600;
  font-size: 16px;
  line-height: 21.79px;
  color: #dadada;
  opacity: 1;
  padding: 10px 0px 0px 9px;
}

.crt-Confirm-sub-info-new_res {
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  color: #f9f9f9;
  opacity: 1;
  padding: 16px 10px 12px 9px;
}

.bank_Details_res {
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding-top: 20px;
  display: flex;
  margin: 0px 20px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6em;
}
