.internal_Container_txn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.Internal_stepper_Div {
  width: 100%;
  background-color: #314c7f;
  height: 164px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-shadow: 2px 2px 10px 0px rgba(26, 26, 26, 0.733);
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
}

/*----- new ---------*/

/* Common container styling */

.carousel-container {
  max-width: 1024px;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 186px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Mounted (Enter) and Unmounted (Exit) Animations */
.mountedRight {
  opacity: 0;
  transform: translateX(50%);
  animation: slideInRightBBPS 0.6s forwards;
}

.unmountedRight {
  opacity: 1;
  transform: translateX(0%);
  animation: slideOutRight 0.6s forwards;
}

.mountedLeft {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideInLeft 0.6s forwards;
}

.unmountedLeft {
  opacity: 1;
  transform: translateX(0%);
  animation: slideOutLeft 0.6s forwards;
}

@keyframes slideInRightBBPS {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideOutRight {
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes slideInLeft {
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideOutLeft {
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}
