.owner-dashboard {
  height: 100vh;
  width: 100%;
}

.ownerNavMenu {
  background: #314c7f 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  padding-left: 30px;
  height: 260px;
  padding: 0px 10px 20px 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000; /* to ensure it stays on top of other elements */
}

.owner-dsb-loader {
  position: relative;
  margin: auto;
}

.owner-dsb-loader > img {
  width: 49px !important;
  height: 50px !important;
}

.owner-gridLayout {
  width: 95%;
  max-width: 1024px;
  display: flex;
  /* Adjust as needed 
  grid-template-columns: repeat(7, minmax(0, 1fr));*/
  align-items: center;
}

.ownerMain {
  /* position: fixed; */
  height: calc(100vh - 260px);
  background: linear-gradient(to right, #1d3a6d 60%, #244277 40%);
}

.ownerSection {
  display: flex;
  flex-direction: row;
  max-width: 1024px;

  margin: auto;
  height: 100%;
  background: linear-gradient(to right, #1d3a6d 60%, #244277 40%);
}

@media (max-width: 800px) {
  .onwerdshbnav {
    display: flex !important;
  }
  .ownerMain,
  .ownerContent {
    height: auto !important;
    padding-bottom: 20px;
  }
  .ownerSection {
    flex-direction: column-reverse;
    height: 200%;
  }

  .ownerNavMenu {
    height: 150px;
  }

  .ownerAcountRightSec {
    padding-right: 20px;
  }

  .owner_dash_responsive {
    width: 95% !important;
  }
  .balance-summary-head,
  .balance-summary-amount {
    width: 100% !important;
  }

  .ownerGridLaySection {
    padding-left: 20px;
  }

  .recentSection {
    height: 40px !important;
    margin: 30px 10px 10px 10px !important;
  }

  .view-all {
    width: 90px !important;
    height: 40px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 680px) {
  .dashboard-card {
    width: 280px !important;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .dashboard-card {
    width: 100% !important;
  }
  .owner-gridcontainer {
    grid-template-columns: repeat(1, 100%) !important;
    padding-left: 20px;
  }
}
@media (max-width: 600px) {
  .dashboard-card {
    width: 250px !important;
  }
}

@media (max-width: 530px) {
  .ownerSection {
    height: 275%;
  }
  .dashboard-card {
    width: 100% !important ;
  }
  .owner-gridcontainer {
    grid-template-columns: repeat(1, 100%) !important;
    margin: 0 20px;
  }

  .ownerGridLaySection {
    padding-left: 0px;
  }
}

.ownerContent {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  width: 100%;
  height: 100%; /* Ensure it takes the height of its parent */
  display: flex;
  flex-direction: column; /* Allow children to stack within */
  padding-bottom: 5px;
}

.owner-recentSection {
  display: flex;
  max-width: 730px;
  justify-content: space-between;
  height: 42px;
  background: #234176 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000033;
  border-radius: 21px;
  opacity: 1;
  margin: 30px 10px 0px 0;
}

.recenttext {
  width: 51px;
  height: 22px;

  /* UI Properties */
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.ownerGridLaySection {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  margin-top: 20px;
  /* padding-bottom: 40px; */
  overflow: hidden;
  overflow-y: auto;
  padding-right: 20px;
  padding-bottom: 35px;
}

.owner-gridcontainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  max-width: 655px;
  row-gap: 20px; /* Set a fixed gap */
  column-gap: 20px; /* Set a fixed gap */
  padding-bottom: 30px;
}
.view-all {
  width: 103px;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000033;
  border: 1.6px solid #ff5860;
  border-radius: 21px;
  opacity: 1;
  cursor: pointer;
}

.onwerdshbnav {
  display: none;
}

.view-all p {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.view-all img {
  width: 12px;
  height: 14px;
  color: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.ownerAcountRightSec {
  display: flex;
  flex-direction: column;
  gap: 1%;

  --tw-bg-opacity: 1;
  background-color: rgba(36, 66, 119, var(--tw-bg-opacity));
  /* width: 100%; */
  height: auto;
}
.viewStatement_div {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background-color: #506994;
  /* opacity: 0.6; */
  border-radius: 22px !important;
}

.viewStatement_div p {
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.viewStatement_div img {
  width: 20px;
  height: 20px;
  opacity: 1;
}

.balance-summary-container-close {
  opacity: 0;
  height: 0;
  transition: all 0.3s ease;
}

.balance-summary-container-open {
  max-height: 300px;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 0px 0px 22px 22px;
}

.balance-summary-head {
  width: 308px;
  background-color: #314c7f;
  height: 37px;
  padding: 15px 0px;
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #1c396b;
}

.balance-summary-head p {
  text-align: center;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.balance-summary-head img {
  width: 12px;
  height: 14px;
}

.balance-summary-amount {
  width: 308px;
  background-color: #314c7f;
  height: 37px;
  padding: 15px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.balance-summary-amount span {
  font-size: 12px;
  color: #dadada;
  /* width: 78px; */
  height: 17px;
}

.balance-summary-amount p {
  text-align: right;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.balance-cards-div {
  width: 100%;
  max-height: 190px; /* previously was 250 */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: #3a5686;
  border-radius: 0px 0px 22px 22px;
  overflow-y: auto;
}

#infinityScrollOwnersView {
  display: flex !important;
  flex-direction: column !important;
  overflow: auto;
}

.no-data-div p {
  text-align: center;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-right: 2rem;
}

.ownerNotFound {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  width: 100%;
}

.ownerNotFound img {
  margin-top: 2rem;
  margin-bottom: -1rem;
  left: 289px;
  width: 288px;
  height: 194px;
}
