.popover-main {
  width: 100%;
  height: 0;
  background: #ffffff;
  margin: auto;
  padding-top: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  transform-origin: top center;
  overflow: hidden;
  opacity: 0;
  transition: height 300ms ease-in, opacity 500ms ease-in;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.popover-main.open {
  height: 216px;
  padding-top: 65px;
  transition: height 500ms ease-in;
  opacity: 1;
}

.popover-main.close {
  height: 0px;
  transform-origin: top center;
  opacity: 0;
  animation: collapseUp 500ms ease-in forwards;
}


@keyframes collapseUp {
  0% {
    opacity: 1;
    height: 216px;
  }

  80% {
    opacity: 0.5;
    height: 50px;
  }

  100% {
    opacity: 0;
    height: 0;
  }
}


@media only screen and (max-width: 480px) {
  .popover-main {
    display: none;
  }
}

