@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@font-face {
  font-family: "fontello";
  src: url("assets/font/fontello.eot");
  src: url("assets/font/fontello.eot") format("embedded-opentype"),
    url("assets/font/fontello.woff") format("woff"),
    url("assets/font/fontello.woff2") format("woff2"),
    url("assets/font/fontello.ttf") format("truetype"),
    url("assets/font/fontello.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

:root {
  --toastify-font-family: "Open Sans", sans-serif;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  width: 3px;
}

.vendor-txn::-webkit-scrollbar {
  width: 3px;
  height: 25px;
}

.vendor-txn::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.vendor-txn::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
  outline: 1px solid #314d7e;
  border-radius: 25px;
  height: 75px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #d3d3d3;
}

body::-webkit-scrollbar-thumb {
  background-color: #fafafa;
  border-radius: 25px;
}

.bodymaindiv {
  background-color: #1d3a6d;
  overflow-x: hidden;
  margin-bottom: 120px;
}

@media (min-width: 801px) {
  .bodymaindiv {
    background-color: #1d3a6d;
    overflow-x: hidden;
    margin-bottom: 0px;
  }
}

@layer base {
  html {
    font-family: -apple-system, "Open Sans", sans-serif;
  }
}

#webviewd {
  display: block;
}
#mobileviewd {
  display: none;
}

.clearfix {
  clear: both;
}

input[type="password"] {
  font-weight: 100;
  letter-spacing: 1px;
  font-family: "fontello" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row {
  margin-right: -5px;
  margin-left: -5px;
  box-sizing: border-box;
}

.container-fluid {
  margin-right: 0px;
  margin-left: 0px;
  box-sizing: border-box;
}

.row:before {
  content: "";
  display: table;
}

.row:after {
  content: "";
  display: table;
}

.row:after {
  clear: both;
}

.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-sx-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-13 {
  float: left;
}

.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-sx-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-13 {
  position: relative;
  min-height: 1px;
  margin-left: 0px;
  margin-right: 0px;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
}

.col-xl-1 {
  width: 8.333333%;
}

.col-xl-2 {
  width: 16.666667%;
}

.col-xl-3 {
  width: 25%;
}

.col-xl-4 {
  width: 33.333333%;
}

.col-xl-5 {
  width: 41.666667%;
}

.col-xl-6 {
  width: 50%;
}

.col-xl-7 {
  width: 58.333333%;
}

.col-xl-8 {
  width: 66.666667%;
}

.col-xl-9 {
  width: 75%;
}

.col-xl-10 {
  width: 83.333333%;
}

.col-xl-11 {
  width: 91.666667%;
}

.col-xl-12 {
  width: 100%;
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}

@media only screen and (min-width: 982px) and (max-width: 1024px) {
}

@media only screen and (min-width: 802px) and (max-width: 981px) {
  .col-md-1 {
    width: 8.333333%;
  }

  .col-md-2 {
    width: 16.666667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.333333%;
  }

  .col-md-5 {
    width: 41.666667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.333333%;
  }

  .col-md-8 {
    width: 66.666667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.333333%;
  }

  .col-md-11 {
    width: 91.666667%;
  }

  .col-md-12 {
    width: 100%;
  }
}

@media only screen and (min-width: 641px) and (max-width: 801px) {
  .col-sm-1 {
    width: 8.333333%;
  }

  .col-sm-2 {
    width: 16.666667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.333333%;
  }

  .col-sm-5 {
    width: 41.666667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.333333%;
  }

  .col-sm-8 {
    width: 66.666667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.333333%;
  }

  .col-sm-11 {
    width: 91.666667%;
  }

  .col-sm-12 {
    width: 100%;
  }
  #webviewd {
    display: none;
  }

  #mobileviewd {
    display: block;
  }
}

@media only screen and (min-width: 321px) and (max-width: 640px) {
  .col-xs-1 {
    width: 8.333333%;
  }

  .col-xs-2 {
    width: 16.666667%;
  }

  .col-xs-3 {
    width: 25%;
  }

  .col-xs-4 {
    width: 33.333333%;
  }

  .col-xs-5 {
    width: 41.666667%;
  }

  .col-xs-6 {
    width: 50%;
  }

  .col-xs-7 {
    width: 58.333333%;
  }

  .col-xs-8 {
    width: 66.666667%;
  }

  .col-xs-9 {
    width: 75%;
  }

  .col-xs-10 {
    width: 83.333333%;
  }

  .col-xs-11 {
    width: 91.666667%;
  }

  .col-xs-12 {
    width: 100%;
  }
  #webviewd {
    display: none;
  }

  #mobileviewd {
    display: block;
    height: 100vh;
    /* overflow: hidden scroll; */
    scrollbar-width: none;
  }
}

@media only screen and (max-width: 320px) {
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-xs-1,
  .col-sx-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    width: 100%;
    margin-right: 0px;
  }
  #webviewd {
    display: none;
  }

  #mobileviewd {
    display: block;
  }
}

#id-firstLetter::first-letter,
#email-id-firstLetter::first-letter {
  text-transform: lowercase !important;
}

#id-textTag::first-letter {
  text-transform: none !important;
}
