.ownerIfscMain {
  box-shadow: 5px 5px 11px #00000026;
  opacity: 1;
  padding-left: 30px;
  height: 140px;
  padding: 0 10px;
  padding-top: 16px;
}

.ownerIfscBankSearch {
  display: flex;
  margin: 0 auto;
  max-width: 1024px;
  margin-top: 63px;
  flex-direction: row;

  justify-content: space-between;
}

.midSection {
  margin-left: 380px;
  font: normal normal normal 17px/39px Open Sans;

  color: #f9f9f9;
  opacity: 1;
}

.input_ifsc_search {
  width: 420px;
  height: 37px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  display: flex;
  padding: 10px 10px 10px 0.5px;
  justify-content: space-around;
}

.input_ifsc_search > input {
  width: 90%;
  outline: none;
  background-color: transparent;
  text-align: left;
  font: normal normal normal 14px / 18px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.search_owner_ifsc {
  width: 14px;
  height: 14px;
  opacity: 1;
  margin-left: 10px;
  cursor: pointer;
}
.owner_ifsc_code_text_input {
  background: #506994;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 40px;
  margin-right: 14px;
  opacity: 1;

  width: 402px;
  height: 37px;
  padding: 15px;
  font: normal normal normal 16px/30px Open Sans;
  color: #dadada;
}

.button_clear {
  width: 120px;
  height: 37px;
  background: #565656 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 3px 27px;
  opacity: 1;
  cursor: pointer;
}

.button_clear_text {
  text-align: center;
  font: normal normal normal 16px / 30px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.ownerIfsclayout {
  width: 100%;

  /* display: flex; */
  height: 96vh;
  
  background: linear-gradient(to right, #3a5686 45%, #1d3a6d 55%);
}

.ownerIfscSection {
  display: flex;
  flex-direction: row;
  max-width: 1024px;
  margin: auto;
  height: 96vh;
  background: linear-gradient(to right, #3A5686 45%, #1D3A6D 55%);
}

.owner_ifsc_code {
  text-align: left;
  font: normal normal 600 32px/43px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.ownerIfscSection1 {
 

  width: 45%;
  height: 90vh;
}

.ownerIfscimg {
  opacity: 1;
  margin-top: 45px;
  margin-left: 40px;
  width: 120px;
  height: 120px;
  /* background: transparent radial-gradient(closest-side at 50% 50%, #FAFAFA 0%, #FFFFFFB5 100%) 0% 0% no-repeat padding-box; */
  border-radius: 65px;
  opacity: 1;
  background: #ffffffb5;
}

.ownerIfscimgmain {
  margin-left: 30px;
}

.image_owner {
  position: relative;
  width: 109px;
  height: 28px;
  top: 46px;
  left: 6px;
}

.owner_bg_img {
  background: #506994;
  opacity: 1;
  width: 112px;
  height: 90px;
}
.ownerIfscdiv {
  display: flex;
  flex-direction: column;
  height: 50px;
  margin-left: 162px;
}

.owner_parent_div {
  
  width: 250%;
  display: flex;
  flex-direction: row;
}

.ifsc_code_owner {
  width: 213px;
  height: 43px;
  text-align: left;
  font: normal normal 600 24px/26px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.ifsc_code_text {
  font: normal normal normal 12px/18px Open Sans;
  width: 100px;
  color: #c9c9c9;
  opacity: 1;
}

.ownerIfscSection1_text {
  font: normal normal normal 18px/25px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.Ifsc_border_div {
  width: 400px;
  border-top: 2px solid #c9c9c9;
}

.bank-ifsclogo-details {
  display: flex;
    flex-direction: column;
}

.ifsclogo-bank-container{
  background: #fafafa;
    height: 88.5px;
    width: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.detailed-ifscbank-logo{
  width: 80px;
}
.mid_border_content {
  width: 400px; 
  border-top: 2px solid #1D3A6D;
}

.ownerbankname {

  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 35px;
   
}

.bank_name_owner {
  

  font: normal normal normal 16px/30px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.ownerIfscSection2 {
  display: flex;
  flex-direction: column;
  gap: 1%;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));

  width: 55%;
  height: 96vh;
}


.ownerIfsccontainer{
 
      width: 495px;
      cursor: cursor;
      margin-left: 40px;
      margin-top: 55px;
      padding: 35px;
      opacity: 1;
      height: 492px;
      border-radius: 20px;
      justify-content: center;
    background: #314D7E 0% 0% no-repeat padding-box;
      box-shadow: -5px -5px 11px #71717133;
  
}
.ownerMaptext {
  font: normal normal normal 16px/30px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;

}



.ownerMapPadding {
  margin-top: 25px;

}

.addressLabelMain {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
 
}

.addressLabelText {
  width: 390px;
  height: 22px;
  text-align: left;

  font: normal normal Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}
