.cin_main_div {
  display: flex;
  flex-direction: column;
}

.cin_search {
  position: fixed;
  width: 100%;
  height: 13.125rem;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 0.3125rem 0.3125rem 0.6875rem #00000026;
  opacity: 1;
  padding-bottom: 1.6875rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 99;
}

.cin_search_1024 {
  max-width: 64rem;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cin_input_div {
  display: flex;
  position: relative;
  margin-right: 1.25rem;
}

.cin_input_div > input {
  width: 54.75rem;
  height: 3.0625rem;
  background: #667ca3 0% 0% no-repeat padding-box;
  border-radius: 0.625rem;
  opacity: 1;
  padding-left: 1.25rem;
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  outline: none;
}

.cin_input_div > input::placeholder {
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.cin_input_div > img {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  right: 1.25rem;
  top: 0.9375rem;
  cursor: pointer;
}

.cin_fetch_button {
  width: 8rem !important;
  height: 3.0625rem !important;
  border-radius: 0.625rem !important;
  text-align: center !important;
  font: normal normal 600 0.875rem/1.1875rem Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
}

.cin_details {
  width: 100%;
  height: 16.9375rem;
  margin-top: 13.125rem;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0.3125rem 0.3125rem 0.6875rem #00000026;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cin_details_1024 {
  max-width: 64rem;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cin_details_1 {
  display: flex;
  width: 64rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.0625rem solid #1d3a6d;
  height: 5.625rem;
}

.cin_img_div {
  width: 6.375rem;
  height: 5.625rem;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.cin_img_div > img {
  width: 2.5rem;
  height: 2.5rem;
}

.cin_details_1_left {
  width: 22.4375rem;
  position: relative;
}

.cin_details_1_left::after {
  content: "";
  position: absolute;
  right: 0;
  top: -0.5rem;
  width: 0.0625rem;
  height: 3.75rem;
  background-color: #1d3a6d;
}

.cin_details_1_left > h4 {
  text-align: left;
  font: normal normal 600 1rem/1.375rem Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.cin_details_1_left > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.5625rem Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.cin_details_1_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 41.5625rem;
  padding-left: 1.25rem;
}

.cin_details_1_right_cin_no > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.5625rem Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.cin_details_1_right_cin_no > h4 {
  text-align: left;
  font: normal normal 600 1rem/1.375rem Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.cin_tabs {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.875rem 0px;
}

.cin_tabs_1024 {
  max-width: 64rem;
  width: 64rem;
}

.cin_tabs_button {
  margin-bottom: 1.875rem;
}

.cin_tabs_button_1 {
  width: fit-content !important;
  height: 1.6875rem !important;
  border-radius: 1.125rem !important;
  text-align: center !important;
  letter-spacing: 0px !important;
  margin-right: 1.25rem !important;
  text-transform: none !important;
  padding: 0.3125rem 1.25rem !important;
}
.cin_tabs_button_1:hover {
  background-color: #a3a3a3 !important;
}

.cin_details_slide {
  display: flex;
  align-items: center;
}

.cin_details_slide_con_cap {
  width: 20.5rem;
  height: 11.1875rem;
  background: #314d7e 0% 0% no-repeat padding-box;
  box-shadow: -0.3125rem -0.3125rem 0.6875rem #71717133;
  border-radius: 1.25rem;
  opacity: 0.8;
  margin-right: 1.25rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cin_details_slide_con_cap > h4 {
  text-align: left;
  font: normal normal 600 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  opacity: 1;
}

.cin_details_slide_con_cap_customer_no > h5 {
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.cin_details_slide_con_cap_customer_no > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.0625rem Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.cin_details_slide_con_cap_email {
  padding: 0.625rem 0px;
}

.cin_details_slide_con_cap_email > h5 {
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.cin_details_slide_con_cap_email > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.0625rem Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.cin_details_slide_con_cap_customer_no {
  padding: 0.625rem 0px;
  border-bottom: 0.0625rem solid #1d3a6d;
}

.cin_directors_slide {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.cin_directors_box {
  width: 15.0625rem;
  height: 213px;
  background: #314d7e 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.625rem;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 15px;
}

.cin_directors_box:hover {
  background: #4c6a9e 0% 0% no-repeat padding-box;
}

.cin_directors_box_img > img {
  width: 3.75rem;
  height: 3.75rem;
  opacity: 1;
  margin-bottom: 0.625rem;
}

.cin_directors_box_img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cin_directors_box_img > h4 {
  width: 12.5625rem;
  height: 1.375rem;
  text-align: center;
  font: normal normal normal 1rem/1.375rem Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cin_directors_box_details_left > h5 {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.cin_directors_box_details_left > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.5625rem Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.cin_directors_box_details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.cin_directors_box_details > img {
  width: 22px;
  height: 22px;
  transform: rotate(180deg);
  cursor: pointer;
}

.cin_address_slide {
  width: 50.3125rem;
  height: 18.125rem;
  background: #314d7e 0% 0% no-repeat padding-box;
  box-shadow: -0.3125rem -0.3125rem 0.6875rem #71717133;
  border-radius: 1.25rem;
  opacity: 0.8;
  padding: 1.25rem;
  display: flex;
}

.cin_address_slide_left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 1.25rem;
}

.cin_address_slide_left > h5 {
  text-align: right;
  font: normal normal 600 1rem/1.375rem Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 1.25rem;
}

.cin_address_slide_left > p {
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  width: 24.8125rem;
  height: 3.5625rem;
}

.cin_address_slide_right {
  width: 21.75rem;
  height: 15.625rem;
}

.cin_default_img {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 18.75rem;
}

.cin_default_img > img {
  width: 25rem;
  height: 17.75rem;
  opacity: 1;
  margin-bottom: 2.5rem;
}

.cin_default_img > p {
  text-align: center;
  font: normal normal normal 1.25rem/1.6875rem Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  width: 424px;
  height: 54px;
}

.cin_loading_loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22.625rem;
}

.cin_not_found {
  margin-top: 20.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cin_not_found > img {
  width: 18.75rem;
  height: 12.625rem;
  opacity: 0.9;
  margin-bottom: 2.8125rem;
}

.cin_not_found > p {
  height: 3.375rem;
  text-align: center;
  font: normal normal normal 1.25rem/1.6875rem Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}
