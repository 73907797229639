.hide-scroll-bar {
  scrollbar-width: none !important;
}

.divideAmount {
  width: 472px;
  max-height: auto;
  margin: 0 20px 20px 20px;
  overflow-y: auto;
}

.upload_img_summary_p1 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
  margin-bottom: 10px;
}

.upload_img_summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.upload_img_summary_p2 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
}

.top_div_upload {
  display: flex;
}

.upload_cancel {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 40%;
  cursor: pointer;
  right: 20px;
}

@media screen and (max-width: 1024px) {
  .divideAmount {
    padding: 0 20px;
    margin: 0 !important;
  }
}
