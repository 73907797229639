.successDetailText {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  padding-top: 1%;
  width: 85%;
  margin: auto;
}
.simpleSuccessLine {
  margin: 10px 0px;
  height: 2px;
  background: #dadada;
}

.cat-show {
  background-color: white;
  width: 59px;
  height: 50px;
  border-radius: 10px;
  padding: 5px;
}

.holder_name {
  text-transform: lowercase;
}
.holder_name:first-line {
  text-transform: capitalize;
}

.txnMessage_bottom {
  text-align: center;
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.txnMessage_top {
  text-align: center;
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 20px;
}
