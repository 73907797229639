.dashboard-container-div {
  margin: 0rem auto 0px auto;
  color: #ffffff;
  width: 100%;
  max-width: 1024px;
  color: #ffffff;
  width: 100%;
  max-width: 1024px;
  overflow-x: hidden;
}

@media (min-width: 801px) {
  .dashboard-container-div {
    margin: 5rem auto 0px auto;
  }
}

.dashboard_header_div {
  background-color: #506994;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.accordion {
  border: 1px solid #3a5686;
  border-radius: 5px;
  position: relative;
}

.accordion-title {
  background-color: #3a5686;
  padding: 15px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.accordion-arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #f05a6a;
  transition: transform 0.3s ease;
}

.accordion-arrow.open {
  transform: rotate(180deg);
}

.accordionDashboard-content {
  background-color: #1c396c;
  /* position: absolute; */
  width: 100%;
  height: 0;
  opacity: 0;
  overflow: hidden;
  z-index: 99;
  top: 55px;
  /* transition: height 0.3s ease, opacity 0.3s ease; */
}
.Carouselcontentmainm {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.Carouselcontentmainm.open {
  opacity: 1;
}
.accordionDashboard-content.open {
  /* height: 100vh; */
  height: max-content;
  opacity: 1;
}

.dashboard_logo_div {
  padding: 15px 10px;
  width: 100%;
  border: 1px solid #1c396c;
}

.dashboard_header_main {
  width: 100%;
  background-image: url(../../assets/newdashboardicon/homedashboadentity.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #506994;
}

.dashboard_header_left {
  width: 70%;
  float: left;
  padding: 10px 20px;
}

.dashboard_header_right {
  width: 30%;
  float: left;
  padding: 10px 5px;
}

.dashboard_summery {
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
  height: 592px;
}

.dashboard_summerym {
  background-color: #506994;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  width: 95%;
}

.dashboard_hedderm {
  background-color: #506994;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.dashboard_hedderm .activetab {
  background-color: #506994;
}

.dashboard_tranxcontentmainmm {
  width: 100%;
  background-color: #506994;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px 20px;
}

.dashboard_welcome {
  background-color: #314d7e;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin: 10px auto 20px auto;
  width: 97%;
  padding: 0px 10px;
}

.dashboard_welcometext {
  height: 50px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
}

.dashboard_hedder {
  background-color: #3a5686;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  align-items: center;
  justify-content: center;
  height: 44px;
}

.dashboard_contenthedder {
  background-color: #3a5686;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
}

.dashboard_contenthedder_ent {
  background-color: #3a5686;
  align-items: center;
  justify-content: center;
  padding: 10px 15px 0px 15px;
}

.dashboard_card {
  background-color: #3a5686;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 10px;
  background-color: #3a5686;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 10px;
}

.CaraouselBar {
  width: 100%;
  padding: 20px 15px 15px 15px;
  width: 100%;
  padding: 20px 15px 15px 15px;
}

.Caraouselcontent {
  width: 100%;
  border-bottom: 1px solid #1c396b;
  height: 50px;
  width: 103%;
  border-bottom: 1px solid #1c396b;
  height: 50px;
}

.Caraouselcontentmain {
  width: 103%;
  height: 376px;
  padding: 15px 10px;
  overflow-y: auto;
  background-color: #314d7e;
  /* border-bottom-left-radius: 20px; */
  /* border-bottom-right-radius: 20px; */
}

.Caraouselcontentmainm {
  width: 100%;
  height: 80vh;
  padding: 15px 10px;
  overflow-y: auto;
}

.Caraouselcontentleft {
  width: 70%;
  color: #dadada;
  font-size: 14px;
  padding: 15px;
  float: left;
  width: 70%;
  color: #dadada;
  font-size: 14px;
  padding: 14px;
  float: left;
  background-color: #314d7e;
}

.Caraouselcontentright {
  width: 30%;
  color: #f9f9f9;
  font-size: 12px;
  background-color: #506994;
  padding: 16px;
  float: left;
  cursor: pointer;
  height: 49.5px;
  width: 30%;
  color: #f9f9f9;
  font-size: 12px;
  background-color: #506994;
  padding: 16px;
  float: left;
  cursor: pointer;
  height: 48.5px;
}

.CarouselDotbar {
  width: 84%;
  float: left;
  width: 84%;
  float: left;
}

.Caraouseltab {
  width: 100%;
  padding: 0px;
}

.CarouselDottabl {
  width: 50%;
  float: left;
  font-size: 10px;
  padding: 15px 10px;
  border-top-left-radius: 22px;
  background-color: #244277;
  cursor: pointer;
}

.CarouselDottabl:hover {
  background-color: #60779d;
}

.CarouselDottabr {
  width: 50%;
  float: left;
  font-size: 10px;
  padding: 15px 10px;
  border-top-right-radius: 17px;
  background-color: #244277;
  cursor: pointer;
}

.CarouselDottabr:hover {
  background-color: #60779d;
}

.CarouselDottablm {
  width: 50%;
  float: left;
  font-size: 12px;
  padding: 15px 10px;
  border-top-left-radius: 17px;
  background-color: #244277;
  cursor: pointer;
}

.CarouselDottabrm {
  width: 50%;
  float: left;
  font-size: 12px;
  padding: 15px 10px;
  border-top-right-radius: 17px;
  background-color: #244277;
  cursor: pointer;
}

.NextAndPrevIcon {
  width: 16%;
  float: left;
}

.containerscroll {
  overflow: hidden;
  width: 97%;
  position: absolute;
  background-color: #3a5686;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.showSecondContentdm {
  transform: translateX(0);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 1;
  width: 100%;
}

.hideSecondContentdm {
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.showFirstContentdm {
  transform: translateX(0);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 1;
  width: 100%;
}

.hideFirstContentdm {
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  pointer-events: none;
}

.showSecondContentd {
  transform: translateX(0);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  width: 100%;
  /* transition: 0.3s linear; */
  opacity: 1;
  /* position: absolute; */
  left: 0;
}

.hideSecondContentd {
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  width: 0;
  /* transition: 0.3s linear; */
  opacity: 0;
  height: 0;
  /* position: absolute; */
  /* left: 97%; */
}

.showFirstContentd {
  transform: translateX(0);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  width: 97%;
  /* transition: 0.3s linear; */
  opacity: 1;
  /* position: absolute; */
}

.hideFirstContentd {
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  width: 0;
  /* transition: 0.3s linear; */
  opacity: 0;
  height: 0;
  /* position: absolute; */
}

.containerscrollm {
  overflow: hidden;
  width: 100%;
}

.containerscrollm {
  overflow: hidden;
  width: 100%;
}
.dashboard_CarouPrevIcon {
  img {
    width: 20px;
    height: 20px;
    border-radius: 32px;
    cursor: pointer;
  }
}

.dashboard_CarouNextIcon {
  img {
    width: 20px;
    height: 20px;
    border-radius: 32px;
    cursor: pointer;
  }

  img {
    width: 20px;
    height: 20px;
    border-radius: 32px;
    cursor: pointer;
  }
}

div[disabled] {
  pointer-events: none;
  background-color: #3a5686;
  cursor: default;
}

.textcontent {
  font-size: 16px;
  color: #f9f9f9;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  text-transform: capitalize !important;
  padding-bottom: 5px;
}

.textheader {
  font-size: x-small;
  color: #dadada;
}

.textcontent_no {
  font-size: 12px;
  color: #f9f9f9;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
}

.textcontent_bank {
  font-size: 12px;
  color: #4ddd37;
  font-weight: 600;
}

.textheader_bank {
  font-size: 9px;
  color: #f9f9f9;
}

.dashboard_bank_refresh {
  width: 13%;
  height: 100%;
  background: #506994;
  opacity: 1;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  float: left;
  padding: 17px 0px;
  cursor: pointer;
}

.dashboard_bank_display_div {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  height: 100%;
  width: 87%;
  float: left;
}

.dashboard_display_div {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  height: 62px;
  width: 95%;
  float: left;
}

.dashboard_cat {
  width: 5%;
  height: 62px;
  background: #ffc450;
  opacity: 1;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  float: left;
  padding: 15px 0px;
}

.catgoryicon {
  width: 20%;
  float: left;
  padding: 17px 15px;
  text-align: left;
  background: #fff;
  height: 62px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.datehader {
  width: 100%;
  background: #506994;
  padding: 15px;
  font-size: 14px;
  font-weight: 600;
}

.datecontent {
  width: 100%;
  padding: 15px;
  height: 95px;
}

.datediv {
  width: 20%;
  background: #506994;
  padding: 10px 10px;
  text-align: center;
  border-radius: 10px;
  font-size: 14px;
  float: left;
  font-weight: 600;
}

.datedisc {
  width: 80%;
  padding: 15px 10px;
  float: left;
  font-size: 12px;
  font-weight: 600;
}

hr.new {
  margin: 0rem auto 0rem auto;
  overflow: hidden;
  border: 0px;
  border-bottom: 2px solid #1c396c;
  width: 90%;
}

hr.new1 {
  margin: 0rem auto 0rem auto;
  overflow: hidden;
  border: 0px;
  border-bottom: 2px solid #506994;
  width: 90%;
}

.Caraouseldatemain {
  width: 100%;
  height: 83%;
  max-height: 531px;
  padding: 15px 0px;
  overflow-y: auto;
  background-color: #506994;
}

.dashboard_tranx {
  width: 97%;
  background: #244277;
  border-radius: 20px;
  margin: 10px auto 20px auto;
}

.dashboard_tranxmain {
  width: 100%;
  height: 47px;
}

.dashboardcontentleft {
  width: 55%;
  color: #dadada;
  font-size: 14px;
  padding: 14px 15px;
  float: left;
  font-weight: 600;
}

.dashboardcontentleftr {
  color: #dadada;
  font-size: 14px;
  font-weight: 600;
}

.dashboardcontentright {
  width: 25%;
  max-width: 100px;
  color: #f9f9f9;
  font-size: 12px;
  background-color: #314d7e;
  padding: 15px;
  cursor: pointer;
  border-top-right-radius: 20px;
  float: right;
}

.dashboard_tranxcontentmain {
  width: 100%;
  height: 189px;
  display: table;
  background-color: #3a5686;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.tranxcontentmainfirst {
  width: 25%;
  display: table-cell;
  vertical-align: top;
}

.table-containerr {
  width: 75%;
  display: table-cell;
  vertical-align: top;
  position: relative;
}

.inner-table-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.showSecondContentdc {
  display: table;
  width: 100%;
  transition: 0.3s linear;
  opacity: 1;
  position: absolute;
  left: 0;
  height: 100%;
}

.hideSecondContentdc {
  display: table;
  width: 0;
  transition: 0.3s linear;
  opacity: 0;
  height: 100%;
  position: absolute;
  left: 49%;
  overflow: hidden;
}

.showFirstContentdc {
  display: table;
  width: 100%;
  transition: 0.3s linear;
  opacity: 1;
  position: absolute;
  height: 100%;
}

.hideFirstContentdc {
  display: table;
  width: 0;
  transition: 0.3s linear;
  opacity: 0;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.tranxcontentmainsecond {
  width: 25%;
  padding: 30px 0px 40px 0px;
  background-color: #506994;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.tranxcontentmainthird {
  width: 25%;
  padding: 30px 0px 40px 0px;
  background-color: #506994;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.tranxcontentmainforth {
  width: 25%;
  background-color: #506994;
  padding: 30px 0px 40px 0px;
  /* border-bottom-right-radius: 20px; */
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.tranxcontentmainforthlast {
  width: 25%;
  /* background-color: #506994; */
  padding: 30px 0px 40px 0px;
  /* border-bottom-right-radius: 20px; */
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.tranxcontentmainforth:hover {
  background-color: #60779d;
}

.borderright {
  border-right: 1px solid #1c396c;
}

.Bene_View_All {
  padding-left: 1em;
  padding-top: 2em;
  cursor: pointer;
}

.Bene_View_All:hover {
  background-color: #60779d;
}

.borderBottom {
  width: 8em;
  border-bottom: 1px solid #1c396c;
}

.Internal_View_All {
  padding-left: 1em;
  padding-top: 1.5em;
  cursor: pointer;
  padding-bottom: 0.2em;
}

.Internal_View_All:hover {
  background-color: #60779d;
  border-bottom-left-radius: 1rem;
}

.dashboard_tranxcontentmainm {
  width: 100%;
  background-color: #3a5686;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px 20px;
}

.tranxcontentmainfirstm {
  width: 100%;
  margin-bottom: 20px;
}

.tranxcontentmainsecondm {
  width: 100%;
  background-color: #244277;
  border-radius: 20px;
  margin-bottom: 20px;
}

.tranxcontentmainthirdm {
  width: 100%;
  background-color: #244277;
  border-radius: 20px;
  margin-bottom: 20px;
}

.tranxcontentmainforthm {
  width: 100%;
  background-color: #244277;
  border-radius: 20px;
  width: 100%;
  background-color: #244277;
  border-radius: 20px;
}

.table-row {
  position: absolute;
  width: 100%;
  transition: all 1s ease;
  opacity: 0;
}

.table-rowm {
  position: absolute;
  width: 100%;
  transition: all 1s ease;
  opacity: 0;
}

.slide-right-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-right-enter-done {
  transform: translateX(0);
  opacity: 1;
}

.slide-right-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-right-exit-done {
  transform: translateX(100%);
  opacity: 0;
}

.slide-left-enter {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-left-enter-done {
  transform: translateX(0);
  opacity: 1;
}

.slide-left-enter-done-acc {
  /* transform: translateX(0); */
  opacity: 1;
}

.slide-left-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-left-exit-done {
  transform: translateX(-100%);
  opacity: 0;
}

.table-containerm {
  display: table;
  width: 100%;
  height: 250px;
}

/* ::-webkit-scrollbar {
  width: 12px;
  scrollbar-width: thin;
  scrollbar-color: #314d7e #d3d3d3;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #fafafa;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3;
} */

::-ms-scrollbar {
  width: 12px;
}

::-ms-scrollbar-track {
  background: #d3d3d3;
}

::-ms-scrollbar-thumb {
  background: #fafafa;
  border-radius: 6px;
}

::-ms-scrollbar-thumb:hover {
  background: #d3d3d3;
}
