.settle_main_div_BankAccount {
    margin: 10px 30px;
    border-bottom: 1px solid #172e57;
    padding-bottom: 10px;
    align-items: flex-start;
    margin-bottom: 5px;
}

.settle_main_div_BankAccount p {
    width: 164px;
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: #DADADA;
}

.settle_main_div_BankAccount h6 {
    width: 164px;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    color: #F9F9F9;
}

.settle_main_div_container_internal {
    width: 472px;
    height: 338px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 30px;
    position: relative;
    margin: 0px 20px;
}