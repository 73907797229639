.dashboard-card {
  width: 315px;
  position: relative;
}

.maindashboard-card {
  width: 100%;
  background-color: #506994;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
}

.maindashboard-card-left {
  width: 50%;
  float: left;
  background-color: #506994;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.maindashboard-card-right {
  width: 50%;
  float: left;
}

.maindashboard-card .right-first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 74%;
  float: left;
  padding: 5px 0px 0px 10px;
}

.maindashboard-card .right-first-child p {
  text-align: right;
  font: normal normal 600 16px/17px Open Sans;
  letter-spacing: 0px;
  color: #4de659;
  opacity: 1;
  margin-top: 10px;
}

.maindashboard-card .right-first-child p:nth-child(2) {
  text-align: left;
  font: normal normal normal 9px/11px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-top: 3px;
}

.maindashboard-card .maindashboard-card-right .right-second-childu {
  width: 35px;
  height: 67px;
  border-radius: 0px 10px 10px 0px;
  background: #667ca3 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: right;
}

.maindashboard-card .maindashboard-card-right .right-second-childd {
  width: 29px;
  height: 33px;
  border-radius: 0px 0px 10px 0px;
  opacity: 1;
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: right;
}

.maindashboard-card .maindashboard-card-right .right-second-child img {
  height: 14px;
  width: 25px;
}

.maindashboard-card .maindashboard-card-right .right-second-child:hover {
  background-color: #667ca3;
}

.arrowUpDown-div {
  position: absolute;
  height: 10px;
  background-color: green;
}

.arrowUpDown-div-up {
  position: absolute;
  top: 26px;
  right: 45px;
}

.arrowUpDown-div-down {
  position: absolute;
  top: 9px;
  right: 48px;
}

.bank-logom {
  width: 54px;
  float: left;
}

.bank-account-no {
  float: left;
  width: 65%;
  font-size: 12px;
  padding: 5px 10px;
}

.maindashboard-card-leftg {
  width: 100%;
  height: 35px;
}

.maindashboard-card-leftgm {
  width: 100%;
}

.bank-account-name {
  width: 100%;
  padding: 3px 15px 10px 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}

.bank-logo .card-logo {
  border-radius: 0 0 20px 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
}

.balance-amt {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #dedede;
  opacity: 1;
}

.balance-text {
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-top: 5px;
}
