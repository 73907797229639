.beneBank_Main {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dividebank_edit {
  width: 472px;
  margin: 0px 20px;
}

.no-scroll {
  overflow: hidden;
}

.bankright {
  width: 100%;
}

.bankLeft {
  width: 100%;
}

.bank_Details_edit_pop {
  height: 160px;
  width: 472px;
  border-radius: 30px;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;
  padding-bottom: 12px;
}

.bank_Details_Edit {
  width: 472px;
  border-radius: 30px;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;
}

.dividepan {
  width: 472px;
  margin: 0px 20px;
}

.bank_ass_top {
  width: 472px;
  height: 345px;
  color: white;
}

.bank_achived_main {
  width: 100%;
}

.bank_hidden_accounts {
  margin-left: 30px;
  margin-top: 20px;
}

.bank_hidden_accounts > p {
  width: 375px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.bank_hidden_accounts p > span {
  width: 259px;
  height: 19px;
  text-align: left;
  font: normal normal 600 14px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.bank_hidden_accounts_edit {
  margin-left: 30px;
  margin-top: 20px;
}

.bank_hidden_accounts_edit > p {
  width: 400px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.bank_scroll_All_approval {
  height: 250px;
  overflow-y: scroll;
}

.bene_achived_scroll {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 28px;
}

.bene_child_scroll {
  width: 412px !important;
  height: 68px !important;
  background: #1d3a6d 0% 0% no-repeat padding-box !important;
  border-radius: 20px !important;
  display: flex !important;
  justify-content: flex-start !important;
  padding: 0px !important;
  margin: 0px !important;
  position: relative !important;
  align-items: flex-start !important;
  margin-bottom: 10px !important;
  text-transform: none !important;
}

.bank_client_name {
  padding-left: 19px;
  padding-right: 65px;
  height: fit-content;
}

.bank_name_client2 {
  width: 253px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/15px Open Sans;
  letter-spacing: 0px;
  color: #b4b4b4;
  opacity: 1;
  margin-top: 14px;
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bene_bank_client2 {
  width: 112px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/12px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  width: 100%;
}

.bene_bank_no {
  width: 131px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/9px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  margin-left: 4px;
}

.bank_achived_main > p {
  margin-left: 30px;
  width: 102px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 4px;
}

.bene_new_message_text {
  width: 472px;
  height: 116px;
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
}
.bene_block_message_text {
  width: 472px;
  height: 85px;
  background: #c97b29 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.bene_block_message_text > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.bene_block_message_text > p {
  width: 368px;
  height: 44px;
  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.bene_new_message_text p > span {
  width: 232px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/19px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}

.Bank_new_message_text_block_nonarchive {
  width: 472px;
  height: 85px;
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px 30px;
}

.Bank_new_message_text_block_nonarchive > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.Bank_new_message_text_block_nonarchive > p {
  width: 368px;
  height: 44px;
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.bene_new_message_text_block {
  width: 472px;
  height: 112px;
  background: #c97b29 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px 30px;
}

.bene_new_message_text_block > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.bene_new_message_text_block > p {
  width: 368px;
  height: 83px;
  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.bene_new_message_text > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.bene_new_message_text > p {
  width: 388px;
  height: 76px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.bene_top_acc {
  width: 158px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  margin-left: 30px;
  margin-bottom: 4px;
  position: relative;
}

.bene_top_acc > img {
  position: absolute;
  width: 10px;
  height: 10px;
  top: -3px;
  right: -9px;
  cursor: pointer;
}

.bene_swap_butt {
  margin-left: 20px;
  margin-bottom: 10px;
}

.bene_swap_butt_1 {
  width: 58px !important;
  height: 24px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: capitalize !important;
}

.bene_swap_butt_2 {
  width: 58px !important;
  height: 24px !important;
  margin-left: 10px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: capitalize !important;
}

.bank_viewProfile {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: inherit;
  position: relative;
}

.bank_viewProfile::after {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 1px;
  height: 48px;
  background-color: #dadada;
}

.bank_viewProfile > p {
  width: 39px;
  height: 9px;
  text-align: left;
  font: normal normal normal 7px/9px Open Sans;
  letter-spacing: 0px;
  color: #fefefe;
  opacity: 1;
  margin-top: 4px;
}

.bank_viewProfile > img {
  width: 25px;
  height: 25px;
  opacity: 1;
}

.bene_top_con {
  width: 390px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #e0e0e0;
  opacity: 1;
  margin-left: 30px;
  margin-bottom: 20px;
}

::-webkit-scrollbar {
  width: 9px;
  height: 10px !important;
}

::-webkit-scrollbar-thumb {
  width: 9px;
  height: 104px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

.bank_ass_bottom_editFlow {
  width: 472px;
  height: 115px;
  border-radius: 30px;
  padding-top: 20px;
  padding-left: 30px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 6;
}

.bank_ass_bottom_editFlow > p {
  font-size: 12px;
  color: #ffffff;
}

.bank_ass_arc {
  width: 412px !important;
  height: 48px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.bank_ass_arc > img {
  width: 25px;
  height: 25px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 50%;
  margin-right: 7px;
}

.bank_ass_arc:hover {
  background: #7bca8b 0% 0% no-repeat padding-box !important;
}

.bank_ass_bottom {
  width: 472px;
  height: 173px;
  border-radius: 30px;
  padding-top: 20px;
  padding-left: 30px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 6;
  padding-bottom: 20px;
}

.bene_plus_Add {
  margin-right: 2px;
  font-size: 28px;
  font-weight: 300;
  width: 31px;
  height: 46px;
}

.bank_ass_create {
  width: 412px !important;
  height: 48px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.bank_archive_btn {
  margin-right: 8px !important;
  width: 24px;
  height: 24px;
}

.bank_ass_bottom > p {
  font-size: 12px;
  color: #ffffff;
}

.bank_ass_next > p {
  font-size: 12px;
  color: #ffffff;
}

.bank_ass_next > img {
  width: 25px;
  height: 25px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 50%;
  margin-right: 7px;
}

.bank_ass_next {
  width: 412px !important;
  height: 48px !important;
  background: #63ba74 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.bank_bene_view_acc {
  width: 412px !important;
  height: 48px !important;
  background: #8696b5 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.bank_pan_in_bottom {
  margin-top: 30px;
  width: 471px;
  height: 84px;
  background: #bea542 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.bank_pan_in_bottom > p {
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
}

.bank_pan_in_bottom img {
  width: 24px;
  height: 24px;
  background: #bea542 0% 0% no-repeat padding-box;
  margin: 0px 20px;
}

.bank_pan_in_bottom2 {
  margin-top: 30px;
  width: 471px;
  height: 139px;
  background: #bea542 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
}
