.multiIt_error_swap_tab_left {
  width: 50%;
  height: 80px;
  background: 0% 0% no-repeat padding-box;
  border-radius: 30px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99;
}

.multiIt_error_swap_tab_right > h4 {
  width: 146px;
  height: 22px;
  color: var(---f9f9f9);
  text-align: center;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.multiIt_error_swap_tab_left > h4 {
  width: 146px;
  height: 22px;
  color: var(---f9f9f9);
  text-align: center;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.multiIt_error_swap_tab_right {
  width: 50%;
  height: 80px;
  background: 0% 0% no-repeat padding-box;
  border-radius: 0px 30px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99;
}

.multiItInner_error_msg {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  height: fit-content;
}

.multiItInner_error_msg_name {
  display: flex;
  justify-content: space-between;
  height: 32px;
  background: #dadada 0% 0% no-repeat padding-box;
  padding: 0px 30px;
  align-items: center;
}

.multiItInner_error_msg_name > h4 {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #1d1d1d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 317px;
}

.multiItInner_error_msg_name > h6 {
  text-align: right;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #1d1d1d;
  width: 317px;
}
.multiItInner_error_msg_nameMob {
  display: flex;
  justify-content: space-between;
  height: 30px;
  background: #dadada 0% 0% no-repeat padding-box;
  padding: 0px 15px;
  align-items: center;
}

.multiItInner_error_msg_nameMob > h4 {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #1d1d1d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 317px;
}

.multiItInner_error_msg_nameMob > h6 {
  text-align: right;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #1d1d1d;
  width: 317px;
}

.multiItInner_error_msg_title {
  width: 412px;
  text-align: left;
  padding: 10px 0px;
  margin: 0px 30px;
}

.multiItInner_error_msg_title > h4 {
  width: 100%;
  text-align: left;
  overflow-wrap: break-word;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #1d1d1d;
  opacity: 1;
}

.multiItInner_error_msg_title > p {
  margin-top: 4px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}
