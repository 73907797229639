.internalTxn-card {
  /* Internal transaction card */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 328px;
  height: 77px;

  border-radius: 10px;
  cursor: pointer;
  background-color: #244277;
  position: relative;
}

.card_hover_overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 77px;
  border-radius: 10px;
}

.internalTxn-card:hover > .card_hover_overlay {
  display: block;
  background-color: #f9f9f9;
  opacity: 0.2;
  z-index: 9;
}

.internalTxn-card-first {
  /* Frame 11340 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 79px;

  width: 328px;
  height: 39px;

  background: #3a5686;
  border-radius: 10px 10px 0px 0px;
}

.internalTxn-card-second {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 328px;
  height: 38px;

  background: #314d7e;
  border-radius: 0px 0px 10px 10px;
}

.internalTxn-card-first h5 {
  /* ₹ 789,04,24,523.12 */

  width: auto;
  height: 19px;

  /* Web Font/14 Semibold */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #4ddd37;
}

.internalTxn-card-first p {
  /* 27 Nov 2020 */

  width: 59px;
  height: 14px;

  /* Web Font/10 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  /* identical to box height */

  color: #dadada;
}

.internalTxn-card-second p {
  /* •••• 2389 */

  width: 53px;
  height: 16px;

  /* Web Font/12 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  color: #f9f9f9;
}

.internalTxn-card-second .beneBank-img {
  /* Frame 11332 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: 66px;
  height: 38px;

  background: #f9f9f9;
  border-radius: 0px 0px 0px 10px;
}

.internalTxn-card-second .ownerBank-img {
  /* Frame 11332 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: 66px;
  height: 38px;

  background: #f9f9f9;
  border-radius: 0px 0px 10px 0px;
}

.internalTxn-card-second .acc-nos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 30px;

  width: 176px;
  height: 16px;
}

.internalTxn-card-second .acc-nos img {
  /* Path 10 */
  width: 12px;
  height: 12px;
}

@media (min-width: 800px) and (max-width: 1024px) {
  .internal-GridContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .internalTxn-card {
    width: 90% !important;
  }

  .internalTxn-card-first {
    width: 100% !important;
  }

  .internalTxn-card-second {
    width: 100% !important;
  }
}
@media (max-width: 800px) {
  .internal-GridContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .internalTxn-card {
    width: 90% !important;
  }

  .internalTxn-card-first {
    width: 100% !important;
  }

  .internalTxn-card-second {
    width: 100% !important;
  }
}
