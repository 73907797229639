.div-fixed-setting-gst {
  height: fit-content;
  background: #3a5686 0% 0% no-repeat padding-box;
}

.profile-setting {
  height: 30%;
  background-color: #3a5686;
  max-width: 1024px;
  margin: auto;
  /* padding-top: 75px; */
}

.div-auto-setting-gst {
  flex-grow: 1;
  background: #1d3a6d 0% 0% no-repeat padding-box;
  width: 100%;
}

.location-div {
  height: auto;
  background-color: #1d3a6d;
  max-width: 1024px;
  margin: auto;
  margin-top: 15px;
}

.gst-location {
  width: 805px;
  height: 260px;
  background: #314d7e 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  border-radius: 20px;
  opacity: 0.8;
  padding: 18px;
  margin-bottom: 15px;
}

.gst-main-div {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.main-div::-webkit-scrollbar {
  width: 7px;
}

.main-div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.main-div::-webkit-scrollbar-thumb {
  background-color: #fafafa;
  outline: 1px solid #314d7e;
  border-radius: 25px;
  height: 10px;
}

.main-div::-webkit-scrollbar-thumb:hover {
  background-color: #fafafa;
}

.parent-div {
  display: flex;
}

.gst-corp-divide-one {
  flex: 60%;
}

.gst-corp-divide-two {
  flex: 40%;
}

.gst-title-label-block {
  display: flex;
  flex-direction: row;

  margin-bottom: 20px;
}

.gst-corp-sub-name {
  width: 380px;
  height: 22px;
  text-align: left;

  font: normal normal normal 16px/20px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.gst-geo-map-pad {
  margin-left: 40px;
}

.gst-container {
  width: 100%;
  background-color: #3a5686;
  height: 30%;
  min-height: 15%;
  margin: auto;
}

.gst-acc-section {
  height: 30%;
  background-color: #3a5686;
  max-width: 1024px;
  margin: auto;
  display: flex;
}

.gst-major-div {
  width: 800px;
  margin-top: 24px;
}

.gst-individual-person-detail {
  margin-top: 6.5px;
  display: flex;
  border-bottom: 2px solid #1d3a6d;
}

.gst-individual-name {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 1px;
}

.gst-account-name {
  color: #ffffff;
  font-size: 1rem;
  opacity: 1;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: lowercase !important;
}

.gst-account-name {
  text-transform: lowercase !important;
}

.gst-account-name::first-line {
  text-transform: capitalize !important;
}

.gst-acc-name-label {
  color: #dadada;
  opacity: 1;
  font-size: 0.75rem;
}

.gst-acc-detail {
  padding: 20px;
  width: 50%;
}

.gst-acc-content {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  border-left: 2px solid #1d3a6d;
  height: 47px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}

.gst-account-status-div {
  font-size: 0.875rem;
  opacity: 1;
  font-weight: 600;
  text-transform: capitalize;
}

.gst-account-holder-details {
  border-bottom: 2px solid #1d3a6d;
  height: 90px;
  display: flex;
}

.gst-account-holder-details-no-bot {
  height: 90px;
  display: flex;
}

.gst-acc-number-label {
  color: #dadada;
  opacity: 1;
  font-size: 0.75rem;
}

.gst-bank-code {
  padding: 20px;

  width: 50%;
}

.gst-div-main {
  padding: 20px;
  padding-top: 25px;
  width: 50%;
}

.gst-div-content {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  border-left: 2px solid #1d3a6d;
  height: 47px;
  width: 160px;

  display: flex;

  flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
}

.gst-code-element {
  color: #dadada;
  opacity: 1;
  font-size: 0.75rem;
  margin-left: 22px;
  margin-top: -22px;
  font-size: 0.8rem;
  color: #c9c9c9;
  padding-bottom: 10px;
  width: 100%;
}

.gst-minor-div {
  width: 224px;
  margin-top: 29px;
}

.gst-recipient {
  height: 90px;
  border-bottom: 2px solid #1d3a6d;
  display: flex;
}

.gst-recipient-img {
  width: 35px;
  height: 35px;
}

@media (max-width: 768px) {
  .gst-acc-section {
    flex-direction: column;
  }

  .gst-individual-person-detail {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .gst-container {
    margin: auto;
  }
}

.gst-verification {
  cursor: auto;
}

.gst-acc-content-pan {
  width: 144px;
  height: 60px;
  border-radius: 10px;
}
