.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
  outline: none;
}

.h-custom {
  height: 511px !important;
}

@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.mobile_view {
  display: none;
}

@media (max-width: 768px) {

  /* .web_view {
    display: none;
  } */
  .mobile_view {
    display: block;
  }
}

.flex-container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 450px) {
  .flex-container {
    flex-direction: column;
  }
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.centerm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  width: 100%;
}

.btncolor {
  background-color: #716f70 !important;
  border-radius: 28px;
  text-transform: none !important;
  font-size: 16px !important;
  color: #d4d4d4 !important;
  line-height: 2.5rem !important;
  margin-top: 70px;
}

.btncolor.filled {
  background-color: #69b76f !important;
  color: white !important;
}

.loginlogo {
  width: 50%;
  margin: 0 auto 2rem;
}

.forgotpass {
  text-decoration: underline;
  font: normal normal 600 14px/20px Open Sans;
  color: #3a69b2;
}

* {
  outline: none;
  box-sizing: border-box;
}

.wrapper {
  background: #fff;
  padding: 48px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  height: 518px;
  margin-top: -2px;
}

.multi_wrapper {
  background: #fff;
  padding: 40px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  height: 513px;
}

.wrapper .input-data {
  height: 37px;
  width: 100%;
  position: relative;
}

.wrapper .input-data input {
  height: 100%;
  width: 100%;
  border: none;
  font-size: 20px;
  color: #313131;
  border-bottom: 2px solid #a0a0a0;
  outline: none;
}

.wrapper .input-data input[type="password"] {
  height: 100%;
  width: 100%;
  border: none;
  font-size: 10px;
  color: #313131;
  border-bottom: 2px solid #a0a0a0;
}

.wrapperm {
  background: #fff;
  padding: 48px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  height: 100%;
  margin-top: -2px;
}

.multi_wrapperm {
  background: #fff;
  padding: 40px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  height: 100%;
}

.wrapperm .input-data {
  height: 37px;
  width: 100%;
  position: relative;
}

.wrapperm .input-data input {
  height: 100%;
  width: 100%;
  border: none;
  font-size: 20px;
  color: #313131;
  border-bottom: 2px solid #a0a0a0;
  outline: none;
}

.wrapperm .input-data input[type="password"] {
  height: 100%;
  width: 100%;
  border: none;
  font-size: 10px;
  color: #313131;
  border-bottom: 2px solid #a0a0a0;
}

.input-data input:valid~label {
  transform: translateY(-20px);
  font-size: 14px;
  font-weight: normal;
}

.input-data input:focus~label {
  transform: translateY(-20px);
  font-size: 14px;
  font-weight: normal;
  color: #2273ef;
}

.multi_entity_text {
  width: 404px;
  height: 38px;
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #313131;
  padding-left: 40px;
}

.multi_entity_company_img {
  width: 30px;
  height: 30px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 20px;
}

.multi_entity_company_name {
  width: auto;
  max-width: 300px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
}

.multi_entity_name {
  width: auto;
  max-width: 300px;
  height: 14px;
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  padding: 3px 1px;
}

.multi_entity_div {
  max-height: 250px;
  height: 210px;
  overflow-y: scroll;
  margin-top: 15px;
  padding: 0px 40px 5px;
}

.multi_entity_div::-webkit-scrollbar-thumb {
  background: #868686 0% 0% no-repeat padding-box;
}

.multi_entity_div::-webkit-scrollbar-track {
  background-color: transparent;
}

.multi_entity_card {
  width: 380px;
  height: 57px;
  box-shadow: 0px 5px 11px #00000029;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  margin-top: 10px;
  gap: 20px;
  cursor: pointer;
}

.multi_entity_card:hover {
  opacity: 0.8;
}

.wrapper .input-data label {
  position: absolute;
  bottom: 7px;
  left: 0;
  color: #313131;
  pointer-events: none;
  transition: all 0.3s ease;
  font-weight: 600;
}

.wrapperm .input-data label {
  position: absolute;
  bottom: 7px;
  left: 0;
  color: #313131;
  pointer-events: none;
  transition: all 0.3s ease;
  font-weight: 600;
}

.input-data .underline {
  position: absolute;
  height: 1.5px;
  width: 100%;
  bottom: 0;
}

.input-data .underline:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.input-data .errorline:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #ff0001;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.input-data input:valid~.underline:before {
  transform: scaleX(1);
}

.input-data input:focus~.underline:before {
  transform: scaleX(1);
  background: #2273ef;
}

.half-border-width::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 57.1%;
  z-index: 1;
}

.mobile-view-login {
  margin-left: 0px !important;
}