.setting_main_1024_div {
  width: 1024px;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting_enterpriseData {
  height: 23.5625rem; /* 377px / 16 */
  width: 19.21875rem; /* 307.5px / 16 */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting_enterpriseData > h4 {
  width: 6.6875rem; /* 107px / 16 */
  height: 1.375rem; /* 22px / 16 */
  text-align: left;
  font: normal normal 600 1rem/1.375rem Open Sans; /* 16px/22px */
  letter-spacing: 0px;
  color: #f05a6a;
  text-transform: uppercase;
  opacity: 1;
  white-space: nowrap;
  margin-bottom: 1.25rem; /* 20px / 16 */
}

.setting_verification {
  height: 23.5625rem; /* 377px / 16 */
  width: 19.21875rem; /* 307.5px / 16 */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting_verification > h4 {
  width: 6.6875rem; /* 107px / 16 */
  height: 1.375rem; /* 22px / 16 */
  white-space: nowrap;
  margin-bottom: 1.25rem; /* 20px / 16 */
  text-align: left;
  font: normal normal 600 1rem/1.375rem Open Sans; /* 16px/22px */
  letter-spacing: 0px;
  color: #f05a6a;
  text-transform: uppercase;
  opacity: 1;
}

.setting_img {
  height: 23.5625rem; /* 377px / 16 */
  width: 25.625rem; /* 410px / 16 */
}

.setting_img > img {
  object-fit: contain;
}

.setting_top_main_div {
}

.inner_setting_enterpriseData {
  width: 14.1875rem; /* 227px / 16 */
  height: fit-content;
}

.inner_setting_enterpriseData_1 {
  width: 100% !important;
  height: 2.8125rem !important; /* 45px / 16 */
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-radius: 0px !important;
  padding: 0 !important;
  margin: 0 !important;
  text-transform: capitalize !important;
}

.inner_setting_enterpriseData_1 > img {
  width: 1.25rem; /* 20px / 16 */
  height: 1.25rem; /* 20px / 16 */
}

.inner_setting_verification {
  width: 14.1875rem; /* 227px / 16 */
  height: fit-content;
}

.inner_setting_verification_1 {
  width: 100% !important;
  height: 2.8125rem !important; /* 45px / 16 */
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-radius: 0px !important;
  padding: 0 !important;
  margin: 0 !important;
  text-transform: capitalize !important;
}

.inner_setting_verification_1 > img {
  width: 1.25rem; /* 20px / 16 */
  height: 1.25rem; /* 20px / 16 */
}

.inner_setting_enterpriseData_1_left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner_setting_enterpriseData_1_left > img {
  width: 1.4375rem; /* 23px / 16 */
  height: 1.4375rem; /* 23px / 16 */
  margin-right: 0.625rem; /* 10px / 16 */
}

.inner_setting_enterpriseData_1_left > h6 {
  width: fit-content;
  height: 1.1875rem; /* 19px / 16 */
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans; /* 14px/19px */
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
