.buttonsfordetails {
  display: flex;
  padding-top: 30px;
}

.DetailsButton {
  background-color: #373f4b;
  border-radius: 18px;
}

.AdditionalDetailsButton {
  background-color: #373f4b;
  border-radius: 18px;
}

.DetailsButton.effective,
.AdditionalDetailsButton.effective {
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.bene-detail-loader-box {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.calculatedFeeSeparation {
  border: 1px dashed #bcbbbb;
}

.beneTxnDetailsPage {
  width: 100%;
  background-color: #3a5686;
  height: auto;
}

.bene-txn-account-primary-details {
  height: 30%;
  background-color: #3a5686;
  max-width: 1024px;
  margin: auto;
  display: flex;
}

.custom-progress::-webkit-progress-bar {
  background-color: #102c5d;
  border-radius: 15px;
  box-shadow: inset 0px 3px 6px #00000029;
}

.progress-require-approval::-webkit-progress-bar {
  background-color: #102c5d;
  border-radius: 15px;
}

.progress-require-approval::-webkit-progress-value {
  background-color: #69b76f;
  border-radius: 15px;
}

.progress-rejected-approval::-webkit-progress-bar {
  background-color: #102c5d;
  border-radius: 15px;
}

.progress-rejected-approval::-webkit-progress-value {
  background-color: #ff5860;
  border-radius: 15px;
}

.progress-abandoned-transaction::-webkit-progress-bar {
  background-color: #102c5d;
  border-radius: 15px;
}

.progress-abandoned-transaction::-webkit-progress-value {
  background-color: #b4b4b4;
  border-radius: 15px;
}

.individual-details {
  border-bottom: 2px solid #1d3a6d;
}

.make-first-letter-small::first-letter {
  text-transform: lowercase !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

progress {
  background-color: transparent;
}

.progress-require-approval::-moz-progress-bar {
  background-color: #69b76f;
  border-radius: 15px;
}

.progress-rejected-approval::-moz-progress-bar {
  background-color: #ff5860;
  border-radius: 15px;
}

.progress-abandoned-transaction::-moz-progress-value {
  background-color: #b4b4b4;
  border-radius: 15px;
}

.approval-data,
.distribution-data {
  animation: lazyLoadOpen 0.5s ease-out alternate;
}

@keyframes lazyLoadOpen {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.detail-section-trans {
  animation: lazyLoadDetails 0.4s ease-out alternate;
}

@keyframes lazyLoadDetails {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.beneTxnDetailsMob {
  display: none;
}

@media screen and (max-width: 1024px) {
  .beneTxnDetailsPage {
    display: none;
  }

  .beneTxnDetailsMob {
    height: 100vh;
    overflow: hidden scroll;
    display: block;
    margin-bottom: 105px;
    scrollbar-width: none;
  }

  .buttonsfordetails {
    padding-top: 0;
  }

  .DetailsButton,
  .AdditionalDetailsButton {
    border-radius: 0;
    background-color: #314d7e;
  }

  .approving-toast-beneTxn {
    justify-content: start !important;
    /* padding: 30px 20px; */
  }
}
