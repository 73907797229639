.cin_details_din {
  width: 100%;
  height: 11.3125rem;
  margin-top: 13.125rem;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0.3125rem 0.3125rem 0.6875rem #00000026;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cin_details_slide_con_cap_din {
  width: 20.5rem;
  height: 14.9375rem;
  background: #314d7e 0% 0% no-repeat padding-box;
  box-shadow: -0.3125rem -0.3125rem 0.6875rem #71717133;
  border-radius: 1.25rem;
  opacity: 0.8;
  margin-right: 1.25rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cin_details_slide_con_cap_din > h4 {
  text-align: left;
  font: normal normal 600 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.cin_details_slide_con_cap_date {
  padding: 0.625rem 0px;
}

.cin_details_slide_con_cap_date > h5 {
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.cin_details_slide_con_cap_date > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.0625rem Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.cin_details_1_right_din {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 41.5625rem;
}
.cin_details_1_right_cin_no {
  width: 360px;
}

.cin_details_1_right_cin_no_din > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.5625rem Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.cin_details_1_right_cin_no_din > h4 {
  text-align: left;
  font: normal normal 600 1rem/1.375rem Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}
