.user-card-main-div {
  width: 100%;
  height: auto;
}

.user-first-div {
  height: 200px;
  background: #314c7f 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  opacity: 1;
  display: flex;
}

.user-count-div {
  max-width: 1253px !important;
  margin: 0 auto;
}

.user-total-board {
  background: #314c7f 0% 0% no-repeat padding-box;
}

.part-one {
  flex: 28vw;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.part-two {
  flex: 72vw;
  box-shadow: 0px 3px 6px #00000029;
}

.view-all-user {
  font-family: Open Sans, Semibold;
  font-size: 20px;
  height: 28px;
  text-align: right;
  color: #fff;
}

.No-of-User {
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 100px;
  margin-top: 110px;
}

.user-total-count-symbol {
  transform: matrix(0, -1, 1, 0, 0, 0);
  font-style: italic;
  color: #ffffff;
  margin-right: 4px;
  font-size: 1.125rem;
}

.user-count {
  font-size: 12px;
  margin-bottom: 10px;
  letter-spacing: 0px;
  color: #dadada;
  font-style: Open, Sans;
  margin-right: 80px;
}

.snak-bar {
  display: flex !important;
  justify-content: space-between;
  width: 90% !important;
  margin: 0 auto;
  padding: 209px 26px 10px 8px;
}

.create-user {
  width: 136px;
  height: 36px;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 28px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.crt-icon {
  font-size: 30px;
  color: #1d3a6d;
  opacity: 1;
  margin-right: 8px;
}

.crt-label {
  width: auto;
  height: 17px;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #1d3a6d;
  opacity: 1;
}

.user-second-div {
  max-width: 1253px;
  margin: 0 auto;
  background: #1d3a6d 0% 0% no-repeat padding-box;
}

.infinityScrollContainerUserSettings {
  display: flex !important;
  flex-wrap: wrap;
  height: auto;
  overflow-y: auto;
  margin: 0 auto;
  background: #1d3a6d 0% 0% no-repeat padding-box;
}

.card-viewer {
  max-height: 464px;
}

.card-viewer-data {
  display: flex !important;
  flex-wrap: wrap;
  margin: 0 auto;
}

.user-details-card {
  width: 260px;
  height: 98px !important;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 20px !important;
  box-shadow: -5px -5px 11px #71717133 !important;
  grid-column: span 1;
  box-sizing: border-box;
  padding: 5px !important;
  margin: 10px 5px;
  padding-right: 1px !important;
  cursor: pointer;
}

.user-name {
  width: 230px;
  height: 19px;
  flex-wrap: wrap;
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  padding-top: 8px;
  padding-left: 8px;
}

.user-id {
  width: 230px;
  height: 17px;
  flex-wrap: wrap;
  text-align: left;
  font: normal normal normal 12px/26px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  padding-top: 14px;
  padding-left: 8px;
  margin-top: 5px;
}

.user-label {
  width: 230px;
  height: 14px;
  text-align: left;
  flex-wrap: wrap;
  font: normal normal 600 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #b0bbce;
  opacity: 1;
  padding-top: 20px;
  padding-left: 8px;
  margin-top: 5px;
}

.loader-user-viewall {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-user-viewall img {
  max-width: 100%;
  height: auto;
}

.user-not-found {
  color: #ffffff;
  opacity: 1;
  font-size: 1rem;
  text-align: center;
}

#infinityScrollContainerUserView {
  display: flex !important;
  flex-direction: column !important;
  overflow: auto;
  position: relative;
  height: 100%;
}

.UserCard {
  left: 5rem;
  padding-top: 0px;
}

.user-details-card:hover {
  background-color: #506994 !important;
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .UserCard {
    left: 6rem;
  }
}

@media screen and (min-width: 2560px) and (min-height: 1440px) {
  .UserCard {
    left: 8rem;
  }
}

@media screen and (min-width: 3840px) and (min-height: 1920px) {
  .UserCard {
    left: 12rem;
  }
}

.UserCard {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-bottom: 15px;
  padding-top: 15px;
  width: 90% !important;
}

.user-div-container {
  background-color: #314c7f;
  background-color: #314c7f;
  margin-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.user-gradient {
  height: 125px;
  position: absolute;
  background-color: #506994;
  top: 15px;
}

.user-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  height: 140px;
  position: relative;
  align-items: center;
}

.user-acc-viewall {
  font-family: Open Sans, Semibold;
  font-size: 20px;
  height: 28px;
  text-align: right;
  color: #fff;
}

.No-of-user-accounts {
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 12px;
  margin-top: 21px;
}

.user-acc-total-count-symbol {
  transform: matrix(0, -1, 1, 0, 0, 0);
  font-style: italic;
  color: #ffffff;
  margin-right: 4px;
  font-size: 1.125rem;
}

.view-count-user {
  font-size: 12px;
  margin-bottom: 10px;
  letter-spacing: 0px;
  color: #dadada;
  font-style: Open, Sans;
}

.search-bar-hover {
  z-index: 999;
}
