.cin_details_1_left_pan {
  width: 22.4375rem;
  position: relative;
}

.cin_details_pan {
  width: 100%;
  /* height: 5.625rem; */
  /* margin-top: 13.125rem; */
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0.3125rem 0.3125rem 0.6875rem #00000026;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cin_details_1_right_pan {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 41.5625rem;
}

.cin_details_1_left_pan > h4 {
  text-align: left;
  font: normal normal 600 1rem/1.375rem Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.cin_details_1_left_pan > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.5625rem Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.pan_gst_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
}

.pan_gst_div > img {
  width: 15.625rem;
  height: 12.25rem;
}

.pan_gst_div > h5 {
  text-align: center;
  font: normal normal normal 1rem/1.375rem Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.pan_gst_have {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: fit-content;
}

.pan_gst_have_1:hover {
  background: #4c6a9e 0% 0% no-repeat padding-box;
}

.pan_gst_have_1 {
  width: 20.5rem;
  height: 5rem;
  background: #314d7e 0% 0% no-repeat padding-box;
  box-shadow: -0.3125rem -0.3125rem 0.6875rem #71717133;
  border-radius: 1.25rem;
  opacity: 1;
  padding: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.25rem;
  margin-right: 20px;
  cursor: pointer;
}

.pan_gst_have_1 > img {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.pan_gst_have_1_details > h5 {
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  margin-bottom: 0.25rem;
}

.pan_gst_have_1_details > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.5625rem Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.cin_details_1_pan {
  display: flex;
  width: 64rem;
  justify-content: space-between;
  align-items: center;
  height: 5.625rem;
}
