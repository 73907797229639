.benePan_Main {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.benePan_Main_responsive {
  width: 100%;
  scroll-snap-align: start;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2em;
}

.dividepan {
  width: 472px;
  height: auto;
  margin: 0px 20px;
}

.dividepan-responsive {
  width: 100%;
  height: auto;
}

.crt-pan_Enter {
  height: 180px;
  border-radius: 30px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  transition: all 0.1s ease;
}

.pan_Input {
  background-color: #3a5686;
  height: 129px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0px !important;
  /* box-shadow: -5px -5px 11px #71717133; */
  /* box-shadow: 0px 4px 4px 0px #1D1D1D33; */
  width: 100%;
}

.pan_Input_responsive {
  background-color: #3a5686;
  height: 129px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0px !important;
  /* box-shadow: -5px -5px 11px #71717133; */
  /* box-shadow: 0px 4px 4px 0px #1D1D1D33; */
  width: 100%;
}

.gst_Input {
  background-color: #3a5686;
  height: 129px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;

  align-items: center;
  box-shadow: -5px -5px 11px #71717133;
  width: 100%;
}

.gst_det {
  display: flex;
  flex-direction: column;
}

.contain_det {
  display: flex;
  flex-direction: column;
}

.contain_det > p {
  color: #dadada;
  font: normal normal 600 16px/22px Open Sans;
  margin: 20px 0px;
}

.gst_det > p {
  color: #dadada;
  font: normal normal 600 16px/22px Open Sans;
  margin: 20px 0px;
}

.input_Field {
  border: 1px solid #dadada;
  border-radius: 10px;
  position: relative;
  transition: all 0.1s ease;
}

.input_Field > img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 28%;
  right: 4%;
}

.pan_gst_name_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 0 0;
  padding: 0 20px;
}

.gst_name_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 0 0;
  padding: 0 20px;
}

.pan_gst_span {
  width: 412px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-12) /
    var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---f9f9f9);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-top: 5px;
}

.gst_span {
  width: 369px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-12) /
    var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---f9f9f9);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-top: 5px;
}

.gst_span_responsive {
  width: 250px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-12) /
    var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---f9f9f9);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-top: 5px;
}

.pan_gst_span_div {
  margin: -5px 53px;
  padding-bottom: 20px;
  padding-top: 2px;
}

.gst_span_div {
  margin: -5px 53px;
  padding-bottom: 11px;
  padding-top: 2px;
}

.bene_top_account-new-responsive {
  width: 170px;
  height: 22px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.79px;
  color: #dadada;
  margin-left: 30px;
  margin-bottom: 4px;
  position: relative;
}

.bene_top_account-new-responsive > img {
  position: absolute;
  width: 11px;
  height: 11px;
  top: -3px;
  right: -1px;
  cursor: pointer;
  margin-left: 5px;
}

.bene_top_account {
  width: auto !important;
  height: 22px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    var(--unnamed-font-size-16) / var(--unnamed-line-spacing-22)
    var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #dadada !important;
  opacity: 1;
  margin-left: 30px;
  margin-bottom: 4px;
  display: flex;
}

.associate-panacc-info-container {
  width: 260px;
  height: 125px;
  padding: 10px;
  background: var(---506994) 0% 0% no-repeat padding-box;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  position: relative;
  left: 0;
  top: 0;
  z-index: 1 !important;
}

.associate-panacc-info-container-responsive {
  width: 290px;
  height: 105px;
  padding: 10px;
  background: var(---506994) 0% 0% no-repeat padding-box;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  position: relative;
  left: 10px;
  top: -6px;
  z-index: 1 !important;
}

.associate-panacc-info {
  height: 85px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-12) /
    var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.merge_name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: #ffffff;
}

.merge_gst_name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ffffff;
}

.pan_gst_name {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  margin: 0 0 0 -10px;
  text-wrap: wrap;
  word-wrap: break-word;
  flex: 1;
  color: #F9F9F9;
  color: #F9F9F9;
}

.pan_gst_name_responsive {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  margin: 0 0 0 -10px;
  text-wrap: nowrap;
  /* word-wrap: break-word; */
  flex: 1;
}

.gst_name {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0 -10px;
  text-wrap: wrap;
  word-wrap: break-word;
  flex: 1;
}

.merge_name > img {
  width: 20px;
  height: 20px;
  margin-right: 22.86px;
}

.merge_gst_name > img {
  width: 20px;
  height: 20px;
  margin-right: 22.86px;
}

.input_Field > label {
  position: absolute;
  color: #dadada;
  margin-left: 15px;
  top: 8px;
  transition: all 0.1s ease;
  background-color: #3a5686;
  padding: 0px 3px;
  cursor: text;
  z-index: 2;
}

.input_Box {
  width: 412px;
  height: 42px;
  background-color: transparent;
  color: #ffffff;
  padding: 15px;
  outline: none;
}

@media screen and (max-width: 800px) {
  .input_Box {
    width: 350px;
    background-color: #63ba74;
  }
}
.crt-pan_Details {
  width: 472px;
  border-radius: 30px;
  height: auto;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.ass_top {
  width: 472px;
  height: 329px;
}

.ass_top-responsive {
  width: 100%;
  height: 329px;
}

.achived_main {
  width: 100%;
}

.hidden_accounts {
  margin-left: 20px;
  /* margin-top: 20px; */
}

.hidden_accounts > p {
  width: 375px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.hidden_accounts p > span {
  width: 259px;
  height: 19px;
  text-align: left;
  font: normal normal 600 14px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.scroll_All_approval {
  overflow-y: scroll;
  overflow-y: scroll;
  /* padding-bottom: 20px; */
  overflow-y: scroll;
  /* padding-bottom: 20px; */
}

.achived_scroll {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 28px;
}

.achived_scroll-responsive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  padding-right: 5px;
}

.child_scroll {
  width: 412px !important;
  height: 68px !important;
  border-radius: 20px !important;
  display: flex !important;
  justify-content: flex-start !important;
  padding: 0px !important;
  margin: 0px !important;
  position: relative !important;
  align-items: flex-start !important;
  margin-bottom: 10px !important;
  text-transform: none !important;
}

.child_scroll_responsive {
  width: auto !important;
  height: 78px !important;
  border-radius: 20px !important;
  display: flex !important;
  justify-content: flex-start !important;
  padding: 0px !important;
  margin: 0px !important;
  position: relative !important;
  align-items: flex-start !important;
  margin-bottom: 10px !important;
  text-transform: none !important;
}

.child_scroll_responsive::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: px;
  background-color: black;
}

/* .child_scroll_responsive:hover {
  background: #c00895 0% 0% no-repeat padding-box !important;
} */

.child_scroll::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: px;
  background-color: black;
}

.child_scroll:hover {
  background: #506994 0% 0% no-repeat padding-box !important;
}

.bene_view_acc:hover {
  background: #fefefe 0% 0% no-repeat padding-box !important;
  color: #1d3a6d !important;
}

.bank_name {
  width: 73px;
  height: 68px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 18px 0px 0px 18px;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bank_name-responsive {
  width: 73px;
  height: 78px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 18px 0px 0px 18px;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bank_name-responsive > img {
  width: 58px;
  height: 15px;
  opacity: 1;
}

.bank_name > img {
  width: 58px;
  height: 15px;
  opacity: 1;
}

.viewProfile {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: inherit;
  position: relative;
}

.viewProfile_responsive {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.viewProfile::after {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 1px;
  height: 48px;
  background-color: #dadada;
}

.viewProfile_responsive::after {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 1px;
  height: 48px;
  background-color: #dadada;
}
/* .viewProfile::after {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 1px;
  height: 48px;
  background-color: #dadada;
} */

.viewProfile > p {
  width: 39px;
  height: 9px;
  text-align: left;
  font: normal normal normal 7px/9px Open Sans;
  letter-spacing: 0px;
  color: #fefefe;
  opacity: 1;
  margin-top: 4px;
}

.viewProfile_responsive > p {
  width: 39px;
  height: 9px;
  text-align: left;
  font: normal normal normal 7px/9px Open Sans;
  letter-spacing: 0px;
  color: #fefefe;
  opacity: 1;
  margin-top: 4px;
}

.viewProfile > img {
  width: 25px;
  height: 25px;
  opacity: 1;
}

.viewProfile_responsive > img {
  width: 25px;
  height: 25px;
  opacity: 1;
}

.client_name {
  height: fit-content;
  margin-top: 13px !important;
  margin-left: 83px !important;
}

.ass_create:hover {
  background: #fefefe 0% 0% no-repeat padding-box !important;
  color: #1d3a6d !important;
}

.name_client2 {
  width: 253px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/15px Open Sans;
  letter-spacing: 0px;
  color: #b4b4b4;
  opacity: 1;
  margin-top: 3px;
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.name_client2-responsive {
  width: 200px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/15px Open Sans;
  letter-spacing: 0px;
  color: #b4b4b4;
  opacity: 1;
  margin-top: 3px;
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

@media (max-width: 385px) {
  .name_client2-responsive {
    width: 160px;
  }
}

.bank_client2 {
  height: 17px;
  height: 17px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/12px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  width: 232px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bank_client2-responsive {
  height: 30px;
  text-align: left;
  font: normal normal normal 12px/12px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.bank_no {
  width: 131px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/9px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-left: 4px;
}

.bank_no_responsive {
  width: 131px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/9px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  /* margin-left: 4px; */
  margin-top: 5px;
}

.achived_main > p {
  margin-left: 30px;
  width: 102px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9F9F9;
  opacity: 1;
  margin-bottom: 4px;
}

.top_acc {
  width: 158px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  margin-left: 30px;
  margin-bottom: 4px;
  position: relative;
}

.new_message_text {
  width: 472px;
  height: 116px;
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  margin-left: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
  padding-right: 25px;
}

.new_message_text p > span {
  width: 232px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/19px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 4px;
  display: inline-block;
}

.new_message_text_block_nonarchive {
  width: 472px;
  height: 85px;
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  margin-left: 20px;
  display: flex;
  align-items: center;
  padding: 20px 30px;
}

.new_message_text_block_nonarchive > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.new_message_text_block_nonarchive > p {
  width: 368px;
  height: 44px;
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.new_message_text_block {
  width: 472px;
  height: 85px;
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  margin-left: 20px;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  margin-bottom: 20px;
}

.new_message_text_block > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.new_message_text_block > p {
  width: 368px;
  height: 44px;
  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.new_message_text > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.new_message_text > p {
  width: 388px;
  height: 76px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.top_acc > img {
  position: absolute;
  width: 10px;
  height: 10px;
  top: -4px;
  right: 35px;
  cursor: pointer;
}

.swap_butt {
  margin-left: 30px;
  margin-bottom: 10px;
}

.swap_butt_1 {
  width: 58px !important;
  height: 24px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: capitalize !important;
}

.swap_butt_2 {
  width: 58px !important;
  height: 24px !important;
  margin-left: 10px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: capitalize !important;
}

.top_con {
  width: 412px;
  height: 17px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-12) /
    var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---f9f9f9);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-left: 30px;
}

.crt_top_con {
  width: 412px;
  height: 17px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-12) /
    var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---f9f9f9);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-left: 30px;
}

::-webkit-scrollbar {
  width: 9px;
  height: 10px !important;
}

::-webkit-scrollbar-thumb {
  width: 9px;
  height: 20px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  background-color: transparent;
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
  background-color: #f1f1f1;
  background-color: #f1f1f1;
}

.crt-ass_bottom {
  width: 472px;
  height: 140px;
  border-radius: 30px;
  padding-top: 22px;
  padding-left: 30px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 6;
  margin-top: 20px;
}

.crt-ass_bottom-reponsive {
  width: 100%;
  height: 140px;
  border-radius: 20px;
  padding-top: 22px;
  padding-left: 30px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 6;
  margin-top: 30px;
  padding-right: 20px;
}

.crt-ass_bottom_editFlow {
  width: 472px;
  height: 100px;
  border-radius: 30px;
  padding-top: 20px;
  padding-left: 30px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 6;
}

.ass_create {
  width: 412px !important;
  height: 48px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.ass_create > img {
  width: 25px;
  height: 25px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 50%;
  margin-right: 7px;
}

.ass_arc {
  width: 412px !important;
  height: 48px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.ass_arc-responsive {
  width: 100% !important;
  height: 48px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.ass_arc-responsive > img {
  width: 25px;
  height: 25px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 50%;
  margin-right: 7px;
}

.ass_arc > img {
  width: 25px;
  height: 25px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 50%;
  margin-right: 7px;
}

.ass_arc:hover {
  background: #7bca8b 0% 0% no-repeat padding-box !important;
}

.ass_next:hover {
  background: #7bca8b 0% 0% no-repeat padding-box !important;
}

.plus_Add {
  margin-right: 20px;
  font-size: 28px;
  font-weight: 300;
}

.crt-ass_bottom > p {
  font-size: 12px;
  color: #ffffff;
}

.crt-ass_bottom-reponsive > p {
  font-size: 12px;
  color: #ffffff;
}

.crt-ass_bottom_editFlow > p {
  font-size: 12px;
  color: #ffffff;
}

.ass_next > p {
  font-size: 12px;
  color: #ffffff;
}

.ass_next > img {
  width: 25px;
  height: 25px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 50%;
  margin-right: 7px;
}

.ass_next-responsive > img {
  width: 25px;
  height: 25px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 50%;
  margin-right: 7px;
}

.dividebank_responsive {
  width: 472px;
  margin: 0px 20px;
}

.bank_details_responsive {
  width: 100%;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;
  padding-bottom: 12px;
  padding-left: 30px;
}

.bank-no-assacc-found-responsive {
  /* width: 472px; */
  height: 105px;
  color: white;
}

.bank_hidden_accounts-responsive {
  margin-left: 30px;
  margin-top: 20px;
}

.bank_hidden_accounts-responsive div > span {
  width: 259px;
  height: 19px;
  text-align: left;
  font: normal normal 600 14px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.bank_hidden_accounts-responsive > div {
  width: 375px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

/* .dividebank bank_Details-responive dividebank {
  width: 100%;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;
  padding-bottom: 12px;
} */

.ass_next {
  width: 412px !important;
  height: 48px !important;
  background: #63ba74 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.ass_next-responsive {
  width: 100% !important;
  height: 48px !important;
  background: #63ba74 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
  margin-right: 10px;
}

.bene_view_acc {
  width: 412px !important;
  height: 48px !important;
  background: #8696b5 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.pan_in_bottom {
  margin-top: 30px;
  width: 471px;
  height: 84px;
  background: #bea542 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.pan_in_bottom > p {
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
}

.pan_in_bottom img {
  width: 24px;
  height: 24px;
  background: #bea542 0% 0% no-repeat padding-box;
  margin: 0px 20px;
}

.pan_in_bottom2 {
  margin-top: 30px;
  width: 471px;
  height: 139px;
  background: #bea542 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
}

.status_para {
  font-size: 16px;
  color: #ffffff;
  margin: 20px 56px 20px 0px;
}

.status_image {
  width: 24px;
  height: 24px;
  background: #bea542 0% 0% no-repeat padding-box;
  margin: 35px 23px 0px 24px;
}

.wrong_pan {
  display: flex;
  align-items: center;
  padding-top: 8px;
}

.wrong_pan > img {
  width: 25px;
  height: 25px;
  margin: 0px 13px 0px 23px;
}

.wrong_pan > span {
  font: normal normal normal 16px/16px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}

.look_loading_div {
  width: 472px;
  height: 520px;
  margin: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pan_in_bottom {
  margin-top: 30px;
  width: 471px;
  height: 84px;
  background: #bea542 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.pan_in_bottom > p {
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
}

.pan_in_bottom img {
  width: 24px;
  height: 24px;
  background: #bea542 0% 0% no-repeat padding-box;
  margin: 0px 20px;
}

.pan_in_bottom2 {
  margin-top: 30px;
  width: 471px;
  height: fit-content;
  background: #bea542 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
  align-items: flex-start;
}

.pan_in_bottom2 > p {
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
  margin: 20px 0px;
  margin-right: 56px;
}

.pan_in_bottom2 > img {
  width: 24px;
  height: 24px;
  background: #bea542 0% 0% no-repeat padding-box;
  margin: 35px 23px 0px 24px;
}

.wrong_pan {
  display: flex;
  align-items: center;
  padding-top: 8px;
}

.wrong_pan > img {
  width: 30px;
  height: 30px;
  margin: 0px 13px 0px 23px;
}

.wrong_pan > span {
  font: normal normal normal 16px/16px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}

.crt-pan_new_crt_acc_bottom {
  display: flex;
  align-items: center;
  width: 472px;
  height: 110px;
  border-radius: 20px;
  /* padding: 9px; */
  padding: 10px 10px 10px 20px;
  background: #3a5686;
}

.crt-pan_new_crt_acc_bottom-responsive {
  display: flex;
  /* width: 472x;
  height: 110px; */
  width: auto;
  height: auto;
  border-radius: 20px;
  padding: 9px;
  background: #3a5686;
}

.crt-gst-arrow-bg {
  width: 25px;
  height: 25px;
  background: var(---1d1d1d) 0% 0% no-repeat padding-box;
  background: #1d1d1d 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.crt-gst-arrow-bg:hover {
  background-color: #1d1d1d89;
  opacity: 0.6;
}

.crt-gst-close-btn:hover {
  background-color: #1d3a6d9b;
  opacity: 0.6;
}

.confirm-btn-style {
  font-weight: 400;
  font-size: 13px;
  line-height: 16.34px;
  color: #f9f9f9;
}

.crt-Confirm-sub-info {
  width: 343px;
  height: auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  color: #f9f9f9;
  opacity: 1;
  /* padding: 18px 0px 0px 9px; */
}

.crt-Confirm-sub-info-responsive {
  width: auto;
  height: auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  color: #f9f9f9;
  opacity: 1;
  padding: 18px 0px 0px 9px;
}

.crt-confirm-box{
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.crt-Confirm-info {
  width: 335px;
  height: 19px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.79px;
  color: #dadada;
  opacity: 1;
  /* padding: 10px 0px 0px 9px; */
}

.crt-Confirm-info-responsive {
  width: auto;
  height: auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.79px;
  color: #dadada;
  opacity: 1;
  padding: 10px 0px 0px 9px;
}

.pan_Details_crt {
  width: 472px;
  border-radius: 30px;
  min-height: 395px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.pan_Details_crt_responsive {
  width: auto;
  border-radius: 30px;
  min-height: 395px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;
}
