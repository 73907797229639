.internal-stepper-main {
    width: 1024px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.internal-main_Line {
    height: 7px;
    border-radius: 4px;
    background-color: #ffffff;
}

@keyframes growHeight {
    from {
        width: 100px;
    }

    to {
        width: 220px;
    }
}

.internal-growing-element {
    animation: growHeight 0.7s backwards;
}

.internal-stepperline_Div_biller {
    width: 772px;
    height: 59.85px;
    padding: 0px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}