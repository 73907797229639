.edit_contactDetails_main {
  max-width: 1024px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.main_edit_stepper_div {
  background-color: #314c7f;
  height: 164px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-shadow: 2px 2px 10px 0px rgba(26, 26, 26, 0.733);
  position: fixed;
  top: 0;
  z-index: 10;
  left: 0;
  width: 472px;
}

.edit_contactDetails {
  max-width: 1024px;
  padding-top: 186px;
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  display: flex;
  scroll-snap-type: x mandatory;
}
.dividecontactDetails {
  width: 472px;
  max-height: 400vh;
  padding-top: 186px;
  height: 100%;
}
