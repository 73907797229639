#searchComponent {
  width: 1024px;
  padding-left: 55px;
  padding-right: 35px;
  height: 36px;
}

#searchComponent:focus {
  padding-left: 55px;
  padding-right: 35px;
}

.searchComponent_inner_image_acc {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 10px;
  left: 20px;
}

.searchComponent_close_image_acc {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 10px;
}

.searchComponent-bar {
  display: flex;
  width: 100%;
  max-width: 1024px !important;
  margin: 0 auto;
  z-index: 1;
  flex-direction: row;
  border-top: 1px solid #1d3a6d;
  height: 56px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.searchComponent_bar_acc {
  width: 1024px;
  height: 36px;
  background: #244277 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  position: relative;
}

.search-amount-div_acc {
  width: 165px;
  height: 36px;
  background: #667ca3 0% 0% no-repeat padding-box;
  border-radius: 10px 0px 0px 10px;
  opacity: 1;
  font-size: 12px;
  color: #fafafa;
  padding: 12px;
}

.searchComponent_expand {
  animation: searchComponent_expandHeight 0.3s forwards;
}

.searchComponent_collapse {
  animation: searchComponent_collapseHeight 0.3s forwards;
}

@keyframes searchComponent_expandHeight {
  from {
    height: 0;
  }
  to {
    height: 56px;
  }
}

@keyframes searchComponent_collapseHeight {
  from {
    height: 56px;
  }
  to {
    height: 0;
  }
}

@media screen and (max-width: 800px) {
  .searchComponent_bar_acc {
    width: 95% !important;
  }
  #searchComponent {
    width: 100% !important;
  }
}
