.itMultiApprovalViewAllCard {
  display: flex;
  flex-wrap: wrap;
  width: 91%;
  margin: 0 auto;
  padding-bottom: 30px;
}

.itMultiSelectViewAllCard {
  border-radius: 10px !important;
  box-sizing: border-box;
  grid-column: span 1;
  height: 96px !important;
  margin: 10px;
  padding: 10px !important;
  width: 257px;
}

@media screen and (max-width: 3840px) and (min-width: 2560px) {
  .itMultiApprovalViewAllCard {
    width: 91%;
  }

  .itMultiSelectViewAllCard {
    width: 268px;
    cursor: pointer;
  }
}

@media screen and (max-width: 2400px) and (min-width: 1800px) {
  .itMultiSelectViewAllCard {
    width: 268px;
    cursor: pointer;
  }
}

.itViewAllAccountTitle {
  color: #b0bbce;
  font-size: 10px;

  font-weight: 600;
}

.itViewAllBankAcc {
  color: #fafafa;
  font-size: 14px;
  margin-bottom: 12px;
  width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: lowercase;
  height: 20.2px;
}

.itViewAllName {
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #4ddd37;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.itViewAllBankAcc:first-line {
  text-transform: capitalize !important;
}

.itViewAllUser {
  white-space: nowrap;
  margin-left: 10px;
  width: 14rem;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
}

.itViewAllStatus {
  width: 5px;
  height: 15px;
  background-color: #4ddd37;
  border-radius: 0px 5px 0px 0px;
  top: 0px;
  position: absolute;
  right: 0px;
}

.itViewAllDetails:hover .itViewAllAssigned {
  display: block;
}

.itViewAllDetails:hover .itViewAllStatus {
  transform: translateX(calc(100% -100%));
  right: auto;
  left: 0px;
}

.itViewAllAssigned {
  display: none;
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  padding-left: 10px;
  padding-right: 10px;
}

.itViewAllLabel {
  display: flex;
  height: 20px;
  right: 0px;
  position: absolute;
  bottom: 65px;
}

.css-wdslgk {
  padding-right: 0 !important;
}

@keyframes slideOutRightToLeft {
  0% {
    visibility: visible;
    transform: translateX(0);
  }

  100% {
    visibility: hidden;
    transform: translateX(-10%);
  }
}

.itViewAllDetails:hover .itViewAllName {
  visibility: hidden;
  animation: slideOutRightToLeft 0.2s ease-in-out forwards;
  cursor: pointer;
}

.moreDetailsContainer {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 10px 0px 10px 0px;
  height: 24px;
}
