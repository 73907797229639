.OAS-owner_div_container {
  background-color: #3a5686;
  margin-top: 60px;
  height: 110px;
  width: 100%;
  z-index: 998;
}

.OAS-owner_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  height: 115px;
  z-index: 1;
  position: relative;
  align-items: center;
}

.OAS-owner_div_height {
  transition: height 0.2s ease-in-out;
  height: 170px;
}

.OAS-owner_search_div {
  width: 50px;
  height: 30px;
  background-color: #244277;
  border-radius: 5px;
  padding: 8px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.OAS-filter-containers {
  height: 30px !important;
  width: 50px !important;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background: #244277;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 5px;
  opacity: 1;
}

.OAS-owner_search_div[data-lable="search"]:hover {
  background-color: #506994 !important;
}
.OAS-filter-containers:hover {
  background-color: #506994;
}

.OAS-filter-images {
  width: 14px !important;
  height: 14px;
}

.OAS-owner-viewall-page-select-div {
  height: 36px !important;
  width: 131px !important;
  cursor: pointer;
  display: flex;
  background: #244277;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 5px;
  opacity: 1;
}

.OAS-pagination-containers {
  height: 36px !important;
  width: 136px !important;
  cursor: pointer;
  display: flex;
  background: #244277;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 5px;
  opacity: 1;
}

.OAS-filter-transition-close {
  transition: all 0.2s ease-in;
  height: 0;
  visibility: hidden;
}

.OAS-filter-transition-open {
  visibility: visible;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  top: 90;
  position: absolute;
  background-color: #3a5686;
  height: auto;
  border-radius: 0 !important;
  width: 100%;
  z-index: 998;
  margin-top: -5px;
}

.OAS-beneTxnFilterSection1 {
  display: flex;
  flex-direction: column;
  height: 165px;
  padding-right: 30px;
}

.OAS-beneTxnFilterSection3 {
  display: flex;
  flex-direction: column;
  height: 225px;
  padding-right: 30px;
}

.OAS-bene_Txn_Calendar_main_div {
  border-radius: 10px;
  /* margin-left: 15px; */
}

.OAS-beneTxn_date_heading_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 35px;
  width: auto;
}

.OAS-beneTxn_date_heading_left {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  width: 100px;
  margin: 0 auto;
}

.OAS-bene_txn_viewall_calendar_icon {
  height: 17px;
  align-items: center;
  justify-content: center;
  width: 20px !important;
}

.OAS-beneTxn_date_heading_right {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  width: 100px;
  margin: 0 auto;
}

.OAS-beneTxn_date_select_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 35px;
  width: auto;
}

.OAS-filter_applied_div {
  height: 60px;
  background-color: #3a5686;

  /* margin-top: 200px; */
}

.OAS-beneTxnviewallsidebar.closed {
  transition: height 0.2s ease-in, visibility 0s linear 0.2s;
  height: 0;
  visibility: hidden;
}

.OAS-beneTxnviewallsidebar.open {
  visibility: visible;
  animation: growDown 300ms ease-in-out forwards;
  transition: height 0.2s ease-in;
  transform-origin: top center;
  top: 90;
  height: auto;
}

.OAS-beneTxnviewallsidebar {
  background-color: #3a5686;
  margin-top: 221px;
  height: 0px;
  border-radius: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: height 0.2s ease-in;
  visibility: hidden;
}

.OAS-beneTxnviewallFilterDiv {
  /* display: flex; */
  /* justify-content: space-between; */
  /* width: 100%; */
  max-width: 1024px !important;
  margin: 0 auto;
  padding-top: 20px;
  z-index: 1;
  flex-direction: row;
}

.OAS-beneTxnFilterbuttonContainer {
  display: flex;
  justify-content: end;
  padding: 20px 0;
  align-items: end;
}

.OAS-table-div-container {
  position: relative;
  /* margin-top: 25px;     */
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  justify-content: space-around;
  background-color: #506994;
  width: 100%;
  box-shadow: 5px 5px 11px #00000026;
  height: 50px;
  z-index: 1;
  overflow-y: hidden;
}

.OAS-table-div-content {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  justify-content: space-around;
  width: 100%;
  border-bottom: 1px solid #506994;
  min-height: 51px;
  height: auto;
  z-index: 1;
  overflow-y: hidden;
}

.OAS-table-div-content:last-child {
  border-bottom: unset;
  margin-bottom: 10px;
}

.OAS-border-mid-div {
  border-right: 1px solid #1d3a6d;
}

.OAS-label-bank-width {
  min-width: 130px !important;
}
.OAS-label-account-width {
  min-width: 140px !important;
}
.ownerredirect {
  color: #31e5e5 !important;
  text-decoration: underline;
  cursor: pointer;
}
/* .label-TimeDate-width{width: 130px !important;} */

/* .label-ValueDt-width{width: 75px !important;} */

.OAS-label-div-trans {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  min-width: 167px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
}

.OAS-label-div-upi {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  min-width: 210px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
}

.OAS-label-div-clamt {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  min-width: 165px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
}

.OAS-label-div-val {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  min-width: 90px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
}

.OAS-label-div-narr {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  min-width: 275px;
  max-width: 275px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
  word-break: break-all;
  text-wrap: wrap;
}

.OAS-label-div-ref {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  min-width: 140px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
  word-break: break-all;
  text-wrap: wrap;
}

.OAS-label-div-type {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  min-width: 80px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
}

.OAS-label-div-type-width {
  min-width: max-content;
}

.OAS-label-div-amt {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  min-width: 165px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
  font-weight: 400;
}

.balance_type {
  display: block;
  width: 84px;
  height: 50px;
}

.type-main-btn {
  margin: 0;
  width: 77px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  text-transform: none;
  position: absolute;
  z-index: 1;
  background-color: #506994;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.2s ease;
}

.OAS-owner-border-div-line {
  height: 131px;
  border-left: 2px solid #1d3a6d;
  margin: auto 15px auto 0px;
  /* margin-top: 40px; */
  /* margin-right: 15px; */
  /* margin-left: 5px !important; */
}

.OAS-owner_filterdropdown {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1024px !important;
  height: auto;
  border-top: none;
  margin: 0 auto;
  z-index: 1;
}

.OAS-owner-main-btn {
  margin: 0 !important;
  width: 56px !important;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  text-transform: none !important;
  position: absolute !important;
  z-index: 1 !important;
  background-color: #667ca3 !important;
  display: flex !important;
  justify-content: space-evenly;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

.OAS-owner_select_scroll_com {
  height: 24px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px / 15px Open Sans !important;
  letter-spacing: 0px !important;
  margin: 4px 0px !important;
  color: #f9f9f9 !important;
  text-transform: none !important;
  padding: 2px 4px;
}

.OAS-owner_select_scroll_com:hover {
  background-color: #506994 !important;
}

/* .beneTxnviewallFilterDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1024px !important;
    margin: 0 auto;
    padding-top: 20px;
    z-index: 1;
    flex-direction: row;
} */

.beneTxnFilterSection1 {
  display: flex;
  flex-direction: column;
  height: 165px;
  /* padding-right: 30px; */
}

.OAS-statement-type {
  display: block;
  width: 80px;
  height: 36px;
}

.OAS-download_statement {
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  font-weight: 600;
  cursor: pointer;
}
/* 
.statement_text {
    text-align: left;
    font: normal normal normal 10px/14px Open Sans;
    color: #c9c9c9;
    padding: 2px 0px 0px 0px;
}

.statement_header {
    font: normal normal 600 16px/22px Open Sans;
    color: #f9f9f9;
    text-align: left;
    padding-bottom: 2px;
} */

.OAS-pdf-main-btn {
  padding: 5px 10px !important;
  margin: 0 1px !important;
  width: 80px !important;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  text-transform: none !important;
  position: absolute !important;
  z-index: 99 !important;
  background-color: #f05a6a !important;
  display: flex !important;
  justify-content: space-between;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

/* .bank_high_to_low_select_select{} */
.OAS-bank_high_to_low_select_select {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 39px;

  padding-right: 10px;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
}

/* .bank_high_to_low_select_scroll{} */
.OAS-bank_high_to_low_select_scroll {
  position: absolute;
  top: 37.5px;
}

/* .status_icon-img{} */

.OAS-statement_select_scroll_com {
  padding: 6px 4px !important;
  height: 27px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px / 15px Open Sans !important;
  letter-spacing: 0px !important;
  margin: 8px 0px !important;
  color: #f9f9f9 !important;
  text-transform: none !important;
}

.OAS-statement_select_scroll_com:active {
  background-color: #6d3034;
}

.OAS-statement_select_scroll_com:hover {
  background-color: #fc7a82 !important;
}

.statement_calendar_date_select_left {
  height: 27px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ff5860;
  padding-left: 20px;
}

.OAS-owner_account_number_text_input {
  background: #667ca3;
  border-radius: 10px;
  opacity: 1;
  width: 216px;
  height: 37px;
  padding: 15px;
  font: normal normal normal 12px/17px Open Sans;
  color: #f9f9f9;
}

.OAS-owner_account_number_text_input::placeholder {
  color: #dadada;
}

.OAS-owner_account_number_text {
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  padding-bottom: 5px;
}

.OAS-amtDiv_type {
  width: 216px;
  background-color: #667ca3;
  border-radius: 15px;
  height: 37px !important;
}

.OAS-amount-main-btn {
  margin: 0;
  width: 216px;
  border-radius: 10px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  text-transform: none;
  position: absolute;
  z-index: 1;
  background-color: #667ca3;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.2s ease;
}

.OAS-amount-inputs-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #dadada;
  padding: 37px 20px 0;
  width: 216px;
  /* padding-top: 55px; */
  /* padding-left: 8px; */
}

.OAS-input-container label {
  display: inline-block;
  font-size: 10px;
  margin-bottom: 3px;
  text-align: left;
}

.OAS-amount-inputs-div p {
  font-size: 10px;
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.1;
}

.OAS-input-container {
  display: inline-block;
}

.OAS-input-container input {
  padding-left: 20px;
}

.OAS-inputs-div-btns button:nth-child(1) {
  width: 100%;
  height: 24px;
  top: 218px;
  left: 10px;
  padding: 5px 10px 5px 10px;
  gap: 10px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  border: 1px solid var(--F9F9F9---Primary-white, #f9f9f9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.OAS-inputs-div-btns button:nth-child(2) {
  width: 100%;
  height: 24px;
  top: 218px;
  left: 77px;
  padding: 5px 10px 5px 10px;
  gap: 10px;
  border-radius: 8px;
  background: var(--FF5860---Failed, #ff5860);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.OAS-amount-inputs-div input {
  background-color: #244277;
  width: 176px;
  height: 24px;
  top: 78px;
  left: 10px;
  gap: 0px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.OAS-acc-viewall-clear-filter {
  border: 0.5px solid #f9f9f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OAS-acc-viewall-apply-filter {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OAS-buttoncontainer {
  margin-right: 0;
  display: flex;
  padding-left: 20px;
}

.OAS-buttoncontainer-text {
  width: 28px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.OAS-clear-button {
  cursor: pointer;
}

.OAS-clear-button:hover {
  background-color: #8093b3ee;
}

.OAS-clear-button.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #506994;
  opacity: 0.6;
}

.OAS-clear-button.disabled:hover {
  background-color: inherit;
}

.OAS-apply-button {
  background-color: #ff5860;
}

.OAS-apply-button:hover {
  opacity: 0.9;
}

.OAS-apply-button.disabled {
  background-color: rgb(112, 112, 112) !important;
  pointer-events: none;
  cursor: not-allowed;
}

.OAS-apply-button.disabled:hover {
  background-color: inherit;
}

.OAS-inputs-div-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 8px auto;
}

.OAS-filter_badge {
  width: auto;
  max-width: 230px;
  height: 36px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border: 2px solid #667ca3;
  border-radius: 48px;
  display: flex;
  gap: 10px;
  padding: 7px 10px;
}

.bene_txn_viewall_pagination_input {
  height: 36px;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(200, 200, 200) !important;
  opacity: 0.6 !important;
}
